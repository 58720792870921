import React from "react"
import { numberFormat } from "./Formats"

interface Props {
    imponible: number
    exento: number
    discount: number
    tax: number
    imp: boolean
    transport: number
}

export default class CalcularPagar extends React.Component<Props> {

    render() {
        let { imponible, exento, tax, imp, discount, transport } = this.props
        let descuento = 1 - (discount / 100)
        let total = imponible + exento;
        let money = localStorage.getItem("money");
        return <>
            <tr>
                <th colSpan={3} rowSpan={8}></th>
                <th style={{ textAlign: "right" }} colSpan={3}>Subtotal {money}:</th>
                <th style={{ textAlign: "right" }}>{numberFormat(total)}</th>
            </tr>
            {(discount !== 0) ? <tr>
                <th style={{ textAlign: "right" }} colSpan={3}>Descuento {discount}% {money}:</th>
                <th style={{ textAlign: "right" }}>{this.calDescuento(total)}</th>
            </tr> : <></>}
            {(imp && discount !== 0) ? <tr>
                <th style={{ textAlign: "right" }} colSpan={3}>Total {money}:</th>
                <th style={{ textAlign: "right" }}>{this.calTotal(total)}</th>
            </tr> : <></>}
            {imp ? <>
                {exento > 0 ? <tr>
                    <th style={{ textAlign: "right" }} colSpan={3}>exento {money}:</th>
                    <th style={{ textAlign: "right" }}>{numberFormat(exento * descuento)}</th>
                </tr> : <></>}
                <tr>
                    <th style={{ textAlign: "right" }} colSpan={3}>base imponible {money}:</th>
                    <th style={{ textAlign: "right" }}>{numberFormat(imponible * descuento)}</th>
                </tr>
                <tr>
                    <th style={{ textAlign: "right" }} colSpan={3}>Impuesto {tax}% {money}:</th>
                    <th style={{ textAlign: "right" }}>{this.calImpuesto()}</th>
                </tr>
            </> : <></>}
            {transport > 0 ? <tr>
                <th style={{ textAlign: "right" }} colSpan={3}>transporte {money}:</th>
                <th style={{ textAlign: "right" }}>{numberFormat(transport)}</th>
            </tr> : <></>}
            <tr>
                <th style={{ textAlign: "right" }} colSpan={3}>Monto a Pagar {money}:</th>
                <th style={{ textAlign: "right" }}>{this.calPagar()}</th>
            </tr>
        </>
    }
    calDescuento(total: number) {
        let { discount } = this.props
        let descuento = (discount / 100)
        return numberFormat(total * descuento)
    }
    calImpuesto() {
        let { discount, tax, imponible } = this.props;
        let impuesto = (tax / 100)
        let descuento = 1 - (discount / 100)
        return numberFormat(imponible * impuesto * descuento)
    }
    calTotal(total: number) {
        let { discount } = this.props
        let descuento = 1 - (discount / 100)
        return numberFormat(total * descuento)
    }
    calPagar() {
        let { discount, tax, imp, imponible, exento, transport } = this.props
        let impuesto = imp ? tax / 100 : 0;
        let descuento = 1 - (discount / 100)
        let total = (((imponible + exento) * descuento) + (imponible * descuento * impuesto)) + transport
        return numberFormat(total)
    }
}
