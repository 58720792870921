import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, Col, FormControl, InputGroup, Pagination, Row, Spinner, Table } from "react-bootstrap";
import { Permisos, PermisosType } from "../config/Permisos";
import { zfill } from "./Formats";

export default class Tabla extends React.Component<any, { itens: any[] | "carro", page: number, open: Boolean }> {
    permiso: PermisosType = "tecnico";
    labelBusqueda = "";
    mostrar = 8
    buscar: string = ""
    bucarLabel: Array<string> = []
    label: string = "id"
    orden: string = "d"
    length = 0;
    constructor(p: any) {
        super(p)
        this.setState = this.setState.bind(this);
        this.setBuscar = this.setBuscar.bind(this);
        this.state = { itens: "carro", page: 1, open: false }
    }
    close() {
        this.setState({ open: false })
    }
    body() {
        var page = this.state.page - 1
        var init = page * this.mostrar
        var fin = init + this.mostrar
        var itens = this.busqueda().filter((iten, index) => (index >= init && index < fin));
        var pts = itens.map((iten) => {
            return this.Row(iten)
        })
        return pts
    }
    paginacion() {
        if (this.state.itens.length === 0)
            return <></>
        var total_page = Math.ceil(this.state.itens.length / this.mostrar)
        var page = this.state.page
        var buttons = []
        if (page === 1) {
            buttons.push(<Pagination.Item key="active1" active>1</Pagination.Item>)
        } else {
            buttons.push(<Pagination.Item key="p1" onClick={this.setpage.bind(this, 1)}>1</Pagination.Item>)
        }
        for (var i = 3; i > 0; i--) {
            if ((page - i) > 1) {
                if (i === 3) {
                    buttons.push(<Pagination.Ellipsis key="Ellipsis1" />)
                } else {
                    buttons.push(<Pagination.Item key={"p" + (page - i)} onClick={this.setpage.bind(this, page - i)}>{page - i}</Pagination.Item>)
                }
            }
        }
        if (total_page > page && page > 1) {
            buttons.push(<Pagination.Item active>{page}</Pagination.Item>)
        }
        for (i = 1; i < 4; i++) {
            if ((page + i) < total_page) {
                if (i === 3) {
                    buttons.push(<Pagination.Ellipsis key="Ellipsis2" />)
                } else {
                    buttons.push(<Pagination.Item key={"p" + (page + i)} onClick={this.setpage.bind(this, page + i)}>{page + i}</Pagination.Item>)
                }
            }
        }
        if (page === total_page) {
            if (1 !== total_page) {
                buttons.push(<Pagination.Item key={"active" + total_page} active>{total_page}</Pagination.Item>)
            }
        } else {
            buttons.push(<Pagination.Item key={"p" + total_page} onClick={this.setpage.bind(this, total_page)}>{total_page}</Pagination.Item>)
        }
        return buttons
    }
    setpage(page: number) {
        this.setState({ page })
    }
    pagination() {
        return <Pagination style={{ alignContent: "right" }}>{this.paginacion()}</Pagination>
    }
    setItens(itens: Array<any>) {
        this.setState({ itens })
    }
    setBuscar(e: React.KeyboardEvent<HTMLInputElement>) {
        let { value } = e.currentTarget
        this.buscar = value.toUpperCase()
        this.setpage(1)
    }
    busqueda() {
        let { itens } = this.state
        if (itens === "carro")
            return [];
        itens = this.filtros(itens);
        if (this.buscar === "")
            return this.ordenar(itens)
        let arrayItens = itens.filter(iten => {
            let buscar = this.buscar.split(" "), pasa = false
            this.bucarLabel.forEach(label => {
                let considencias = 0
                buscar.forEach(busca => {
                    if (isNaN(iten[label])) {
                        if (iten[label].toUpperCase().indexOf(busca) > -1) {
                            considencias++
                        }
                    } else {
                        if (zfill(parseFloat(iten[label])).indexOf(busca) > -1) {
                            considencias++
                        }
                    }
                })
                if (buscar.length === considencias) {
                    pasa = true
                }
            })
            return pasa
        })
        this.length = arrayItens.length
        return this.ordenar(arrayItens)
    }
    filtros(itens: any[]) {
        return itens;
    }
    setOrden(label: string) {
        if (this.label === label) {
            if (this.orden === "a")
                this.orden = "d"
            else
                this.orden = "a"
        } else {
            this.orden = "d"
            this.label = label
        }
        this.forceUpdate();
    }
    ordenar(itens: Array<any>) {
        return itens.sort((a, b) => {
            if (isNaN(b[this.label]))
                if (this.orden === "d")
                    return (a[this.label] < b[this.label] ? - 1 : (a[this.label] > b[this.label] ? 1 : 0))
                else
                    return (b[this.label] < a[this.label] ? - 1 : (b[this.label] > a[this.label] ? 1 : 0))
            if (this.orden === "d")
                return (b[this.label] - a[this.label])
            else
                return (a[this.label] - b[this.label])
        })
    }
    flechas(label: string) {
        if (this.label === label) {
            if (this.orden === "d") {
                return <FontAwesomeIcon className="pull-right" icon={faArrowDown} />
            } else {
                return <FontAwesomeIcon className="pull-right" icon={faArrowUp} />
            }
        }
        return <></>
    }
    Buscador(label: string) {
        return <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>
                    {label}
                </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl placeholder="BUSCAR" onKeyUp={this.setBuscar} />
        </InputGroup>
    }
    render() {
        let { authFuncion } = Permisos();
        if (!authFuncion(this.permiso))
            return <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        No esta autorizado para entrar en esta seccion
                    </h1>
                </Card.Header>
            </Card>
        return <Card className="shadow h-100 py-2">
            <Card.Header>
                {this.busquedaMostrar()}
            </Card.Header>
            <Card.Body>
                {(this.state.itens === "carro") ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                    <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                    <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Cargando....
                    </h4>
                </Row> :
                    <Table>
                        <thead>
                            {this.Header()}
                        </thead>
                        <tbody>
                            {this.body()}
                        </tbody>
                    </Table>}
                <Row className="py-3 d-flex">
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">ELEMENTOS LISTADO {this.length}</h6>
                    {this.pagination()}
                </Row>
            </Card.Body>
        </Card>
    }
    busquedaMostrar() {
        return <Row>
            <Col as="h6" md="6" className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                {this.Buscador(this.labelBusqueda)}
            </Col>
            <Button onClick={() => { this.setState({ open: true }) }} variant="primary">NUEVO</Button>
        </Row>
    }
    Header() {
        return <></>;
    }
    Row(c: any) {
        return <></>;
    }
}