import { Activas } from "../Conexiones/Activas"
import { fechas } from "./Generales"

export class productos extends Activas {
    tabla = 'tablas'
    coleccion = 'products'
    lista: string = 'productos'
}
var conPro: productos
export function conProductos() {
    if (conPro === undefined)
        conPro = new productos()
    return conPro
}
interface proto extends fechas {
    id: number,
    category_id: number,
    description: string,
    quantity: number,
    cost: number,
    price_1: number,
    price_2: number,
    price_3: number,
    status: number
    exempt: boolean
}
export default interface Productos extends proto {
    code: string,
}

export interface InputProducto extends proto { }

export var ProductoNull = {
    id: 0,
    category_id: 0,
    description: "",
    quantity: 1,
    cost: 0,
    price_1: 1,
    price_2: 1,
    price_3: 1,
    status: 1,
    exempt: false
}