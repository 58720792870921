import React from 'react'
import { Modal, Row, InputGroup, FormControl, Button } from 'react-bootstrap'
import moment from 'moment'
import "moment/locale/es"
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Storage } from '../../Storage'
import { conReferidos } from '../../Model/Referidos'
moment.locale('es')
interface Props {
    close: () => void,
    id: number,
}
interface State {
    msn: string
}
class Seguimientos extends React.Component<Props, State> {
    msn: any = undefined
    con = conReferidos()
    constructor(p: any) {
        super(p)
        this.state = { msn: "" }
        this.setState = this.setState.bind(this)
    }
    render() {
        return <Modal size="lg" centered animation show={true} onHide={this.props.close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span>dejar comentario</span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <InputGroup className="mb-3">
                        <FormControl onKeyUp={this.setMsn.bind(this)} defaultValue={this.state.msn} />
                        <InputGroup.Append>
                            <Button onClick={() => { this.send(); this.clear() }}>
                                <FontAwesomeIcon icon={faSave} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Row>
            </Modal.Body>
        </Modal>
    }
    clear() {
        if (this.msn !== undefined)
            this.msn.value = ''
    }
    setMsn(e: React.KeyboardEvent<HTMLInputElement>) {
        let { value } = e.currentTarget
        if (this.msn === undefined)
            this.msn = e.currentTarget
        this.setState({ msn: value })
        if (e.keyCode === 13) {
            this.send()
            this.clear()
        }
    }

    send() {
        let { id } = this.props
        let note = this.state.msn
        this.con.update({ id, note })
        this.props.close()
    }
}
Seguimientos.contextType = Storage
export default Seguimientos