import React from "react"
import { Col, Row } from "react-bootstrap"
import NewRol from "./NewRol"
import { PermisosType } from "../../../config/Permisos"
import { Storage } from "../../../Storage"
import { conAuthAsing, Rol } from "../../../Model/Roles"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import EditarRol from "./EditarRol"
import ReactTooltip from "react-tooltip"
import Tabla from "../../../Utils/Table"
class ListaRoles extends Tabla {
    bucarLabel = ["name"];
    permiso: PermisosType = "user";
    con = conAuthAsing()
    componentWillMount() {
        this.context.set({ title: "configuracion de roles" })
        this.con.setListaRoles(this.setItens.bind(this));
    }
    Row(p: Rol) {
        return <FilaRol {...p} />
    }
    Header() {
        return <tr>
            <th onClick={() => { this.setOrden("name") }}>nombre{this.flechas("name")}</th>
            <th style={{ width: 150 }}>ACCIONES</th>
        </tr>
    }
    busquedaMostrar() {
        return <Titulo actualizar={() => { this.con.setListaRoles(this.setItens) }} />;
    }
}
class FilaRol extends React.Component<Rol, { open: Boolean }>{

    constructor(p: Rol) {
        super(p)
        this.state = { open: false }
    }
    render() {
        return <tr>
            <td>{this.props.name}</td>
            <td>
                {this.state.open ? <EditarRol title={"editar rol " + this.props.name} id={this.props.id} close={this.close.bind(this)} /> : <></>}
                <ReactTooltip />
                <Button data-tip="editar" onClick={() => { this.setState({ open: true }) }}>
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
            </td>
        </tr>
    }
    close() {
        this.setState({ open: false })
    }
}
class Titulo extends React.Component<{ actualizar: () => void }, { open: Boolean }>{
    constructor(p: any) {
        super(p)
        this.state = { open: false }
    }
    render() {
        return <Row style={{marginTop:-5}}>
            {this.state.open ? <NewRol actualizar={this.props.actualizar} close={this.close.bind(this)} /> : <></>}
            <Col as="h6" md="6" className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                Lista de Roles
            </Col>
            <Button onClick={() => { this.setState({ open: true }) }}>nuevo</Button>
        </Row>
    }
    close() {
        this.setState({ open: false })
    }
}
ListaRoles.contextType = Storage
export default ListaRoles