import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons"
import Axios from "axios"
import { Storage } from "../Storage"
import { don } from "../Conexiones/Minimo"

class User extends React.Component<any, { open: boolean }> {
    constructor(p: any) {
        super(p)
        this.state = { open: false }
    }
    Open() {
        this.setState({ open: !this.state.open })
    }
    render() {
        let { User } = this.context.data
        return <li className="nav-item dropdown no-arrow">
            <div onClick={this.Open.bind(this)} className="nav-link dropdown-toggle">
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">{User.name}</span>
                <img className="img-profile rounded-circle" src={User.image} alt="User Imege Templete" />
            </div>
            <div className={"dropdown-menu dropdown-menu-right shadow animated--grow-in " + (this.state.open ? "show" : "")}>
                <div onClick={this.cerrarSeccion} className="dropdown-item">
                    <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </div>
            </div>
        </li>
    }
    cerrarSeccion() {
        Axios.get(don + "/api/auth/logout")
        localStorage.setItem("Autorization", "undefined undefined")
        window.location.reload()
    }
}
User.contextType = Storage
export default User