import Axios from "axios"
import { toast } from "react-toastify"
import { don } from "../Conexiones/Minimo"
import { Cita } from "./Sevicios"
import app from "../config/firebase"
export class conexionTecnico {
    sent = 0
    async setListaTecnicos(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(don + "/api/technicians")
            setData({ Users: rest.data.data })
        } catch (error: any) {
            toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async signaciones(send: { user_id: number, service_id: number }) {
        try {
            let rest = await Axios({ method: 'post', url: don + "/api/technicians/dates", data: send })
            let Citas: Array<Cita> = rest.data.data
            return Citas
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
        return []
    }
    async ocupados(send: { date: string, turn: number, service_id: number }) {
        try {
            let rest = await Axios({ method: 'post', url: don + "/api/dates/technicians", data: send })
            let Ocu: Array<{ user_id: number }> = rest.data.data
            return Ocu
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async misCitas(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(don + "/api/mydates")
            setData({ citas: rest.data.data })
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    updateMisCitas(setData: (d: any) => void) {
        let mi = this
        app.ref('tablas/servicios')
            .on('value', snap => {
                if (mi.sent < snap.val()) {
                    this.sent = snap.val()
                    this.misCitas(setData)
                }
            })
    }
    async misServicios(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(don + "/api/myservices")
            setData(rest.data.data)
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    updateMisServicios(setData: (d: any) => void) {
        let mi = this
        app.ref('tablas/servicios')
            .on('value', snap => {
                if (mi.sent < snap.val()) {
                    this.sent = snap.val()
                    this.misServicios(setData)
                }
            })
    }
    async TrabajoActual(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(don + "/api/current_service")
            if (rest.data.data !== 3) {
                setData({ servicio: rest.data.data[0] })
                setData({ cita: rest.data.data[1] })
            }
            setData({ success: rest.data.success })
            setData({ mensaje: rest.data.message })
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async Asistencia(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(don + "/api/assists")
            return rest.data.data
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }

}
var conTec: conexionTecnico
export function conTecnico() {
    if (conTec === undefined)
        conTec = new conexionTecnico()
    return conTec
}