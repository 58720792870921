import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { FormControl, InputGroup, Pagination } from "react-bootstrap"
import { zfill } from "./Formats"
export class Paginacion extends React.Component {
    mostrar = 8
    length = 0
    itens: Array<any> = []
    row: (i: any) => void = () => { }
    act: () => void = () => { }
    page: number = 1
    buscar: string = ""
    bucarLabel: Array<string> = []
    label: string = "id"
    orden: string = "d"
    constructor(p: any) {
        super(p)
        this.setBuscar = this.setBuscar.bind(this)
    }
    body() {
        var page = this.page - 1
        var init = page * this.mostrar
        var fin = init + this.mostrar
        var itens = this.busqueda().filter((iten, index) => {
            if (index >= init && index < fin) {
                return true
            }
            return false
        }
        )
        var pts = itens.map((iten) => {
            return this.row(iten)
        })
        return pts
    }

    paginacion() {
        if (this.length === 0)
            return <></>
        var total_page = Math.ceil(this.length / this.mostrar)
        var page = this.page
        var buttons = []
        if (page === 1) {
            buttons.push(<Pagination.Item key="active1" active>1</Pagination.Item>)
        } else {
            buttons.push(<Pagination.Item key="p1" onClick={this.setpage.bind(this, 1)}>1</Pagination.Item>)
        }
        for (var i = 3; i > 0; i--) {
            if ((page - i) > 1) {
                if (i === 3) {
                    buttons.push(<Pagination.Ellipsis key="Ellipsis1" />)
                } else {
                    buttons.push(<Pagination.Item key={"p" + (page - i)} onClick={this.setpage.bind(this, page - i)}>{page - i}</Pagination.Item>)
                }
            }
        }
        if (total_page > page && page > 1) {
            buttons.push(<Pagination.Item active>{page}</Pagination.Item>)
        }
        for (i = 1; i < 4; i++) {
            if ((page + i) < total_page) {
                if (i === 3) {
                    buttons.push(<Pagination.Ellipsis key="Ellipsis2" />)
                } else {
                    buttons.push(<Pagination.Item key={"p" + (page + i)} onClick={this.setpage.bind(this, page + i)}>{page + i}</Pagination.Item>)
                }
            }
        }
        if (page === total_page) {
            if (1 !== total_page) {
                buttons.push(<Pagination.Item key={"active" + total_page} active>{total_page}</Pagination.Item>)
            }
        } else {
            buttons.push(<Pagination.Item key={"p" + total_page} onClick={this.setpage.bind(this, total_page)}>{total_page}</Pagination.Item>)
        }
        return buttons
    }

    setpage(page: number) {
        this.page = page
        this.act()
    }

    pagination() {
        return <Pagination style={{ alignContent: "right" }}>{this.paginacion()}</Pagination>
    }

    setItens(itens: Array<any>) {
        this.itens = itens
        this.length = itens.length
    }

    setBuscar(e: React.KeyboardEvent<HTMLInputElement>) {
        let { value } = e.currentTarget
        this.buscar = value.toUpperCase()
        this.setpage(1)
    }

    busqueda() {
        if (this.buscar === "")
            return this.ordenar(this.itens)
        let arrayItens = this.itens.filter(iten => {
            let buscar = this.buscar.split(" "), pasa = false
            this.bucarLabel.forEach(label => {
                let considencias = 0
                buscar.forEach(busca => {
                    if (isNaN(iten[label])) {
                        if (iten[label].toUpperCase().indexOf(busca) > -1) {
                            considencias++
                        }
                    } else {
                        if (zfill(parseFloat(iten[label])).indexOf(busca) > -1) {
                            considencias++
                        }
                    }
                })
                if (buscar.length === considencias) {
                    pasa = true
                }
            })
            return pasa
        })
        this.length = arrayItens.length
        return this.ordenar(arrayItens)
    }
    setOrden(label: string) {
        if (this.label === label) {
            if (this.orden === "a")
                this.orden = "d"
            else
                this.orden = "a"
        } else {
            this.orden = "d"
            this.label = label
        }
        this.act()
    }
    ordenar(itens: Array<any>) {
        return itens.sort((a, b) => {
            if (isNaN(b[this.label]))
                if (this.orden === "d")
                    return (a[this.label] < b[this.label] ? - 1 : (a[this.label] > b[this.label] ? 1 : 0))
                else
                    return (b[this.label] < a[this.label] ? - 1 : (b[this.label] > a[this.label] ? 1 : 0))
            if (this.orden === "d")
                return (b[this.label] - a[this.label])
            else
                return (a[this.label] - b[this.label])
        })
    }
    flechas(label: string) {
        if (this.label === label) {
            if (this.orden === "d") {
                return <FontAwesomeIcon className="pull-right" icon={faArrowDown} />
            } else {
                return <FontAwesomeIcon className="pull-right" icon={faArrowUp} />
            }
        }
        return <></>
    }
    Buscador(label: string) {
        return <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>
                    {label}
                </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl placeholder="BUSCAR CLIENTE O NUMERO" onKeyUp={this.setBuscar} />
        </InputGroup>
    }
}

export default function nuevaPaginacion() {
    return new Paginacion(0)
}