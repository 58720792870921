import { Mensajes } from "../Conexiones/Mensajes"
import { fechas } from "./Generales"
export class chat extends Mensajes {
    tabla = "services"
    ruta: string = "chat"
}
var conC: chat[] = []
export function conChat(id: number) {
    if (conC[id] === undefined)
        conC[id] = new chat(id)
    conC[id].escuchar = true;
    return conC[id]
}
export default interface Mensaje_chat extends fechas {
    id: number,
    user_id: number
    service_id: number
    msn: string
    read: number
}