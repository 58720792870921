import React from "react";
import moment from 'moment';
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import Servicio, { Cita, ServicioDefault } from "../../Model/Sevicios";
import { conTecnico } from "../../Model/Tecnico";
import { Calendario } from "../../Utils/Calendario";
import { manejadorColores } from "../../Utils/ManejadorColores";
import LeyendaDashboard from "./LeyendaDashborad";
import { zfill } from "../../Utils/Formats";
import RowTecnico from "../Servicios/NuevaCita/RowTecnico";
import Confimacion from "../../Utils/Confimacion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import Chat from "../Servicios/Chat";
moment.lang("es");
interface State {
    servicio: Servicio,
    success: boolean
    mensaje: string
    cita: Cita
    open: boolean
    confirmar: boolean
    chat: boolean
}
export class Dashboard extends React.Component<any, State> {
    con = conTecnico()
    constructor(p: any) {
        super(p)
        this.state = {
            servicio: ServicioDefault,
            success: false,
            mensaje: "",
            cita: { turn: 0, date: "", status: 3 },
            open: false,
            confirmar: false,
            chat: false,
        }
    }
    render() {
        return <Card className="shadow mb-4">
            <Card.Body>
                {(!this.state.success) ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                    <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                    <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Cargando....
                </h4>
                </Row> : this.getTrabajo()}
            </Card.Body>
        </Card>
    }
    getTrabajo() {
        let height = window.innerHeight - 300,
            turn = false,
            msn = '',
            buttonColor = '',
            confirmacion = '',
            { cita,
                open,
                servicio: { dates,
                    id,
                    created_at,
                    client,
                    address,
                    products,
                    users,
                    status
                }, } = this.state;
        return <Row>
            {open ? <LeyendaDashboard close={() => { this.setState({ open: false }) }} /> : <></>}
            <Col md={12}>
                <Card>
                    <Card.Header className="mh-3">
                        <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">{this.state.mensaje}</h6>
                    </Card.Header>
                </Card>
            </Col>
            {id > 0 ? <>
                <Col md={4}>
                    <Card>
                        <Card.Header className="mh-3">
                            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">DATOS DEL SERVICIOS</h6>
                        </Card.Header>
                        <Container>
                            N°:{zfill(id)}<br />
                        FECHA:{moment(created_at).format('DD-MM-YYYY')}
                        </Container>
                    </Card>
                    <br />
                    <Card>
                        <Card.Header>
                            <h4 className="m-0 font-weight-bold text-primary mr-auto align-self-center">asistencia</h4>
                        </Card.Header>
                        <Card.Body>
                            <b>Fecha:</b>{cita.date}<br />
                            <b>turno:</b>{cita.turn === 1 ? "mañana" : "tarde"}<br />
                        </Card.Body>
                    </Card>
                    <br />
                    {this.actual(cita) ? <>
                        <Card>
                            <Card.Header>
                                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">datos del cliente</h6>
                            </Card.Header>
                            <Container className="mh-3">
                                <b>Nombre: </b>
                                <br />{client.name}<br />
                                <b>PERSONA DE CONTACTO: </b>
                                <br />{client.contact_person}<br />
                                <b>CORREO ELECTRONICO: </b>
                                <br />{client.email}<br />
                                <b>TELEFONO: </b>{client.phone}<br />
                                <b>direccion del servicio:</b><br />
                                <p>{address}</p>
                            </Container>
                        </Card>
                        <br />
                    </> : <></>}
                    <Card>
                        <Card.Header className="mh-3">
                            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">Servivicos Contratados</h6>
                        </Card.Header>
                        <Container>
                            {products.map(p => {
                                return <p>{p.product.description} <b>x{p.quantity}</b></p>
                            })}
                        </Container>
                    </Card>
                    <br />
                    <Card>
                        <Card.Header>
                            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">tecnicos enviados</h6>
                        </Card.Header>
                        <Container className="mb-3">
                            {users.map(p => {
                                return <RowTecnico add={(t: any) => { }} tenico={p} />
                            })}
                        </Container>
                    </Card>
                </Col>
                <Col style={{ height }} md={8}>
                    <Calendario leyenda={() => { this.setState({ open: true }) }} celdas={dates.map(d => {
                        let manejador = manejadorColores();
                        let color = manejador.paso(d) ? manejador.getColor(4) : manejador.getColor(d.status);
                        if (cita.id !== undefined && d.id === cita.id && status === 2) {
                            color = manejador.selectedColor(d.status);
                            if (!turn && this.isTurno(d)) {
                                turn = true;
                                if (d.status === 1) {
                                    buttonColor = 'warning';
                                    msn = 'MARCAR ASISTENCIA';
                                    confirmacion = "¿ESTA SEGURO DE QUE DESEA COMENSAR ESTA CITA?"
                                } else {
                                    buttonColor = 'success';
                                    msn = 'CERRAR CITA'
                                    confirmacion = "¿ESTA SEGURO DE QUE DESEA CERRAR ESTA CITA?"
                                }
                            }
                        }
                        return { ...d, color }
                    })} />
                    <br />
                    {(turn) ?
                        <>
                            {this.state.confirmar ? <Confimacion msn={confirmacion} accion={this.marcarAsistencia.bind(this)} close={() => { this.setState({ confirmar: false }) }} /> : <></>}
                            <Button onClick={() => { this.setState({ confirmar: true }) }} size="lg" block variant={buttonColor}>
                                {msn}
                            </Button>
                        </> : <></>}
                    {this.state.chat ? <Chat open={this.state.chat} id={id} close={() => { this.setState({ chat: false }) }} /> : <></>}
                    <Button onClick={() => { this.setState({ chat: true }) }} size="lg" block variant="info">
                        <FontAwesomeIcon icon={faComments} /> Abrir Chat
                </Button>
                </Col>
            </> : <></>}
        </Row >
    }
    isTurno(cita: Cita) {
        let fecha = moment().format("YYYY-MM-DD")
        if (cita.status !== 3)
            if (cita.date === fecha) {
                let hora = parseInt(moment().format("HHmm"))
                if (cita.turn === 1)
                    if (hora < 1230)
                        return true;
                if (cita.turn === 2)
                    return true;
            }
        return false
    }
    actual(cita: Cita) {
        let fecha = parseInt(moment(cita.date).format("YYYYMMDD")),
            hoy = parseInt(moment().format("YYYYMMDD"));
        return fecha === hoy;
    }
    marcarAsistencia() {
        this.con.Asistencia(console.log)
        this.con.TrabajoActual(this.setState.bind(this))
    }
    componentDidMount() {
        this.con.TrabajoActual(this.setState.bind(this))
    }
}