import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTruck, faCalculator, faBell } from "@fortawesome/free-solid-svg-icons"
import { Dropdown, Row } from "react-bootstrap"
import { Storage } from "../Storage"
import { Notificaciones, notify } from "../Model/Notificaciones"
import { UpdateElement } from "../Utils/ArrayFun"
import { toast } from "react-toastify"
import { auth } from "../database/Auth"
import { users } from "../database/Users"

interface State {
    notificaciones: notify[]
    cont: number
}
function getIcon(nun: number) {
    if (nun === 2)
        return faCalculator;
    return faTruck;
}
class Notify extends React.Component<notify>{
    render() {
        let { permission_id, msn, user_id } = this.props;
        let user = users().getUser(user_id)
        return <Dropdown.Item className="d-flex align-items-center">
            <div className="mr-3">
                <div className="icon-circle bg-success">
                    <FontAwesomeIcon icon={getIcon(permission_id)} />
                </div>
            </div>
            <div>
                <div className="small text-gray-500">{user.name}</div>
                {msn}
            </div>
        </Dropdown.Item>
    }
}
class NotificacionesSystem extends React.Component<any, State> {
    con = Notificaciones(auth().id)
    constructor(p: any) {
        super(p)
        this.state = { cont: 0, notificaciones: [] }
        this.setState = this.setState.bind(this)
    }
    render() {
        let height = window.innerHeight / 2;
        return <Dropdown as="li" className="nav-item no-arrow mx-1">
            <Dropdown.Toggle className="nav-link" as="div">
                <FontAwesomeIcon onClick={() => { this.setState({ cont: 0 }) }} icon={faBell} />
                {this.state.cont > 0 ?
                    <span className="badge badge-danger badge-counter">{this.state.cont}</span> : <></>}
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="dropdown-list dropdown-menu p-0 shadow animated--grow-in">
                <Dropdown.Header as="h6">notificaciones del sistema</Dropdown.Header>
                <div style={{ height, overflowY: "scroll" }}>
                    {this.getNotificaciones()}
                </div>
                <Dropdown.Item href="#/action-1" className="text-center small text-gray-500">notificaciones del sistema</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    }
    componentDidMount() {
        this.start();
    }
    start() {
        if (this.con.id !== 0) {
            if (this.state.notificaciones.length === 0) {
                this.con.updateSystem(this.setData.bind(this));
            } else {
                this.con.setSystem(this.setLimpia.bind(this));
                setTimeout(() => {
                    this.start();
                }, 108000000);
            }
        } else {
            setTimeout(() => {
                this.start();
            }, 500);
        }
    }
    updateNotifi(n: notify) {
        n.read = 2;
        this.setState({ cont: this.state.cont + 1, notificaciones: UpdateElement({ a: this.state.notificaciones, b: n, label: "id" }) });
        let user = users().getUser(n.user_id);
        toast(<Row className="px-2">
            <div className="mr-3">
                <div className="icon-circle bg-success">
                    <FontAwesomeIcon icon={getIcon(n.permission_id)} />
                </div>
            </div>
            <div style={{ width: 180 }} className="font-weight-bold">
                <div className="small text-gray-500">{user.name}</div>
                <div style={{ maxWidth: 180, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} className="small">{n.msn}</div>
            </div>
        </Row>, { type: toast.TYPE.WARNING })
    }
    setData(d: notify[]) {
        this.setState({
            notificaciones: [...this.state.notificaciones, ...d].sort((a, b) => {
                return (b.id - a.id)
            })
        });
    }
    setLimpia(d: notify[]) {
        this.setState({
            notificaciones: d.sort((a, b) => {
                return (b.id - a.id)
            })
        });
    }
    getNotificaciones() {
        let { notificaciones } = this.state
        return notificaciones.map((m: notify) => {
            if (m.read !== 2)
                this.updateNotifi(m);
            return <Notify key={"sistem" + m.id} {...m} />
        })
    }
}
NotificacionesSystem.contextType = Storage
export default NotificacionesSystem;