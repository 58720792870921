import React from "react"
import { Accordion, Card, Col, Image, Row } from "react-bootstrap"
import RowTecnico from "../Servicios/NuevaCita/RowTecnico";
import { Tecnico } from "../../Model/Sevicios";
interface Props {
    tech: number
    Users: Tecnico[]
    setData: (d: any) => void
}
export class SelectorUser extends React.Component<Props> {
    render() {
        let { tech, Users, setData } = this.props;
        return <Accordion defaultActiveKey="1">
            <Row>
                <Accordion.Toggle eventKey="0" as={Col} md={5} >
                    {(tech === 0) ? <Card className="btn-outline-primary" style={{ border: "1px solid #e3e6f0", borderRadius: ".35rem" }}>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Image fluid src="/grupo.png" />
                                </Col>
                                <Col md={8}>
                                    <b>Nombre:</b> <br />todos los usuarios<br />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card> : <></>}
                    {Users.map(t => {
                        if (tech === t.id)
                            return <Card className="btn-outline-primary" style={{ border: "1px solid #e3e6f0", borderRadius: ".35rem" }}>
                                <Card.Body>
                                    <Row>
                                        <Col md={4}>
                                            <Image fluid src={t.image} />
                                        </Col>
                                        <Col md={8}>
                                            <b>Nombre:</b> <br />{t.name}<br />
                                            <b>correo: </b><br />{t.email}<br />
                                            <b>telefono: </b>{t.phone}<br />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        return <></>
                    })}
                </Accordion.Toggle>
                <Col md={7}>{this.props.children}</Col>
            </Row>
            <br />
            <Accordion.Collapse eventKey="0">
                <Row>
                    <Col md="4" className="mb-1">
                        <Card className="btn-outline-dark" onClick={() => { setData({ tech: 0 }) }} style={{ border: "1px solid #e3e6f0", borderRadius: ".35rem" }}>
                            <Row className="m-1">
                                <div style={{ width: "60px" }}>
                                    <Image fluid src="/grupo.png" />
                                </div>
                                <Col>
                                    Todos los Usuarios<br />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    {Users.map(t => {
                        return <Col md="4" className="mb-1">
                            <Card className="btn-outline-dark" onClick={() => { setData({ tech: t.id }) }} style={{ border: "1px solid #e3e6f0", borderRadius: ".35rem" }}>
                                <RowTecnico add={() => { }} tenico={t} />
                            </Card>
                        </Col>
                    })}
                </Row>
            </Accordion.Collapse>
        </Accordion>
    }
}