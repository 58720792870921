import React from 'react';
import { Button, Card, Col, Container, FormCheck, Row, Spinner } from 'react-bootstrap';
import User, { conUsers } from '../../Model/User';
import UserCard from './UserCard';
import { Storage } from '../../Storage';
import UserCreate from './UserCreate';
import { Permisos } from '../../config/Permisos';
import Tabla from '../../Utils/Table';
class ListUser extends Tabla {
    labelBusqueda = "BUSCAR USUARIO";
    bucarLabel = ["email", "name"];
    con = conUsers();
    mostrar = 6;
    eliminados = false;
    componentWillMount() {
        var yo = this;
        this.context.set({ title: "LISTA DE USUARIOS" })
        this.con.setCargar((itens: Array<any>) => {
            yo.setState({ itens })
        });
    }
    filtros(itens: User[]) {
        if (this.eliminados)
            return itens;
        return itens.filter(c => {
            if (c.status === 1) return true;
            return false;
        })
    }
    Row(u: User) {
        return <UserCard update={() => { this.con.setLista(this.setItens.bind(this)) }} key={"user" + u.id + u.status} user={u} />
    }
    render() {
        let { authFuncion } = Permisos();
        if (!authFuncion("user"))
            return <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        No esta autorizado para entrar en esta seccion
                </h1>
                </Card.Header>
            </Card>
        return <Card className="shadow mb-4">
            <Card.Header className="py-3"><Row>
                <Col as="h6" md="6" className="m-0 font-weight-bold text-primary align-self-center">
                    {this.Buscador(this.labelBusqueda)}
                </Col>
                <Col xs='4' className='mr-auto'>
                    <FormCheck
                        id="imp"
                        type="switch"
                        label="MOSTRAR ELIMINADOS"
                        checked={this.eliminados}
                        onClick={() => { this.eliminados = !this.eliminados; this.forceUpdate() }}
                    />
                </Col>
                <Button onClick={() => { this.setState({ open: true }) }} variant="primary">NUEVO</Button>
            </Row>
            </Card.Header>
            <Card.Body>
                {this.state.open ? <UserCreate close={this.close.bind(this)} /> : <></>}
                {(this.state.itens === "carro") ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                    <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                    <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Cargando....
                    </h4>
                </Row> : <Container fluid>
                    <Row>
                        {this.body()}
                    </Row>
                    <Row className="py-3 d-flex">
                        <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">elementos LISTADO {this.length}</h6>
                        {this.pagination()}
                    </Row>
                </Container>}
            </Card.Body>
        </Card>
    }
}
ListUser.contextType = Storage;
export default ListUser;