import React from 'react'
import { Modal } from 'react-bootstrap'
interface Props {
    close: () => void,
}
export default class Leyendas extends React.Component<Props> {

    render() {
        let { close } = this.props;
        return <Modal size="xl" centered animation show={true} onHide={close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>Leyenda</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                    {this.Body()}
            </Modal.Body>
        </Modal >
    }
    Body() {
        return <></>;
    }
}