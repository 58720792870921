import React from 'react';
import { PermisosType } from '../../config/Permisos';
import Referido, { conReferidos } from '../../Model/Referidos';
import { Storage } from '../../Storage';
import Tabla from '../../Utils/Table';
import FilaReferido from './Fila';
import Referir from './Referir';
class ListaReferenciados extends Tabla {
    labelBusqueda = "BUSCAR REFERIDO";
    bucarLabel = ["id", "phone", "name"];
    permiso: PermisosType = "cliente";
    con = conReferidos();
    componentWillMount() {
        var yo = this;
        this.context.set({ title: "LISTA DE REFERIDOS" });
        this.con.setCargar((itens: Array<any>) => {
            yo.setState({ itens })
        });
    }
    Row(c: Referido) {
        return <FilaReferido update={()=>{this.con.setLista(this.setItens.bind(this))}} key={"refe" + c.id + c.status + c.note} Referido={c} />
    }
    Header() {
        return <tr>
            <th onClick={() => { this.setOrden("created_at") }}>fecha{this.flechas("created_at")}</th>
            <th onClick={() => { this.setOrden("cliente") }}>cliente{this.flechas("cliente")}</th>
            <th onClick={() => { this.setOrden("name") }}>
                {this.state.open ? <Referir open close={() => { this.setState({ open: false }) }} /> : <></>}
                nombre{this.flechas("name")}</th>
            <th onClick={() => { this.setOrden("phone") }}>telefono{this.flechas("phone")}</th>
            <th onClick={() => { this.setOrden("note") }}>comentario{this.flechas("note")}</th>
            <th>ESTATUS</th>
            <th>ACCIONES</th>
        </tr>
    }
}
ListaReferenciados.contextType = Storage;
export default ListaReferenciados;