import Axios from 'axios'
import React from 'react'
import { Container, Row, Col, Card, Form, Button, Image } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify'
import { don } from '../Conexiones/Minimo'
import { Storage } from '../Storage'
class Login extends React.Component<any, { email: string, password: string }> {
    constructor(p: any) {
        super(p)
        this.state = { email: "", password: "" }
    }
    render() {
        return <Container className="align-self-center">
            <Row className="justify-content-center">
                <Col xl={10} lg={12} md={9}>
                    <Card style={{ position: "relative", transform: "translateY(-50%)", top: "80%", verticalAlign: "middle" }} className="o-hidden border-0 shadow-lg my-5">
                        <Card.Body className="p-0">
                            <Row className="justify-content-md-center">
                                <Col lg={6} className="d-none d-lg-block" >
                                    <Image className="m-5" fluid src="logoInicio.png" />
                                </Col>
                                <Col lg={6}>
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">
                                                Bienvenido
                                            </h1>
                                            <Form className="user">
                                                <Form.Group>
                                                    <Form.Control onKeyUp={this.setemail.bind(this)} type="email" className="form-control-user" placeholder="INGRESE SU CORREO ELECTRONICO..." />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control onKeyUp={this.setpassword.bind(this)} type="password" className="form-control-user" placeholder="*********" />
                                                </Form.Group>
                                                <Button onClick={this.autenticacion.bind(this)} className="btn-user btn-block">
                                                    Iniciar sesión
                                                </Button>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    }
    setemail(e: React.KeyboardEvent<HTMLInputElement>) {
        let { value } = e.currentTarget
        this.setState({ email: value })
        if (e.keyCode === 13) {
            this.autenticacion()
        }
    }
    setpassword(e: React.KeyboardEvent<HTMLInputElement>) {
        let { value } = e.currentTarget
        this.setState({ password: value })
        if (e.keyCode === 13) {
            this.autenticacion()
        }
    }
    async autenticacion() {
        try {
            let response = await Axios({
                method: 'POST',
                url: don + "/api/auth/login",
                data: { ...this.state, remember_me: true }
            })
            let data = await response.data
            localStorage.setItem("Autorization", data.token_type + " " + data.access_token)
            setTimeout(() => {
                this.context.actializar()
            }, 100);
        } catch (e: any) {
            if (e.response !== undefined)
                toast(e.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
}
Login.contextType = Storage
export default Login
