import React from "react"
import { Card, Col, Row, Spinner, ButtonGroup, Button, } from "react-bootstrap"
import { Permisos } from "../../../config/Permisos"
import Asistencia from "./Asistencia";
import { Tecnico } from "../../../Model/Sevicios";
import { conTecnico } from "../../../Model/Tecnico";
import { SelectorUser } from "../SelectorUser";
import ValorTodos from "./ValorTodos";
import ValorTecnico from "./ValorTecnico";
import Fechas from "../Impresos/Fechas";
import ModalPrint from "../../../Utils/ModalPrint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
interface State {
    modulo: string
    metodo: string
    status: string
    open: boolean
    tech: number
    Users?: Tecnico[]
}
export class ReportTecnicos extends React.Component<any, State> {
    syncTecnicos = conTecnico();
    sent = 0;
    constructor(p: any) {
        super(p);
        this.state = {
            modulo: "feedback/technical",
            metodo: "list",
            status: "10",
            open: false,
            tech: 0,
            Users: undefined
        }
    }
    render() {
        let { authFuncion } = Permisos()
        if (!authFuncion("reporte_tecnico"))
            return <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        No esta autorizado para entrar en esta seccion
                </h1>
                </Card.Header>
            </Card>
        let { tech, Users, modulo, open } = this.state;
        if (Users === undefined)
            return <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Cargando....
                </h4>
            </Row>
        return <Card>
            {open ? <ModalPrint title="IMPRIMIR" url={"pdf/" + this.getUrl()} close={() => this.setState({ open: false })} /> : <></>}
            <Card.Body as={Row}>
                <Card.Body>
                    <SelectorUser Users={Users} tech={tech} setData={this.setState.bind(this)} >
                        <ButtonGroup>
                            <Button variant={"feedback/technical" === modulo ? "primary" : "outline-dark"}
                                onClick={() => { this.setState({ modulo: "feedback/technical" }) }}>
                                VALORACIONES
                            </Button>
                            <Button variant={"technical" === modulo ? "primary" : "outline-dark"}
                                onClick={() => { this.setState({ modulo: "technical" }) }}>
                                ASISTENCIA
                            </Button>
                            <Button variant={"calendario" === modulo ? "primary" : "outline-dark"}
                                onClick={() => { this.setState({ modulo: "calendario" }) }}>
                                CALENDARIO
                            </Button>
                        </ButtonGroup>
                    </SelectorUser>
                </Card.Body>
                <Col sm={12}>
                    {this.getBody()}
                </Col>
            </Card.Body>
        </Card>
    }
    getUrl() {
        let { tech, modulo, metodo, status } = this.state;
        if (tech === 0) return modulo + "/" + metodo + "/" + status;
        return modulo + "/" + metodo + "/" + status + "/" + tech;
    }
    componentDidMount() {
        this.syncTecnicos.setListaTecnicos(this.setState.bind(this));
    }
    componentDidUpdate(p: any, s: State) {
        if (this.state.tech !== 0 && s.tech === 0)
            this.forceUpdate();
    }
    getBody() {
        let { tech, modulo } = this.state;
        switch (modulo) {
            case "calendario":
                return tech === 0 ?
                    <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center"> debe seleccionar un Tecnico </h1>
                    : <Asistencia key={"tecnico"+tech} Tecnicos={tech} />;
            case "technical":
                return this.getPrint();
            case "feedback/technical":
                return tech === 0 ? <ValorTodos /> : <Row>
                    <Col>
                        <ValorTecnico tech={tech} />
                    </Col>
                    <Col>
                        {this.getPrint()}
                    </Col>
                </Row>;
        }
    }
    setMetodo(metodo: string) {
        this.setState({ metodo })
    }
    getPrint() {
        return <Card>
            <Card.Header>
                <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center"> imprimir reporte </h1>
            </Card.Header>
            <Card.Body>
                <Fechas metodo={this.setMetodo.bind(this)} />
                <Button className="float-right"
                    onClick={() => this.setState({ open: true })}
                    variant="primary">
                    <FontAwesomeIcon icon={faPrint} />
                </Button>
            </Card.Body>
        </Card>;
    }
}
