
import { Permission } from "../Model/Roles"
export declare type PermisosType = "user" | "cotizacion" | "servicio" | "valoracion" | "referido" | "producto" | "cliente" | "categoria" | "tipocontribuyente" | "general" | "reporte_basico" | "reporte_callcenter" | "reporte_postventa" | "reporte_tecnico" | "tecnico";
class P {
    administrador = false
    cotizacion = false
    servicio = false
    valoracion = false
    referido = false
    producto = false
    cliente = false
    categoria = false
    tipocontribuyente = false
    general = false
    reporte_basico = false
    reporte_callcenter = false
    reporte_postventa = false
    reporte_tecnico = false
    tecnico = false
    user = false
    constructor() {
        this.setPermisos = this.setPermisos.bind(per)
        this.authFuncion = this.authFuncion.bind(per)
    }
    public setPermisos(data: Permission) {
        per[data.name] = true
    }
    public authFuncion(data: PermisosType): boolean {
        if (data === "tecnico")
            return per[data]
        if (per.administrador)
            return true
        return per[data]
    }
}
var per: P
export function Permisos() {
    if (per === undefined)
        per = new P()
    return per
}