import User, { conUsers, userPrueba } from "../Model/User"
import { Permisos } from "../config/Permisos"
import { auth } from "./Auth"

class Users {
    todas?: User[]
    activas?: User[]
    con = conUsers()
    actualizar: () => void = auth().actualizar
    constructor() {
        conUsers().setActivas(this.setTodas.bind(this))
        let { authFuncion } = Permisos()
        if (authFuncion("user"))
            conUsers().setCargar(this.setActivas.bind(this))
    }
    async cargar() {
        await conUsers().setListaActivas(this.setTodas.bind(this))
        let { authFuncion } = Permisos()
        if (authFuncion("user"))
            await conUsers().setLista(this.setActivas.bind(this))
        this.actualizar()
    }
    getUser(id: number, add?: (tec: User) => void) {
        var tec = userPrueba
        if (this.activas !== undefined)
            this.activas.forEach(t => {
                if (t.id === id) {
                    tec = t
                }
            })
        if (tec.id === 0)
            if (this.todas !== undefined)
                this.todas.forEach(t => {
                    if (t.id === id) {
                        tec = t
                    }
                })
        if (add) add(tec)
        return tec
    }
    setTodas(d: User[]) {
        this.todas = d
        auth().actualizar()
        this.actualizar()
    }
    setActivas(d: User[]) {
        this.activas = d
        auth().actualizar()
        this.actualizar()
    }
}
var a: Users
export function users() {
    if (a === undefined)
        a = new Users()
    return a
}