import React from 'react'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog, faTachometerAlt, faArrowRight, faArrowLeft, faCalculator, faStar, faTruck, faPhone, faUsers, faUser, faPrint, faShoppingCart, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { Image } from 'react-bootstrap'
import { Storage } from '../Storage'
import { Permisos } from '../config/Permisos'
class Menu extends React.Component<any, { open: Boolean, Permisos: Array<any> }> {
    permisos = Permisos()
    constructor(p: any) {
        super(p)
        this.state = { open: true, Permisos: [] }
        this.setState = this.setState.bind(this)
    }
    render() {
        let { authFuncion } = this.permisos
        return <ul className={"navbar-nav bg-gradient-warning sidebar sidebar-warning accordion" + (this.state.open ? " toggled" : "")} id="accordionSidebar">
            <Link to="/" className="sidebar-brand d-flex pt-5 pb-5 align-items-center justify-content-center">
                <div className="sidebar-brand-icon">
                    <Image src="/logo.sistema-200-x-91-.png" fluid />
                </div>
            </Link>
            <hr className="sidebar-divider my-0" />
            <li className="nav-item active">
                <Link to="/" className="nav-link collapsed">
                    <FontAwesomeIcon icon={faTachometerAlt} />
                    <span>INICIO</span>
                </Link>
            </li>
            <hr className="sidebar-divider" />
            {authFuncion("cotizacion") ? <li className="nav-item active">
                <Link to="/cotizaciones" className="nav-link collapsed">
                    <FontAwesomeIcon icon={faCalculator} />
                    <span>COTIZACIONES</span>
                </Link>
            </li> : <></>}
            {authFuncion("servicio") ? <li className="nav-item active">
                <Link to="/servicios" className="nav-link collapsed">
                    <FontAwesomeIcon icon={faTruck} />
                    <span>servicios</span>
                </Link>
            </li> : <></>}
            {authFuncion("valoracion") ? <li className="nav-item active">
                <Link to="/valoraciones" className="nav-link collapsed">
                    <FontAwesomeIcon icon={faStar} />
                    <span>valorar</span>
                </Link>
            </li> : <></>}
            {authFuncion("cliente") ? <li className="nav-item active">
                <Link to="/referidos" className="nav-link collapsed">
                    <FontAwesomeIcon icon={faPhone} />
                    <span>REFERIDOS</span>
                </Link>
            </li> : <></>}
            {authFuncion("cliente") ? <li className="nav-item active">
                <Link to="/clientes" className="nav-link collapsed">
                    <FontAwesomeIcon icon={faUsers} />
                    <span> CLIENTES</span>
                </Link>
            </li> : <></>}
            {authFuncion("producto") ? <li className="nav-item active">
                <Link to="/productos" className="nav-link collapsed">
                    <FontAwesomeIcon icon={faShoppingCart} />
                    <span> PRODUCTOS</span>
                </Link>
            </li> : <></>}
            {authFuncion("user") ? <li className="nav-item active">
                <Link to="/usuarios" className="nav-link collapsed">
                    <FontAwesomeIcon icon={faUser} />
                    <span> USUARIOS</span>
                </Link>
            </li> : <></>}
            {(authFuncion("reporte_basico") || authFuncion("reporte_tecnico") || authFuncion("reporte_postventa") || authFuncion("reporte_callcenter")) ? <li className="nav-item active">
                <Link to="/reportes" className="nav-link collapsed">
                    <FontAwesomeIcon icon={faPrint} />
                    <span> reportes</span>
                </Link>
            </li> : <></>}
            {(authFuncion("general") || authFuncion("tipocontribuyente") || authFuncion("categoria")) ? <li className="nav-item active">
                <Link to="/configuracion" className="nav-link collapsed">
                    <FontAwesomeIcon icon={faCog} />
                    <span>CONFIG</span>
                </Link>
            </li> : <></>}
            {authFuncion("tecnico") ? <>
                <li className="nav-item active">
                    <Link to="/misServicios" className="nav-link collapsed">
                        <FontAwesomeIcon icon={faTruck} />
                        <span> Mis Servicios</span>
                    </Link>
                </li>
                <li className="nav-item active">
                    <Link to="/misCitas" className="nav-link collapsed">
                        <FontAwesomeIcon icon={faCalendar} />
                        <span> Mis Citas</span>
                    </Link>
                </li>
            </> : <></>}

            <hr className="sidebar-divider d-none d-md-block" />
            <div className="text-center d-none d-md-inline">
                <button onClick={this.Open.bind(this)} style={{ fontSize: "1.5em", height: "2em", width: "2em" }} className="rounded-circle border-0">
                    <FontAwesomeIcon icon={this.state.open ? faArrowRight : faArrowLeft} />
                </button>
            </div>
        </ul>
    }
    Open() {
        this.setState({ open: !this.state.open })
    }

}
Menu.contextType = Storage
export default Menu