import React from "react";
import { Card, Col, ProgressBar } from "react-bootstrap";
export interface Rank {
    rank1: number
    rank2: number
    rank3: number
    rank4: number
    rank5: number
    top: number
}
interface Props {
    data: Rank,
    title: string
}

export default class ValorRank extends React.Component<Props> {

    render() {
        let { data, title } = this.props;
        return <Card>
            <Card.Header>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    {title}
                </h6>
            </Card.Header>
            <Col md="12" className="d-flex">
                <Col md="3" className="m-0 font-weight-bold text-success">exelente</Col>
                <Col md="9" className=" mr-auto align-self-center">
                    <ProgressBar variant="success" now={(data.rank5 / data.top) * 100} label={data.rank5} />
                </Col>
            </Col>
            <Col md="12" className="d-flex">
                <Col md="3" className="m-0 font-weight-bold text-info">bueno</Col>
                <Col md="9" className=" mr-auto align-self-center">
                    <ProgressBar variant="info" now={(data.rank4 / data.top) * 100} label={data.rank4} />
                </Col>
            </Col>
            <Col md="12" className="d-flex">
                <Col md="3" className="m-0 font-weight-bold text-dark">promedio</Col>
                <Col md="9" className=" mr-auto align-self-center">
                    <ProgressBar variant="dark" now={(data.rank3 / data.top) * 100} label={data.rank3} />
                </Col>
            </Col>
            <Col md="12" className="d-flex">
                <Col md="3" className="m-0 font-weight-bold text-warning">mejorable</Col>
                <Col md="9" className=" mr-auto align-self-center">
                    <ProgressBar variant="warning" now={(data.rank2 / data.top) * 100} label={data.rank2} />
                </Col>
            </Col>
            <Col md="12" className="d-flex">
                <Col md="3" className="m-0 font-weight-bold text-danger">malo</Col>
                <Col md="9" className=" mr-auto align-self-center">
                    <ProgressBar variant="danger" now={(data.rank1 / data.top) * 100} label={data.rank1} />
                </Col>
            </Col>
        </Card>
    }
}