import React from 'react'
import { Modal, Row, Button } from 'react-bootstrap'
import moment from 'moment'
import "moment/locale/es";
import { Permission, conAuthAsing } from '../../../Model/Roles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
moment.locale('es')
interface Props {
    id: number
    title: string
    close: () => void
}
interface State {
    permisos: number[],
    Permisos: Permission[]
}
export default class EditarRol extends React.Component<Props, State> {
    con = conAuthAsing();
    constructor(p: Props) {
        super(p);
        this.state = { permisos: [], Permisos: [] }
        this.setState = this.setState.bind(this);
        this.con.setListaPermisos(this.setState);
        this.con.setRolPermisos({ role_id: p.id }, this.setPermisosRol.bind(this));
    }
    setPermisosRol(d: { Permisos: Permission[] }) {
        this.setState({
            permisos: d.Permisos.map(p => {
                return p.id;
            })
        });
    }
    render() {
        let { close } = this.props;
        return <Modal size="xl" centered animation show={true} onHide={close}>
            <Modal.Header closeButton>
                {this.props.title}
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {this.state.Permisos.map(p => {
                        return <Button onClick={this.click.bind(this, p.id)} variant={this.selected(p.id)}>{p.name}</Button>
                    })}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.save.bind(this)} className="pull-right"><FontAwesomeIcon icon={faSave} /></Button>
            </Modal.Footer>
        </Modal >
    }
    selected(id: number) {
        let v = "outline-dark";
        this.state.permisos.forEach(p => {
            if (p === id)
                v = "success"
        })
        return v;
    }
    click(id: number) {
        let v = true;
        this.state.permisos.forEach(p => {
            if (p === id)
                v = false;
        })
        if (v) {
            this.setState({ permisos: [...this.state.permisos, id] });
        } else {
            this.setState({
                permisos: this.state.permisos.filter(p => {
                    if (p === id)
                        return false;
                    return true
                })
            });
        }
    }
    save() {
        let { props: { id }, state: { permisos } } = this;
        this.con.update({ role_id: id, permisos }, () => {
            this.props.close();
        });
    }
}