import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { Link, Route } from "react-router-dom"
import ListaCategorias from "./Categorias"
import ConfigGeneral from "./ConfigGeneral"
import ListaContribuyentes from "./Contribuyentes"
import Roles from "./Roles"

export class Config extends React.Component {


    render() {
        return <Row>
            <Col md="3">
                <Col>
                    <Card as={Link} to="/configuracion" className="border-left-primary shadow h-100 py-2">
                        <Card.Body>
                            <Row className="no-gutters align-items-center">
                                <Col className="mr-2">
                                    <h2 className="text-xs font-weight-bold text-primary text-uppercase mb-1">general</h2>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card as={Link} to="/configuracion/categorias" className="border-left-primary shadow h-100 py-2">
                        <Card.Body>
                            <Row className="no-gutters align-items-center">
                                <Col className="mr-2">
                                    <h1 className="text-xs font-weight-bold text-primary text-uppercase mb-1">CATEGORIAS</h1>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card as={Link} to="/configuracion/contribuyente" className="border-left-primary shadow h-100 py-2">
                        <Card.Body>
                            <Row className="no-gutters align-items-center">
                                <Col className="mr-2">
                                    <h2 className="text-xs font-weight-bold text-primary text-uppercase mb-1">TIPOS DE CONTRIBUYENTE</h2>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card as={Link} to="/configuracion/roles" className="border-left-primary shadow h-100 py-2">
                        <Card.Body>
                            <Row className="no-gutters align-items-center">
                                <Col className="mr-2">
                                    <h2 className="text-xs font-weight-bold text-primary text-uppercase mb-1">Configuracion de Roles</h2>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Col>
            <Col md="9">
                <Route path="/configuracion/categorias" component={ListaCategorias} />
                <Route path="/configuracion/contribuyente" component={ListaContribuyentes} />
                <Route path="/configuracion/roles" component={Roles} />
                <Route exact path="/configuracion" component={ConfigGeneral} />
            </Col>
        </Row>
    }
}