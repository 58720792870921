import Axios from "axios"
import app from "../config/firebase"
import { don } from "../Conexiones/Minimo"
export class Not {
    cot = 0
    ser = 0
    sys = 0
    id = 0
    dominio = don
    constructor(id: number) {
        this.cargar.bind(this, id)
    }
    cargar(id: number) {
        this.id = id
        if (id !== 0) {
            this.setCot()
            this.setSer()
            this.setSys()
        }
    }
    async setCot() {
        if (this.id !== 0) {
            const snat = await app.ref('notify/quotation/' + this.id).once('value')
            const data = snat.val()
            this.cot = data
        }
    }
    async setSer() {
        if (this.id !== 0) {
            const snat = await app.ref('notify/service/' + this.id).once('value')
            const data = snat.val()
            this.ser = data
        }
    }
    async setSys() {
        if (this.id !== 0) {
            const snat = await app.ref('notify/System/' + this.id).once('value')
            const data = snat.val()
            this.sys = data
        }
    }
    updateCotizacion(setData: (d: any) => void) {
        if (this.id !== 0) {
            this.setCotizacion(setData)
            app.ref('notify/quotation/' + this.id)
                .on('value', snap => {
                    const data = snap.val()
                    console.log(data);
                    if (this.cot < data) {
                        this.cot = data
                        this.notifyCotizacion(setData)
                    }
                })

        }
    }
    updateServicio(setData: (d: any) => void) {
        if (this.id !== 0) {
            this.setServicio(setData)
            app.ref('notify/service/' + this.id)
                .on('value', snap => {
                    const data = snap.val()
                    console.log(data);
                    if (this.ser < data) {
                        this.ser = data
                        this.notifyServicio(setData)
                    }
                })

        }
    }
    updateSystem(setData: (d: any) => void) {
        if (this.id !== 0) {
            this.setSystem(setData)
            app.ref('notify/System/' + this.id)
                .on('value', snap => {
                    const data = snap.val()
                    console.log(data);
                    if (this.sys < data) {
                        this.sys = data
                        this.notifySystem(setData)
                    }
                })

        }
    }
    async setCotizacion(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(this.dominio + "/api/msntracings")
            setData(rest.data.data)
        } catch (error) {
            //toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async setServicio(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(this.dominio + "/api/msnchats")
            setData(rest.data.data)
        } catch (error) {
            //toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async setSystem(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(this.dominio + "/api/msnsystems")
            setData(rest.data.data)
        } catch (error) {
            //toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async notifyServicio(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(this.dominio + "/api/newchats")
            setData(rest.data.data)
        } catch (error) {
            //toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async notifyCotizacion(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(this.dominio + "/api/newtracings")
            setData(rest.data.data)
        } catch (error) {
            //toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async notifySystem(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(this.dominio + "/api/newsystems")
            setData(rest.data.data)
        } catch (error) {
            //toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
}
var conCot: Not
export function Notificaciones(id: number) {
    if (conCot === undefined)
        conCot = new Not(id)
    if (conCot.id === 0)
        conCot.cargar(id)
    return conCot
}

export interface notify {
    id: number
    user_id: number,
    quotation_id: number
    service_id: number
    permission_id: number
    read: number
    msn: string
}