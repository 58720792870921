import React from 'react'
import { Storage } from '../../Storage'
import FilaServicio from './FilaServicio'
import Servicio, { conServicios } from '../../Model/Sevicios'
import { PermisosType } from '../../config/Permisos'
import Tabla from '../../Utils/Table'
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
class ListServicios extends Tabla {
    labelBusqueda = "BUSCAR SERVICIO";
    bucarLabel = ["id", "name"];
    permiso: PermisosType = "servicio";
    con = conServicios();
    componentWillMount() {
        this.context.set({ title: "LISTA DE SERVICIOS" });
        var yo = this;
        this.con.setCargar((itens: Array<any>) => {
            yo.setState({ itens })
        });
    }
    update() {
        var yo = this;
        this.con.setLista((itens: Array<any>) => {
            yo.setState({ itens })
        });
    }
    Row(c: Servicio) {
        return <FilaServicio update={this.update.bind(this)} key={'ser' + c.id + c.status} cotizacion={c} />
    }
    Header() {
        return <tr>
            <th onClick={() => { this.setOrden("id") }}>NUMERO{this.flechas("id")}</th>
            <th>FECHA</th>
            <th onClick={() => { this.setOrden("name") }}>CLIENTE{this.flechas("name")}</th>
            <th style={{textAlign:'right' }} >MONTO</th>
            <th>ESTATUS</th>
            <th>ACCIONES</th>
        </tr>
    }
    busquedaMostrar() {
        return <Row>
            <Button to="/servicios/calendario" as={Link} variant="primary">
                <FontAwesomeIcon icon={faCalendar} /> citas
            </Button>
            <Col as="h6" md="6" className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {this.labelBusqueda}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl placeholder="BUSCAR" onKeyUp={this.setBuscar} />
                </InputGroup>
            </Col>
            <Button to="/servicio/nueva" as={Link} variant="primary">NUEVO</Button>
        </Row>
    }
}
ListServicios.contextType = Storage
export default ListServicios