import React from "react"
import { Card, Col, Container, Row, Spinner, Tab } from "react-bootstrap";
import Servicio, { conServicios, marcado, Ocupado } from "../../Model/Sevicios";
import { conTecnico } from "../../Model/Tecnico";
import { Calendario } from "../../Utils/Calendario";
import { manejadorColores } from "../../Utils/ManejadorColores";
import LeyendaCitas from "./LeyendaCitas";
import { Valorarcion } from "../Valoracion/Valorar";
import { Tabs } from "react-bootstrap";
import moment from 'moment';
import { zfill } from "../../Utils/Formats";
import RowTecnico from "../Servicios/NuevaCita/RowTecnico";
interface State {
    servicio?: Servicio | null,
    citas?: Array<Ocupado>
    open: boolean
    select?: marcado
}
var scc = { border: "1px solid #e3e6f0", borderRadius: ".35rem", marginTop: "10px", marginLeft: "-1rem", padding: "1.25rem 1.25rem 1.25rem 1.25rem" }
export default class MisCitas extends React.Component<any, State> {
    con = conTecnico()
    servicios = conServicios()
    constructor(p: any) {
        super(p)
        this.state = { servicio: null, citas: undefined, select: undefined, open: false }
    }
    render() {
        return <Card className="shadow mb-4">
            <Card.Body>
                {(this.state.citas === undefined) ?
                    this.cargar() : this.getTrabajo(this.state.citas)}
            </Card.Body>
        </Card>
    }
    getTrabajo(dates: Array<Ocupado>) {
        let height = window.innerHeight - 300;
        let start: any = undefined;
        if (this.state.select !== undefined)
            start = this.state.select.date;
        return <Row>
            {this.state.open ? <LeyendaCitas close={() => { this.setState({ open: false }) }} /> : <></>}
            <Col style={{ height }} md={8}>
                <Calendario start={start} leyenda={() => { this.setState({ open: true }) }} selectDate={this.selectDate.bind(this)} celdas={dates.map(d => {
                    let color = this.printColor(d)
                    return { ...d, color }
                })} />
            </Col>
            <Col md={4}>
                <Tabs defaultActiveKey="Servicio" id="tap">
                    <Tab style={scc} eventKey="Servicio" title="Servicio">
                        {this.getMarcado()}<br />
                        {this.Servicio()}
                    </Tab>
                    {this.feedback()}
                </Tabs>
            </Col>
        </Row>
    }
    cargar() {
        return <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
            <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
            <h4 style={{ position: "absolute" }}
                className="m-0 font-weight-bold text-primary mr-auto align-self-center"
            >Cargando....</h4>
        </Row>
    }
    Servicio() {
        switch (this.state.servicio) {
            case undefined:
                return this.cargar()
            case null:
                return <h4 className="m-0 font-weight-bold text-primary mr-auto align-self-center"
                >Seleccione una fecha</h4>
            default:
                let { id, created_at, products, users } = this.state.servicio;
                return <>
                    <Card>
                        <Card.Header className="mh-3">
                            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">DATOS DEL SERVICIOS</h6>
                        </Card.Header>
                        <Container>
                            N°:{zfill(id)}<br />
                            FECHA:{moment(created_at).format('DD-MM-YYYY')}
                        </Container>
                    </Card>
                    <br />
                    <Card>
                        <Card.Header className="mh-3">
                            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">Servivicos Contratados</h6>
                        </Card.Header>
                        <Container>
                            {products.map(p => {
                                return <p>{p.product.description} <b>x{p.quantity}</b></p>
                            })}
                        </Container>
                    </Card>
                    <br />
                    <Card>
                        <Card.Header>
                            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">tecnicos enviados</h6>
                        </Card.Header>
                        <Container className="mb-3">
                            {users.map(p => {
                                return <RowTecnico add={(t: any) => { }} tenico={p} />
                            })}
                        </Container>
                    </Card>
                </>
        }
    }
    feedback() {
        let { servicio } = this.state;
        if (servicio === undefined || servicio === null)
            return <></>;
        if (servicio.feedback === null)
            return <></>;
        return <Tab style={scc} eventKey="Valoracion" title="VAloracion">
            <Valorarcion setData={() => { }} data={servicio.feedback} />
        </Tab>;
    }
    getMarcado() {
        let { select } = this.state;
        if (select === undefined)
            return <></>;
        let s = select.service_id ? select.service_id : 0;
        return <Card>
            <Card.Header>
                <h4 className="m-0 font-weight-bold text-primary mr-auto align-self-center">asistencia</h4>
            </Card.Header>
            <Card.Body>
                <b>Servicio N°:</b> {zfill(s)}<br />
                <b>Fecha:</b>{select.date}<br />
                <b>turno:</b>{select.turn === 1 ? "mañana" : "tarde"}<br />
                <b>Inicia:</b>{select.start !== null ? select.start : "N/A"}<br />
                <b>Cierre:</b>{select.end !== null ? select.end : "N/A"}<br />
            </Card.Body>
        </Card>
    }
    printColor(d: Ocupado) {
        let manejador = manejadorColores()
        if (this.state.servicio !== undefined && this.state.servicio !== null)
            manejador.setServicio(this.state.servicio)
        return manejador.printColor(d)

    }
    componentDidMount() {
        this.con.misCitas(this.setState.bind(this))
        this.con.updateMisCitas(this.setState.bind(this))
    }
    selectDate(date: marcado) {
        this.setState({ select: date })
        if (date.service_id !== undefined) {
            this.setState({ servicio: undefined })
            this.servicios.select(date.service_id, this.add.bind(this))
        } else {
            this.setState({ servicio: null })
        }
    }
    add(servicio: Servicio) {
        this.setState({ servicio })
    }
}