import React from 'react'
import { Button, Modal, Row, Col, InputGroup, FormControl } from 'react-bootstrap'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cliente, { ClienteNull, ClienteInput, conCliente } from '../../Model/Clientes'
import InputText from '../../Utils/InputText'
import Referido from '../../Model/Referidos'
import { conContribuyente, taxpayer_type } from '../../Model/Contribuyente'
import { toast } from 'react-toastify'
interface PropsCon {
    change: (r: number) => void
    defaultValue: number,
    invalid?: boolean
}
export class ContribuyenteSelector extends React.Component<PropsCon, { Tipos: Array<taxpayer_type> }>{
    con = conContribuyente();
    constructor(p: { change: () => void, defaultValue: number }) {
        super(p);
        this.state = { Tipos: [] };
        this.con.setActivas(this.setTipos.bind(this))
    }
    setTipos(t: taxpayer_type[]) {
        this.setState({ Tipos: t })
    }
    render() {
        let { defaultValue, invalid } = this.props;
        return <>
            <FormControl isInvalid={invalid} onChange={this.setRol.bind(this)} value={defaultValue} as="select">
                {(defaultValue === 0) ? <option value={0}>seleccionar tipo de contribuyente</option> : <></>}
                {this.state.Tipos.map(t => {
                    return <option key={"con" + t.id} {...(t.id === defaultValue) ? "selected" : ""} value={t.id}>{t.description}</option>
                })}
            </FormControl>
        </>
    }
    setRol(Rol: React.ChangeEvent<HTMLSelectElement>) {
        let value = Rol.currentTarget.value as string;
        let r = parseInt(value);
        this.props.change(r);
    }
}
interface Props {
    cliente: Cliente | null
    referido?: Referido
    title: string
    close: () => void
    add?: (c: Cliente) => void
}
export default class FormCliente extends React.Component<Props, ClienteInput> {
    con = conCliente()
    constructor(p: Props) {
        super(p)
        this.setState = this.setState.bind(this)
        let state = ClienteNull
        if (p.cliente !== null)
            state = p.cliente
        if (p.referido !== undefined) {
            state.name = p.referido.name
            state.phone = p.referido.phone
        }
        this.state = state
    }
    setTaxpayer(id: number) {
        this.setState({ taxpayer_type_id: id })
    }
    render() {
        let { setState, state } = this
        return <Modal centered animation show={true} onHide={this.props.close}>
            <Modal.Header closeButton>
                {this.props.title}
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs="12">
                        {this.inputCodeMode()}
                    </Col>
                    <Col xs="12">
                        <InputText label="NOMBRE" value={state.name} setData={setState} update="name" />
                    </Col>
                    <Col xs="12">
                        <InputText label="CORREO" value={state.email} setData={setState} update="email" />
                    </Col>
                    <Col xs="12">
                        <InputText label="TELÉFONO" value={state.phone} setData={setState} update="phone" />
                    </Col>
                    <Col xs="12">
                        <InputText label="PERSONA DE CONTACTO" value={state.contact_person} setData={setState} update="contact_person" />
                    </Col>
                    <Col xs="12">
                        <InputText label="DIRECCIÓN" value={state.address} setData={setState} update="address" />
                    </Col>
                    <Col xs="12">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    TIPO DE CONTRIBUYENTE
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <ContribuyenteSelector defaultValue={this.state.taxpayer_type_id} change={this.setTaxpayer.bind(this)} />
                        </InputGroup>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.get.bind(this)} variant="primary"><FontAwesomeIcon icon={faSave} /></Button>
            </Modal.Footer>
        </Modal>
    }
    isInvalid(s: string) {
        if (s === "")
            return true
        return false
    }
    inputCodeMode() {
        let { setState, state } = this
        if (this.state.id > 0)
            return <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        CED/RUC
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl disabled value={this.state.code} />
            </InputGroup>
        return <InputText label="CED/RUC" value={state.code} setData={setState} update="code" />
    }
    get() {
        if (this.state.code === "") {
            toast('debe colocar el documento legal del cliente', { type: toast.TYPE.ERROR })
        } else if (this.state.name === "") {
            toast('debe colocar el nombre del cliente', { type: toast.TYPE.ERROR })
        } else if (this.state.phone === "") {
            toast('debe colocar un telefono', { type: toast.TYPE.ERROR })
        } else if (this.state.taxpayer_type_id === 0) {
            toast('se debe seleccionar el tipo de contribuyente', { type: toast.TYPE.ERROR })
        } else {
            if (this.props.cliente === null) {
                this.create()
            } else {
                this.update()
            }
        }
    }

    async update() {
        await this.con.update(this.state, this.props.add)
        this.props.close()
    }

    async create() {
        let st: any = this.state
        if (this.props.referido !== undefined)
            st.referrer_id = this.props.referido.id
        await this.con.create(st, this.props.add)
        this.props.close()
    }

}

