import React from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "react-bootstrap";


export default class ClientesExcel extends React.Component<{ clientes: any[] }, {}>{
    _export = React.createRef<any>();

    excelExport() {
        if (this._export.current)
            this._export.current.save();
    };
    render() {
        const { clientes } = this.props;
        if (clientes.length === 0) return <></>
        return <ExcelExport data={clientes} ref={this._export}>
            <Button variant='success' onClick={this.excelExport.bind(this)} style={{marginRight:'15px'}}>
                EXCEL
            </Button>
            <ExcelExportColumn field="code" title="NIC" width={120} />
            <ExcelExportColumn field="name" title="NOMBRE" width={500} />
            <ExcelExportColumn field="email" title="CORREO" width={500} />
            <ExcelExportColumn field="phone" title="TELEFONO" width={300} />
            <ExcelExportColumn field="address" title="DIRECCION" width={1000} />
            <ExcelExportColumn field="contact_person" title="CONTACTO" width={500} />
        </ExcelExport>
    }

}