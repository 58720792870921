import React from 'react';
import { Storage } from '../../Storage';
import { conValorar, Valor } from '../../Model/valoracion';
import FilaValoracion from './FilaValoracion';
import { PermisosType } from '../../config/Permisos';
import Tabla from '../../Utils/Table';
import { Col, Row } from 'react-bootstrap';
class ListValoracion extends Tabla {
    labelBusqueda = "BUSCAR CLIENTE O NUMERO";
    bucarLabel = ["id", "name"];
    permiso: PermisosType = "valoracion";
    con = conValorar();
    componentWillMount() {
        var yo = this;
        this.context.set({ title: "LISTA DE SERVICIOS A VALORAR" });
        this.con.setCargar((itens: Array<any>) => {
            yo.setState({ itens })
        });
    }
    Row(c: Valor) {
        return <FilaValoracion key={'valor' + c.id + c.status} select={this.setState} cotizacion={c} />
    }
    Header() {
        return <tr>
            <th onClick={() => { this.setOrden("service_id") }}>NUMERO{this.flechas("service_id")}</th>
            <th>FECHA</th>
            <th onClick={() => { this.setOrden("name") }}>CLIENTE{this.flechas("name")}</th>
            <th>ESTATUS</th>
            <th>ACCIONES</th>
        </tr>
    }
    busquedaMostrar() {
        return <Row>
            <Col as="h6" md="6" className="m-0 font-weight-bold text-primary mr-auto align-self-center"> 
                {this.Buscador(this.labelBusqueda)}
            </Col>
        </Row>
    }
}
ListValoracion.contextType = Storage;
export default ListValoracion;