import React from 'react'
import { Button, Modal, Row, Col, InputGroup, FormControl } from 'react-bootstrap'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Productos, { conProductos, InputProducto, ProductoNull } from '../../Model/Productos'
import { CategoriaSelector } from './CategoriaSelector' 
import InputText from '../../Utils/InputText'
import Categoria, { conCategorias } from '../../Model/Categoria'
import { toast } from 'react-toastify'
interface Props {
    Producto: Productos | null
    close: () => void
    title: string
    add: (p: Productos) => void
}
interface State extends InputProducto {
    cateName?: string
    monto_1: number
    monto_2: number
    monto_3: number
}
export default class FormProductos extends React.Component<Props, State>{
    con = conProductos()
    constructor(p: Props) {
        super(p)
        if (p.Producto === null)
            this.state = { ...ProductoNull, monto_1: 0, monto_2: 0, monto_3: 0 }
        else {
            this.state = {
                ...p.Producto,
                monto_1: parseFloat((p.Producto.cost * ((p.Producto.price_1 / 100) + 1)).toFixed(2)),
                monto_2: parseFloat((p.Producto.cost * ((p.Producto.price_2 / 100) + 1)).toFixed(2)),
                monto_3: parseFloat((p.Producto.cost * ((p.Producto.price_3 / 100) + 1)).toFixed(2))
            }
            conCategorias().select(p.Producto.category_id, (c: Categoria) => {
                this.setState({ cateName: c.description })
            })
        }
        this.setState = this.setState.bind(this)
    }
    setCategoriaId(category_id: number) {
        this.setState({ category_id })
    }
    render() {
        let { setState, state } = this
        let money = localStorage.getItem("money");
        return <Modal size="xl" centered animation show={true} onHide={this.props.close}>
            <Modal.Header closeButton>
                {this.props.title}
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {this.inputCodeMode()}
                    <Col xs={(this.props.Producto !== null) ? "8" : "12"}>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    CATEGORÍA
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            {this.inputCategoria()}
                        </InputGroup>
                    </Col>
                    <Col xs="12">
                        <InputText label="DESCRIPCIÓN" value={state.description} setData={setState} update="description" />
                    </Col>
                    <Col xs="2" className="d-flex flex-column">
                        <InputGroup className="d-flex">
                            <InputGroup.Prepend>
                                <InputGroup.Checkbox onClick={this.isExempt.bind(this)} checked={state.exempt} />
                            </InputGroup.Prepend>
                            <InputGroup.Append >
                                <InputGroup.Text>EXENTO</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col xs="5">
                        <InputText minValue={0.01} step="0.01" type="number" label={"COSTO " + money} value={state.cost} setData={setState} update="cost" />
                    </Col>
                    <Col xs="5">
                        <InputText label="TURNOS REQUERIDOS" value={state.quantity} setData={setState} update="quantity" />
                    </Col>
                    <Col xs="4">
                        <InputText minValue={1} after="%" type="number" label="UTILIDAD 1" value={state.price_1} setData={this.setUtilidad.bind(this)} update="price_1" />
                    </Col>
                    <Col xs="4">
                        <InputText minValue={1} after="%" type="number" label="UTILIDAD 2" value={state.price_2} setData={this.setUtilidad.bind(this)} update="price_2" />
                    </Col>
                    <Col xs="4">
                        <InputText minValue={1} after="%" type="number" label="UTILIDAD 3" value={state.price_3} setData={this.setUtilidad.bind(this)} update="price_3" />
                    </Col>
                    <Col xs="4">
                        <InputText minValue={state.cost} type="number" label={'PRECIO 1 ' + money} value={state.monto_1} setData={this.setPrecio.bind(this)} update="monto_1" />
                    </Col>
                    <Col xs="4">
                        <InputText minValue={state.cost} type="number" label={'PRECIO 2 ' + money} value={state.monto_2} setData={this.setPrecio.bind(this)} update="monto_2" />
                    </Col>
                    <Col xs="4">
                        <InputText minValue={state.cost} type="number" label={'PRECIO 3 ' + money} value={state.monto_3} setData={this.setPrecio.bind(this)} update="monto_3" />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.get.bind(this)} variant="primary"><FontAwesomeIcon icon={faSave} /></Button>
            </Modal.Footer>
        </Modal>
    }
    isExempt() {
        this.setState({ exempt: !this.state.exempt });
    }
    inputCodeMode() {
        if (this.props.Producto !== null)
            return <Col xs="4">
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            CÓDIGO
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl disabled value={this.props.Producto.code} />
                </InputGroup>
            </Col>
        return <></>
    }
    inputCategoria() {
        if (this.state.cateName !== undefined)
            return <FormControl value={this.state.cateName} />
        return <CategoriaSelector defaultValue={this.state.category_id} change={this.setCategoriaId.bind(this)} />
    }
    getPrecio(utilidad: number) {
        let price = (utilidad / 100) + 1
        return parseFloat((this.state.cost * price).toFixed(2))
    }
    getUtilidad(price: number) {
        let utilidad = ((price / this.state.cost) - 1) * 100
        return parseFloat(utilidad.toFixed(2));

    }
    setPrecio(d: { monto_1?: number, monto_2?: number, monto_3?: number }) {
        if (d.monto_1)
            this.setState({ monto_1: d.monto_1, price_1: this.getUtilidad(d.monto_1) });
        if (d.monto_2)
            this.setState({ monto_2: d.monto_2, price_2: this.getUtilidad(d.monto_2) });
        if (d.monto_3)
            this.setState({ monto_3: d.monto_3, price_3: this.getUtilidad(d.monto_3) });

    }
    setUtilidad(d: { price_1?: number, price_2?: number, price_3?: number }) {
        if (d.price_1)
            this.setState({ monto_1: this.getPrecio(d.price_1), price_1: d.price_1 });
        if (d.price_2)
            this.setState({ monto_2: this.getPrecio(d.price_2), price_2: d.price_2 });
        if (d.price_3)
            this.setState({ monto_3: this.getPrecio(d.price_3), price_3: d.price_3 });
    }
    get() {
        if (this.props.Producto === null) {
            this.create()
        } else {
            this.update()
        }
    }
    update() {
        if (this.state.category_id === 0) {
            toast('se debe seleccionar una categoria', { type: toast.TYPE.ERROR })
        } else if (this.state.description === "") {
            toast('debe colocar una descripcion de producto', { type: toast.TYPE.ERROR })
        } else if (this.state.cost === 0) {
            toast('debe colocar un costo de producto', { type: toast.TYPE.ERROR })
        } else {
            this.con.update(this.state, this.props.add)
            this.props.close()
        }
    }
    create() {
        if (this.state.category_id === 0) {
            toast('se debe seleccionar una categoria', { type: toast.TYPE.ERROR })
        } else if (this.state.description === "") {
            toast('debe colocar una descripcion de producto', { type: toast.TYPE.ERROR })
        } else if (this.state.cost === 0) {
            toast('debe colocar un costo de producto', { type: toast.TYPE.ERROR })
        } else {
            this.con.create(this.state, this.props.add)
            this.props.close()
        }
    }
}
