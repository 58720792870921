import React from "react"
import {  Row, Table } from "react-bootstrap"
import Leyendas from "../../../Utils/Leyendas"
export default class Leyenda extends Leyendas {
    Body() {
        return <Row>
            <Table>
                <tr>
                    <td style={{ width: 40 }}>
                        <div style={{ width: '38px',height:'47px', margin: "1 1 1 1", border: '#28A745 solid 3px' }}></div>
                    </td>
                    <td style={{ width: 50 }}>Calendario</td>
                    <td>DIA EN CURSO</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#007bff" }}></td>
                    <td style={{ width: 50 }}>Calendario</td>
                    <td>turnos que tienen un servicio asignado</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#FFC107" }}></td>
                    <td style={{ width: 50 }}>Calendario</td>
                    <td>alguno de los tecnicos esta ocupado ese turno</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#28A745" }}></td>
                    <td style={{ width: 50 }}>Calendario</td>
                    <td>turnos seleccionados</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#DC3545" }}></td>
                    <td style={{ width: 50 }}>Calendario</td>
                    <td>Uno o mas tecnicos seleccionados estan ocupados el turno seleccionado</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#BF7BAD" }}></td>
                    <td style={{ width: 50 }}>Tecnicos</td>
                    <td>el tecnico esta ocupado en uno o mas turnos seleccionados</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#DC3545" }}></td>
                    <td style={{ width: 50 }}>Tecnicos</td>
                    <td>el tecnico seleccionado esta ocupado no o mas turnos seleccionados</td>
                </tr>
            </Table>
        </Row>
    }
}