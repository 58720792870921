import React from 'react'
import { Modal, Row, Button } from 'react-bootstrap'
import moment from 'moment'
import "moment/locale/es";
import { Permission, conAuthAsing } from '../../../Model/Roles';
import InputText from '../../../Utils/InputText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
moment.locale('es')
interface Props {
    close: () => void
    actualizar: () => void
}
interface State {
    name: string
    array: number[],
    Permisos: Permission[]
}
export default class NewRol extends React.Component<Props, State> {
    con = conAuthAsing();
    constructor(p: Props) {
        super(p);
        this.state = { name: "", array: [], Permisos: [] }
        this.setState = this.setState.bind(this);
        this.con.setListaPermisos(this.setState);
    }
    render() {
        let { close } = this.props;
        return <Modal size="xl" centered animation show={true} onHide={close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <InputText label="Nuevo Rol" update="name" setData={this.setState} value={this.state.name} />
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {this.state.Permisos.map(p => {
                        return <Button onClick={this.click.bind(this, p.id)} variant={this.selected(p.id)}>{p.name}</Button>
                    })}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.save.bind(this)} className="pull-right"><FontAwesomeIcon icon={faSave} /></Button>
            </Modal.Footer>
        </Modal >
    }
    selected(id: number) {
        let v = "outline-dark";
        this.state.array.forEach(p => {
            if (p === id)
                v = "success"
        })
        return v;
    }
    click(id: number) {
        let v = true;
        this.state.array.forEach(p => {
            if (p === id)
                v = false;
        })
        if (v) {
            this.setState({ array: [...this.state.array, id] });
        } else {
            this.setState({
                array: this.state.array.filter(p => {
                    if (p === id)
                        return false;
                    return true
                })
            });
        }
    }
    save() {
        let { name, array } = this.state;
        this.con.create({ name, array }, ()=>{
            this.props.actualizar();
            this.props.close();
        });
    }
}