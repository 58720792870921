import React from 'react'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputText from '../../Utils/InputText'
import User, { conUsers } from '../../Model/User'
interface Props {
    user: User
    open: boolean
    close: () => void
    add?: (user: User) => {}
}
interface State {
    password: string,
    passwordNew: string,
    passwordConfirm: string,
}
export default class Clave extends React.Component<Props, State>{
    con = conUsers()
    constructor(p: Props) {
        super(p)
        this.setState = this.setState.bind(this)
        this.state = {
            password: "",
            passwordNew: "",
            passwordConfirm: "",
        }
    }
    render() {
        let { setState, state } = this
        return <Modal size="lg" centered animation show={this.props.open} onHide={this.props.close}>
            <Modal.Header closeButton>
                cambiar clave del usuario {this.props.user.name}
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs="12">
                        <InputText type="password" label="CLAVE" value={state.passwordNew} setData={setState} update="passwordNew" />
                    </Col>
                    <Col xs="12">
                        <InputText label="REPETIR CLAVE" type="password" invalid={this.validClave()} invalidMsn="LAS CLAVES NO COINSIDEN" value={state.passwordConfirm} setData={setState} update="passwordConfirm" />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.get.bind(this)} variant="primary"><FontAwesomeIcon icon={faSave} /></Button>
            </Modal.Footer>
        </Modal>
    }
    validClave() {
        let { passwordNew, passwordConfirm } = this.state
        return passwordNew !== passwordConfirm
    }
    get() {
        let { state: { password, passwordConfirm, passwordNew }, props: { user: { id }, close } } = this
        this.con.updateClave({ password, passwordConfirm, passwordNew, id })
        close()
    }
}