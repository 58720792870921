import Axios from "axios"
import React from "react"
import { Card, Col, Row } from "react-bootstrap";
import { don } from "../../Conexiones/Minimo";
import moment from 'moment';
import Torta from "../../Utils/Torta";
import Linea from "../../Utils/Linea";
import { getDias } from "../../Utils/ArrayFun";
interface state {
    pie: any[]
    line: any[]
}
const year = parseInt(moment().format("YYYY"));
const mont = parseInt(moment().format("MM"));
export class Dashboard extends React.Component<any, state> {
    constructor(p: any) {
        super(p);
        this.state = {
            pie: [],
            line: []
        }
        this.send()
    }
    send() {
        this.getRank();
        this.getTotal();
    }
    async getRank() {
        try {
            let rest = await Axios.get(don + "/api/graph/service/rank/month/" + year + "/" + mont);
            this.setState({ pie: rest.data.data });
        } catch (e) {
            console.log(e);
        }
    }
    async getTotal() {
        try {
            let rest = await Axios.get(don + "/api/graph/service/month/" + year + "/" + mont);
            this.setState({ line: rest.data.data });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        let { pie, line } = this.state;
        return <Card>
            <Card.Header>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Servicios
                </h6>
            </Card.Header>
            <Row>
                <Col md="5" style={{ height: 300 }}>
                    <Torta data={pie.map(t => {
                        let label = "", color = "";
                        switch (t.RANK) {
                            case 0: label = "CANCELADO"; color = "#DC3545"; break;
                            case 1: label = "ESPERA"; color = "#FFC107"; break;
                            case 2: label = "Activa"; color = "#007bff"; break;
                            case 3: label = "PROCESADO"; color = "#28A745"; break;
                        }
                        return { id: label, label, value: t.CANT, color }
                    })} />
                </Col>
                <Col md="7" style={{ height: 300 }}>
                    {(line.length !== 0) ?
                        <Linea title="SERVICIOS TERMINADOS" data={getDias(year, mont).map(d => {
                            line.forEach(p => {
                                if (p.Day === d.x)
                                    d.y = p.Total;
                            })
                            return d;
                        })} /> : <></>}
                </Col>
            </Row>
        </Card>
    }

}