import React from 'react';
import Login from './app/login';
import Rutas from './config/Rutas';
import { Storage } from './Storage';
class App extends React.Component {
  render() {
    let token = localStorage.getItem("Autorization");
    return (token === null || token === "undefined undefined" || token === "") ? <Login key="login" /> : <Rutas key="app" />

  }
}
App.contextType = Storage;
export default App;
