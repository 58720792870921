import { Activas } from "../Conexiones/Activas"
import { fechas } from "./Generales"


export class categoria extends Activas {
    tabla = 'tablas'
    coleccion = 'categories'
    lista: string = 'categorias'
}
var conCate: categoria
export function conCategorias() {
    if (conCate === undefined)
        conCate = new categoria()
    return conCate
}
export default interface Categoria extends fechas {
    id: number,
    description: string,
    code: string,
    status: number
}