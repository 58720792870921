import Axios from "axios"
import { toast } from "react-toastify"
import { don } from "../Conexiones/Minimo"
export class Roles {
    async setListaRoles(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(don + "/api/roles")
            setData(rest.data.data)
        } catch (error: any) {
            toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async setRolPermisos(send: any, setData: (t: any) => void) {
        try {
            let rest = await Axios({ method: 'post', url: don + "/api/permissions_rol", data: send })
            setData({ Permisos: rest.data.data })
        } catch (error: any) {
            toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async setListaPermisos(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(don + "/api/permissions")
            setData({ Permisos: rest.data.data })
        } catch (error: any) {
            toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async create(send: any, add?: (t: any) => void) {
        try {
            let rest = await Axios({ method: 'post', url: don + "/api/create_roles", data: send })
            if (add !== undefined) {
                add(rest.data)
            }
            toast(rest.data.message, { type: toast.TYPE.SUCCESS })
        } catch (error: any) {
            toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async update(send: any, add?: (t: any) => void) {
        try {
            let rest = await Axios({ method: 'post', url: don + "/api/assign_permissions", data: send })
            if (add !== undefined) {
                add(rest.data)
            }
            toast(rest.data.message, { type: toast.TYPE.SUCCESS })
        } catch (error: any) {
            toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async asingPermisos(send: any, add?: (t: any) => void) {
        try {
            let rest = await Axios({ method: 'post', url: don + "/api/assign_permissions", data: send })
            if (add !== undefined) {
                add(rest.data)
            }
            toast(rest.data.message, { type: toast.TYPE.SUCCESS })
        } catch (error: any) {
            toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
}
var conRoles: Roles
export function conAuthAsing() {
    if (conRoles === undefined)
        conRoles = new Roles()
    return conRoles
}
export interface Rol {
    id: number,
    name: string
    permissos: Array<Permission>
}

export interface Permission {
    id: number,
    name: "administrador" | "cotizacion" | "servicio" | "valoracion" | "referido" | "producto" | "cliente" | "categoria" | "tipocontribuyente" | "general" | "reporte_basico" | "reporte_callcenter" | "reporte_postventa" | "reporte_tecnico" | "tecnico"
}