import React from 'react'
import { Row, Col, Button, Accordion, InputGroup, FormControl, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'
import Cliente from '../../Model/Clientes'
import BuscarCliente from './BuscarCliente'
import FormCliente from './FormCliente'
import { Storage } from '../../Storage'
interface Props {
    Cliente: Cliente
    add: (Cliente: Cliente) => void
}
interface State {
    nuevoCliente: boolean
    buscarCliente: boolean
}
class ClienteSelector extends React.Component<Props, State> {
    constructor(p: Props) {
        super(p)
        this.state = {
            nuevoCliente: false,
            buscarCliente: false
        }
    }
    render() {
        let { nuevoCliente, buscarCliente } = this.state
        return <Accordion defaultActiveKey="1">
            {nuevoCliente ? <FormCliente cliente={null} title="NUEVO CLIENTE" close={this.close.bind(this)} add={this.props.add} /> : <></>}
            {buscarCliente ? <BuscarCliente open={buscarCliente} add={this.props.add} close={this.close.bind(this)} /> : <></>}
            <Row>
                <Col xs="4">
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>RUC</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl disabled value={this.props.Cliente.code} />
                    </InputGroup>
                </Col>
                <Col xs="6">
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Nombre</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl disabled value={this.props.Cliente.name} />
                    </InputGroup>
                </Col>
                <Col xs="2">
                    <ButtonGroup className="float-right">
                        <Accordion.Toggle data-tip="Ver Mas" as={Button} variant="secondary" eventKey="0">
                            <FontAwesomeIcon icon={faEye} />
                        </Accordion.Toggle>
                        <Button data-tip="Nuevo Cliente" onClick={() => { this.setState({ nuevoCliente: true }) }} variant="primary">
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button data-tip="Buscar Cliente" onClick={() => { this.setState({ buscarCliente: true }) }} variant="info">
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Accordion.Collapse eventKey="0">
                <Row>
                    <Col xs="4">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Correo</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl disabled value={this.props.Cliente.email} />
                        </InputGroup>
                    </Col>
                    <Col xs="3">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Teléfono</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl disabled value={this.props.Cliente.phone} />
                        </InputGroup>
                    </Col>
                    <Col xs="5">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Persona de contacto</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl disabled value={this.props.Cliente.contact_person} />
                        </InputGroup>
                    </Col>
                    <Col xs="12">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Direccion</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl disabled value={this.props.Cliente.address} />
                        </InputGroup>
                    </Col>
                </Row>
            </Accordion.Collapse>
        </Accordion>
    }

    close() {
        this.setState({
            nuevoCliente: false,
            buscarCliente: false
        })
    }
}
ClienteSelector.contextType = Storage
export default ClienteSelector