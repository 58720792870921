import React from 'react'
import Paginacion from '../../Utils/Paginacion'
import { Modal, Row, Table, Button, Col, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Cotizacion, { conCotizacion } from '../../Model/Cotizacion'
import { numberFormat, zfill } from '../../Utils/Formats'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
moment.locale('es')
interface PropsFila {
    cotizacion: Cotizacion,
    add: (iten: Cotizacion) => void,
    close: () => void
}
interface Props {
    open: boolean,
    add: (iten: Cotizacion) => void,
    close: () => void
}
interface State {
    act: number,
    cotizaciones?: Array<Cotizacion>
}
class Fila extends React.Component<PropsFila>{
    con = conCotizacion()
    render() {
        let cot = this.props.cotizacion
        return <tr>
            <th style={{ width: "115px" }}>{zfill(cot.id)}</th>
            <th style={{ width: "150px" }}>{moment(cot.created_at).format("DD-MM-YYYY")}</th>
            <th>{cot.name}</th>
            <th style={{ width: "200px" }}>{this.calPagar()}</th>
            <th style={{ width: "100px" }}>{this.getStatus()}</th>
            <th style={{ width: "30px" }}>
                <ReactTooltip />
                <Button data-tip='Cargar Cotizacion' variant="outline-dark" onClick={() => { this.add() }}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </th>
        </tr>
    }
    calPagar() {
        let { tax, discount, with_tax, taxable, exempt } = this.props.cotizacion
        let impuesto = (with_tax) ? (tax / 100) : 0
        let descuento = 1 - (discount / 100)
        return numberFormat((taxable + exempt) * descuento + taxable * impuesto)
    }
    async add() {
        let { add } = this.props
        await this.con.select(this.props.cotizacion.id, add)
    }
    getStatus() {
        switch (this.props.cotizacion.status) {
            case 0:
                return <span className="bg-danger text-white p-1 d-inline-block">Cancelada</span>
            case 1:
                return <span className="bg-warning text-black p-1 d-inline-block">Espera</span>
            case 2:
                return <span className="bg-success text-white p-1 d-inline-block">Procesada</span>
        }
    }
}
export default class BuscarCotizacion extends React.Component<Props, State>{
    page = Paginacion();
    con = conCotizacion();
    constructor(pr: { open: boolean, add: (iten: Cotizacion) => void, close: () => void }) {
        super(pr);
        this.setState = this.setState.bind(this);
        this.state = { act: 0 };
        this.page.row = (p: Cotizacion) => {
            return <Fila key={"cot" + p.id + p.status} cotizacion={p} add={pr.add} close={pr.close} />
        };
        this.page.act = this.act.bind(this);
        this.page.bucarLabel = ["id", "name"];
        this.con.setCargar(this.setCotizacion.bind(this));
    }
    setCotizacion(cotizaciones?: Array<Cotizacion>) {
        this.setState({ cotizaciones })
    }
    render() {
        return <Modal size="xl" centered animation show={this.props.open} onHide={this.props.close}>
            <Modal.Header closeButton>
                <Col as="h6" md="9" className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    {this.page.Buscador("BUSCAR COTIZACIÓN")}
                </Col>
            </Modal.Header>
            <Modal.Body>
                {(this.state.cotizaciones === undefined) ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                    <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                    <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Cargando....
                    </h4>
                </Row> :
                    <Row>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th onClick={() => { this.page.setOrden("id") }}>NUMERO{this.page.flechas("id")}</th>
                                    <th>FECHA</th>
                                    <th onClick={() => { this.page.setOrden("name") }}>CLIENTE{this.page.flechas("name")}</th>
                                    <th onClick={() => { this.page.setOrden("total") }}>MONTO{this.page.flechas("total")}</th>
                                    <th>ESTATUS</th>
                                    <th>ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.page.body()}
                            </tbody>
                        </Table>
                    </Row>}
                <Row className="py-3 d-flex">
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">cotizaciones LISTADO {this.page.length}</h6>
                    {this.page.pagination()}
                </Row>
            </Modal.Body>
        </Modal>
    }
    componentWillUpdate(nextProps: any, nextState: State) {
        if (nextState.cotizaciones !== undefined)
            this.page.setItens(nextState.cotizaciones);
    }
    act() {
        this.setState({ act: this.state.act + 1 });
    }
}