import Servicio, { Ocupado } from "../Model/Sevicios"
import moment from 'moment'
class Colores {
    ser?: Servicio
    setServicio(ser: Servicio) {
        this.ser = ser
    }
    printColor(date: Ocupado) {
        let state = (this.paso(date)) ? 4 : date.status
        return (this.selectedCelda(date)) ? this.selectedColor(state) : this.getColor(state)
    }
    selectedCelda(date: Ocupado): boolean {
        let inService = false
        if (this.ser !== undefined)
            this.ser.dates.forEach(d => {
                if (d.date === date.date && d.turn === date.turn)
                    inService = true
            })
        return inService
    }
    paso(date: Ocupado) {
        if (date.status === 1) {
            let actual = parseInt(moment().format("YYYYMMDD"))
            let fecha = parseInt(moment(date.date).format("YYYYMMDD"))
            let hora = parseInt(moment().format("HHmm"))
            if (fecha < actual)
                return true
            if (fecha === actual) {
                if (hora > 1230) {
                    if (date.turn === 1) {
                        return true
                    } 
                }
            }
        }
        return false
    }
    getColor(state: number) {
        switch (state) {
            case 1:
                return "#007bff"
            case 2:
                return "#FFC107"
            case 3:
                return "#28A745"
            default:
                return "#DC3545"
        }
    }
    selectedColor(state: number) {
        switch (state) {
            case 1:
                return "#BF7BAD"
            case 2:
                return "#FFFF00"
            case 3:
                return "#00FF00"
            default:
                return "#FF0000"
        }
    }


}
export function manejadorColores() {
    return new Colores()
}