
import React from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'
import { faEdit, faComments, faCalendar, faCheck, faTimes, faPrint } from '@fortawesome/free-solid-svg-icons'
import { numberFormat, zfill } from '../../Utils/Formats'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Servicio, { conServicios, ServicioDefault } from '../../Model/Sevicios'
import PopCliente from '../Clientes/PopCliente'
import Chat from './Chat'
import CalendarioServicio from './CalendarioServicio'
import DetalleCotizacion from '../Cotizacion/DetalleCotizacion'
import ModalPrint from '../../Utils/ModalPrint'
import ReactTooltip from 'react-tooltip'
import Confimacion from '../../Utils/Confimacion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
moment.locale('es')
interface Props {
    cotizacion: Servicio
    update: () => void
}
interface State extends Servicio {
    chat: boolean
    verCalendario: boolean
    verDetalles: boolean
    print: boolean
    eliminar: boolean,
    activar: boolean
    terminar: boolean
}
export default class FilaServicio extends React.Component<Props, State>{
    con = conServicios()
    constructor(p: Props) {
        super(p)
        this.state = {
            ...p.cotizacion,
            chat: false,
            verCalendario: false,
            verDetalles: false,
            print: false,
            eliminar: false,
            activar: false,
            terminar: false,
        }
    }
    render() {
        let money = localStorage.getItem("money");
        let { chat, verDetalles, verCalendario, id, print, eliminar, activar, terminar } = this.state
        return <tr onMouseEnter={this.set.bind(this)}>
            {eliminar ? <Confimacion msn={"¿ESTA SEGURO QUE ELIMINARA EL SERVICIO " + zfill(this.state.id) + "?"} accion={this.delete.bind(this)} close={this.close.bind(this)} /> : <></>}
            {terminar ? <Confimacion msn={"¿ESTA SEGURO QUE TERMINAR EL SERVICIO " + zfill(this.state.id) + "?"} accion={this.terminar.bind(this)} close={this.close.bind(this)} /> : <></>}
            {activar ? <Confimacion msn={"¿ESTA SEGURO QUE ACTIVARA EL SERVICIO " + zfill(this.state.id) + "?"} accion={this.activar.bind(this)} close={this.close.bind(this)} /> : <></>}
            {chat ? <Chat open={chat} id={id} close={this.close.bind(this)} /> : <></>}
            {verDetalles ? <DetalleCotizacion select={this.state} open={verDetalles} close={this.close.bind(this)} /> : <></>}
            {print ? <ModalPrint title={"Servicio N°:" + zfill(this.state.id)} url={"pdf/service/store/" + this.state.id} close={this.close.bind(this)} /> : <></>}
            {verCalendario ? <CalendarioServicio open={verCalendario} select={this.state} close={this.close.bind(this)} /> : <></>}
            <th style={{ width: "115px" }}>
                <Button data-tip="ver detalles" variant="link" onClick={() => { this.setState({ verDetalles: true }) }}>
                    {zfill(this.state.id)}
                </Button>
            </th>
            <th style={{ width: "150px" }}>{moment(this.state.created_at).format("DD-MM-YYYY")}</th>
            <th><PopCliente name={this.state.name} cliente={this.state.client} /></th>
            <th style={{ width: "200px",textAlign:'right' }}>{money} {this.calPagar()}</th>
            <th style={{ width: "100px" }}>{this.getStatus()}</th>
            <th style={{ width: "128px" }}>
                <ReactTooltip />
                <ButtonGroup>
                    {this.BotonCambiante()}
                    <Button data-tip="Ver chat" onClick={() => { this.setState({ chat: true }) }} variant="dark">
                        <FontAwesomeIcon icon={faComments} />
                    </Button>
                    <Button data-tip="IMPRIMIR SERVICIO" variant="dark" onClick={() => { this.setState({ print: true }) }}>
                        <FontAwesomeIcon icon={faPrint} />
                    </Button>
                    {this.getButonCalendar()}
                    {this.getBotonEdit()}
                    {this.getBotonDelete()}
                </ButtonGroup>
            </th>
        </tr>
    }
    getStatus() {
        switch (this.props.cotizacion.status) {
            case 0:
                return <span className="bg-danger text-white p-1 d-inline-block">Cancelada</span>
            case 1:
                return <span className="bg-warning text-black p-1 d-inline-block">Espera</span>
            case 2:
                return <span className="bg-info text-white p-1 d-inline-block">Activa</span>
            case 3:
                return <span className="bg-success text-white p-1 d-inline-block">Procesada</span>
        }
    }
    getButonCalendar() {
        let cot = this.props.cotizacion
        if (cot.status > 0)
            return <Button data-tip="Ver calendario" variant="secondary" onClick={() => { this.setState({ verCalendario: true }) }}>
                <FontAwesomeIcon icon={faCalendar} />
            </Button>
        return <></>
    }
    getBotonDelete() {
        let cot = this.props.cotizacion
        if (cot.status === 1)
            return <Button data-tip="Cancelar servicio" variant="danger" onClick={() => { this.setState({ eliminar: true }) }}>
                <FontAwesomeIcon icon={faTimes} />
            </Button>
        if (cot.status === 2)
            return <Button data-tip="Terminar servicio" variant="success" onClick={() => { this.setState({ terminar: true }) }}>
                <FontAwesomeIcon icon={faCheck} />
            </Button>
        return <></>
    }
    getBotonEdit() {
        let cot = this.props.cotizacion
        if (cot.status === 1 || cot.status === 2)
            return <Button data-tip="Editar Servicio" variant="info" to={"/servicio/" + cot.id} as={Link} >
                <FontAwesomeIcon icon={faEdit} />
            </Button>
        return <></>
    }
    BotonCambiante() {
        let cot = this.props.cotizacion
        if (cot.status === 1)
            return <Button data-tip="Comenzar" onClick={() => { this.setState({ activar: true }) }} variant="info" >
                <FontAwesomeIcon icon={faCheck} />
            </Button>
        return <></>
    }
    calPagar() {
        let { tax, discount, with_tax, taxable, exempt, transport } = this.props.cotizacion
        let impuesto = (with_tax) ? (tax / 100) : 0
        let descuento = 1 - (discount / 100)
        return numberFormat((taxable + exempt) * descuento + taxable * descuento * impuesto + transport)
    }
    delete() {
        let cot = this.props.cotizacion
        this.con.stateChange({ id: cot.id, status: 0 }, this.props.update)
    }
    activar() {
        let cot = this.props.cotizacion
        this.con.stateChange({ id: cot.id, status: 2 }, this.props.update)
    }
    terminar() {
        let cot = this.props.cotizacion
        this.con.stateChange({ id: cot.id, status: 3 }, this.props.update)
    }
    async set() {
        if (this.state.products === undefined) {
            this.setState(ServicioDefault)
            this.setState(this.props.cotizacion)
            await this.con.select(this.props.cotizacion.id, (selected: Servicio) => {
                this.setState(selected)
            })
        }
    }
    close() {
        this.setState({
            verCalendario: false,
            verDetalles: false,
            chat: false,
            print: false,
            eliminar: false,
            activar: false,
            terminar: false
        })
    }
}