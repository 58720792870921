import React from 'react'
import { Button } from 'react-bootstrap'
import Cliente from '../../Model/Clientes'
interface Props {
    cliente?: Cliente,
    name: string
}

export default class PopCliente extends React.Component<Props> {


    render() {
        return <Button data-tip={this.tooltip()}  data-class="toolbody" data-type="light" data-html="true" variant="link">
            {this.props.name}
        </Button>
    }

    tooltip() {
        let { cliente } = this.props
        if (cliente !== undefined)
            return "<h9 class='m-0 font-weight-bold text-primary mr-auto align-self-center'>DATOS DE CONTACTO</h9><br />" +
                "<b>PERSONA DE CONTACTO:</b><br />" +
                cliente.contact_person + "<br />" +
                "<b>CORREO ELECTRONICO:</b><br />" +
                cliente.email + "<br />" +
                "<b>TELEFONO:</b><br />"+
                cliente.phone
        return "<h9 class='m-0 font-weight-bold text-primary mr-auto align-self-center'><b>DATOS DE CONTACTO</h9><br />" +
            "<b>PERSONA DE CONTACTO:</b><br />" +
            "<b>CORREO ELECTRONICO:</b><br />" +
            "<b>TELEFONO:</b><br />"
    }
}