import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, FormControl, InputGroup } from "react-bootstrap"
import { conContribuyente, taxpayer_type } from "../../../Model/Contribuyente"
export class NuevaContribuyente extends React.Component<any, taxpayer_type> {
    con = conContribuyente()
    render() {
        return <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>DESCRIPCIÓN</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl onChange={this.setChangeDesc.bind(this)} />
            <InputGroup.Text>IMPUESTO</InputGroup.Text>
            <FormControl onChange={this.setChangeTax.bind(this)} type="number" style={{ maxWidth: 150 }} />
            <InputGroup.Append>
                <Button onClick={this.guardar.bind(this)}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </InputGroup.Append>
        </InputGroup>
    }
    setChangeTax(e: React.ChangeEvent<HTMLSelectElement>) {
        let { value } = e.currentTarget
        let r = parseInt(value)
        e.currentTarget.value = r + ""
        this.setState({ tax: r })
    }
    setChangeDesc(e: React.ChangeEvent<HTMLSelectElement>) {
        let { value } = e.currentTarget
        let r = value.toUpperCase()
        e.currentTarget.value = r
        this.setState({ description: r })
    }
    guardar() {
        let { tax, description } = this.state
        this.con.create({ id: 1, tax, description, status: 1 })
    }
}