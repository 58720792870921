import React from 'react'
import { Modal, Row } from 'react-bootstrap'
import moment from 'moment'
import "moment/locale/es"
import { don } from '../Conexiones/Minimo'
moment.locale('es')
interface Props {
    close: () => void,
    url: string,
    title: string
}
export default class ModalPrint extends React.Component<Props> {

    render() {
        let { title, close, url } = this.props;
        return <Modal size="xl" centered animation show={true} onHide={close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>{title}</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <iframe src={don + "/api/" + url} width='100%' height='700wh'  title="print" scrolling='no' frameBorder='0'></iframe>
                </Row>
            </Modal.Body>
        </Modal >
    }
}