import React from "react"
import { FormControl, InputGroup } from "react-bootstrap"

interface Props {
    setData: (data: any) => void,
    label?: string
    update: string
    type?: "number" | "password"
    value: string | number
    minValue?: number
    maxValue?: number
    invalid?: boolean
    valid?: boolean
    invalidMsn?: string
    validMsn?: string
    step?: string
    after?: string
}

export default class InputText extends React.Component<Props, { select: boolean }> {
    state = { select: false };

    render() {
        let { invalid, valid, type, step } = this.props
        let p: any = { type }
        if (type === "number")
            p = { ...p, step, minLength: 0, min: 0 }
        return <InputGroup className="mb-3">
            {this.label()}
            <FormControl {...p} onBlur={this.minValue.bind(this)} onFocus={() => { this.setState({ select: true }) }} isInvalid={invalid} isValid={valid} onChange={this.setChangeString.bind(this)} {...this.getValue()} />
            {this.after()}
            {this.isInvalid()}
            {this.isValid()}
        </InputGroup>
    }
    label() {
        let { label } = this.props
        if (label !== undefined)
            return <InputGroup.Prepend>
                <InputGroup.Text>{label}</InputGroup.Text>
            </InputGroup.Prepend>
        return <></>
    }
    minValue(e: React.FocusEvent<HTMLInputElement>) {
        let value = e.currentTarget.value as string
        let { update, setData, type, minValue, maxValue } = this.props
        let r: string | number
        if (type === "number") {
            r = (value === "") ? 0 : parseFloat(value)
            if (minValue !== undefined)
                if (r < minValue)
                    r = minValue
            if (maxValue !== undefined)
                if (r > maxValue)
                    r = maxValue
        } else {
            r = value.toUpperCase()
        }
        e.currentTarget.value = r + ""
        if (update !== undefined)
            setData({ [update]: r })
        this.setState({ select: false })
    }

    isInvalid() {
        let { invalid, invalidMsn } = this.props
        if (invalid) {
            if (invalidMsn !== undefined)
                return <FormControl.Feedback type="invalid">{invalidMsn}</FormControl.Feedback>
        }
        return <></>
    }

    isValid() {
        let { valid, validMsn } = this.props
        if (valid) {
            if (validMsn !== undefined)
                return <FormControl.Feedback type="valid">{validMsn}</FormControl.Feedback>
        }
        return <></>
    }
    after() {
        let { after } = this.props
        if (after !== undefined)
            return <InputGroup.Append>
                <InputGroup.Text>{after}</InputGroup.Text>
            </InputGroup.Append>
        return <></>
    }
    setChangeString(e: React.ChangeEvent<HTMLSelectElement>) {
        let { update, setData, type } = this.props
        let { value } = e.currentTarget
        let r: string | number
        if (type === "number") {
            r = (value === "") ? 0 : parseFloat(value)
        } else {
            r = value.toUpperCase()
        }
        setData({ [update]: r })
    }

    getValue() {
        if (this.state.select)
            return { defaultValue: this.props.value }
        return { value: this.props.value }
    }
}