import Axios from "axios";
import React from "react";
import { Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { don } from "../../../Conexiones/Minimo";
import { Permisos } from "../../../config/Permisos";
import servicio, { conServicios, Ocupado } from "../../../Model/Sevicios";
import { Storage } from "../../../Storage";
import { Calendario, celda } from "../../../Utils/Calendario";
import FilaServicio from '../FilaServicio'

interface State {
    servicios?: servicio[]
    Ocupados?: Ocupado[]
    cita?: Ocupado
}
class CalendarioServicios extends React.Component<any, State> {
    Dates = conServicios()
    constructor(p: any) {
        super(p)
        this.state = {
            servicios: undefined,
            Ocupados: undefined,
            cita: undefined
        }
        this.setState = this.setState.bind(this)
    }
    componentWillMount() {
        this.context.set({ title: "CITAS DE PROXIMOS SERVICIOS" });
        var yo = this;
        this.Dates.setCargar((itens: any[]) => {
            yo.update();
        });
    }
    componentDidMount() {
        let { setState } = this
        this.Dates.liDates(setState)
        this.Dates.upDates(setState)
    }
    render() {
        let { authFuncion } = Permisos()
        let cl = "m-0 font-weight-bold text-primary mr-auto align-self-center";
        if (!authFuncion("servicio"))
            return <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h1 className={cl}>
                        No esta autorizado para entrar en esta seccion
                </h1>
                </Card.Header>
            </Card>
        let { state: { Ocupados, servicios } } = this
        if (Ocupados === undefined || Ocupados === null)
            return <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                <h4 style={{ position: "absolute" }} className={cl}>
                    Cargando....
                </h4>
            </Row>
        return <>
            <Card style={{ height: 500 }} className="shadow mb-4">
                <Calendario
                    key="calendario"
                    celdas={this.getCeldas()}
                    selectDate={this.select.bind(this)} />
            </Card>
            <Card className="shadow mb-4">
                <Card.Body>
                    <Row>
                        <Col md="12">
                            <Table>
                                <tr>
                                    <th>NUMERO</th>
                                    <th>FECHA</th>
                                    <th>CLIENTE</th>
                                    <th>MONTO</th>
                                    <th>ESTATUS</th>
                                    <th>ACCIONES</th>
                                </tr>
                                {servicios?.map(c => {
                                    return <FilaServicio update={this.update.bind(this)} key={'ser' + c.id + c.status} cotizacion={c} />
                                })}
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card >
        </>
    }
    select(cita: Ocupado) {
        this.setState({ cita });
    }
    componentDidUpdate(p: any, s: State) {
        if (this.state.cita?.date !== s.cita?.date || this.state.cita?.turn !== s.cita?.turn)
            this.update();
    }
    update() {
        if (this.state.cita)
            this.cargarServicios(this.state.cita);
    }
    async cargarServicios(data: Ocupado) {
        try {
            let rest = await Axios({ method: 'post', url: don + "/api/services/list/turn", data })
            this.setState({ servicios: rest.data.data })
        } catch (e) {
        }
    }
    getCeldas(): Array<celda> {
        let { Ocupados } = this.state;
        let citas: Array<celda> = []
        if (Ocupados !== undefined) {
            citas = Ocupados.map((o: Ocupado) => {
                return { ...o, color: '#007bff' }
            })
        }
        return citas
    }

}
CalendarioServicios.contextType = Storage;
export default CalendarioServicios;