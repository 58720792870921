import { Minimo } from "../Conexiones/Minimo"
import { fechas } from "./Generales"
import Servicio from "./Sevicios"

export class Valorar extends Minimo {
    tabla = 'tablas'
    coleccion = 'feedback'
    lista: string = 'valoraciones'
}
var conVal: Valorar
export function conValorar() {
    if (conVal === undefined)
        conVal = new Valorar()
    return conVal
}
export interface valorFila extends fechas {
    id: number
    service_id: number
    client_id: number
    name: string
    status: number
}
export interface Valor extends valorFila {
    service: Servicio
    comment_service: string
    comment_tech: string
    rank_service: number
    rank_tech: number
}

export function sendValor(v: Valor) {
    return {
        id: v.id,
        status: 3,
        comment_service: v.comment_service,
        comment_tech: v.comment_tech,
        rank_service: v.rank_service,
        rank_tech: v.rank_tech
    }
}