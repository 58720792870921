import React from "react";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import Axios from "axios";
import { don } from "../../../Conexiones/Minimo";
import { SelectorUser } from "../SelectorUser";
import { Storage } from "../../../Storage";
import { Tecnico } from "../../../Model/Sevicios";
import Graficas from "./Graficas";
import GraficasGeneral from "../Graficas";
import Fechas from "../Impresos/Fechas";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalPrint from "../../../Utils/ModalPrint";
import { Permisos } from "../../../config/Permisos";
interface State {
    modulo: string
    metodo: string
    open: boolean
    tech: number
    Users: Tecnico[]
}
class Usuarios extends React.Component<any, State> {
    sent = 0;
    constructor(p: any) {
        super(p);
        this.state = {
            modulo: "graficas",
            metodo: "list",
            open: false,
            tech: 0,
            Users: []
        }
        this.setUsers();
    }
    async setUsers() {
        try {
            let rest = await Axios.get(don + "/api/users/list/operators");
            this.setState({ Users: rest.data.data })
        } catch (e) {

        }
    }
    render() {
        let { tech, Users, modulo } = this.state;
        let { authFuncion } = Permisos()
        if (!authFuncion("reporte_callcenter"))
            return <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        No esta autorizado para entrar en esta seccion
                </h1>
                </Card.Header>
            </Card>
        return <Card>
            <Card.Body as={Row}>
                <Card.Body as={Col} md={12}>
                    <SelectorUser Users={Users} tech={tech} setData={this.setState.bind(this)} >
                        <ButtonGroup>
                            <Button variant={"graficas" === modulo ? "primary" : "outline-dark"}
                                onClick={() => { this.setState({ modulo: "graficas" }) }}>
                                GRAFICAS
                            </Button>
                            <Button variant={"sellers" === modulo ? "primary" : "outline-dark"}
                                onClick={() => { this.setState({ modulo: "sellers" }) }}>
                                VENTAS
                            </Button>
                        </ButtonGroup>
                    </SelectorUser>
                    {this.getBody()}
                </Card.Body>
            </Card.Body>
        </Card>
    }
    componentDidUpdate(p: any, s: State) {
        if (this.state.tech !== 0 && s.tech === 0)
            this.forceUpdate();
    }
    getBody() {
        let { tech, modulo, open } = this.state;
        switch (modulo) {
            case "graficas":
                return tech === 0 ? <GraficasGeneral /> : <Graficas tech={tech} />;
            case "sellers":
                return <Card>
                    {open ? <ModalPrint title="IMPRIMIR" url={"pdf/" + this.getPrint()} close={() => this.setState({ open: false })} /> : <></>}
                    <Card.Header>
                        <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center"> imprimir reporte </h1>
                    </Card.Header>
                    <Card.Body>
                        <Fechas metodo={this.setMetodo.bind(this)} />
                        <Button className="float-right"
                            onClick={() => this.setState({ open: true })}
                            variant="primary">
                            <FontAwesomeIcon icon={faPrint} />
                        </Button>
                    </Card.Body>
                </Card>;
        }
    }
    setMetodo(metodo: string) {
        this.setState({ metodo })
    }
    getPrint() {
        let { tech, modulo, metodo } = this.state;
        if (tech === 0) return modulo + "/" + metodo;
        return modulo + "/" + metodo + "/" + tech;
    }
}
Usuarios.contextType = Storage;
export default Usuarios;
