import React from 'react'
import Productos, { conProductos } from '../../Model/Productos'
import Paginacion from '../../Utils/Paginacion'
import { Modal, Row, Table, Button, FormControl, InputGroup, Col, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { CategoriaSelector } from './CategoriaSelector'
import SetText from '../../Utils/setTex'
import { Producto } from '../../Model/Generales'
class Fila extends React.Component<{ producto: Productos, add: (iten: Producto) => void, close: () => void }, Producto>{
    constructor(p: { producto: Productos, add: (iten: Producto) => void, close: () => void }) {
        super(p)
        this.state = {
            product: p.producto,
            techs: p.producto.quantity,
            quantity: 1,
            price: p.producto.cost + (p.producto.cost * p.producto.price_2 / 100),
            comment: "",
            quantity_turns: p.producto.quantity
        }
    }
    setPriceChance(e: React.ChangeEvent<HTMLSelectElement>) {
        let value = e.currentTarget.value as string
        let r = (value === "") ? 0 : parseInt(value)
        this.setState({ price: r })
    }

    minValue(e: React.FocusEvent<HTMLInputElement>) {
        let value = e.currentTarget.value as string
        let r = (value === "") ? 0 : parseInt(value)
        let { producto } = this.props
        let min = producto.cost + (producto.cost * producto.price_1 / 100)
        if (r < min)
            r = min
        this.setState({ price: r })
        e.currentTarget.value = "" + r
    }

    render() {
        let { producto: { code, description, price_1, price_2, price_3, cost, id } } = this.props
        return <tr>
            <th>{code}</th>
            <th>{description}</th>
            <th><SetText type="number" setData={this.setState} value={this.state.quantity} update="quantity" /></th>
            <th><SetText type="number" setData={this.setState} value={this.state.techs} update="techs" /></th>
            <th><SetText type="number" setData={this.setState} value={this.state.quantity_turns} update="turns" /></th>
            <th>
                <FormControl onBlur={this.minValue.bind(this)} min={(cost + (cost * price_1 / 100))} onChange={this.setPriceChance.bind(this)} list={"edit" + id} type="number" step="0.01" defaultValue={this.state.price} />
                <datalist id={"edit" + id}>
                    <option value={cost + (cost * price_1 / 100)}>{cost + (cost * price_1 / 100)}</option>
                    <option value={cost + (cost * price_2 / 100)}>{cost + (cost * price_2 / 100)}</option>
                    <option value={cost + (cost * price_3 / 100)}>{cost + (cost * price_3 / 100)}</option>
                </datalist>
            </th>
            <th>
                
                <Button data-tip="Agregar Producto" onClick={() => { this.props.add(this.state) }} variant="outline-dark">
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </th>
        </tr >
    }

    componentWillReceiveProps(p: { producto: Productos }) {
        this.setState({
            product: p.producto,
            techs: 1,
            quantity: p.producto.quantity,
            price: p.producto.cost + (p.producto.cost * p.producto.price_2 / 100),
            comment: ""
        })
    }
}
export default class BuscarProducto extends React.Component<{ open: boolean, add: (iten: Producto) => void, close: () => void }, { act: number, productos?: Array<Productos>, categoria: number }>{
    page = Paginacion();
    con = conProductos();
    constructor(pr: { open: boolean, add: (iten: Producto) => void, close: () => void }) {
        super(pr);
        this.setState = this.setState.bind(this);
        this.state = { act: 0, categoria: 0 };
        this.page.row = (p: Productos) => {
            return <Fila key={"producto" + p.id + p.status} producto={p} add={pr.add} close={pr.close} />
        };
        this.page.act = this.act.bind(this);
        this.page.bucarLabel = ["description", "code"];
        this.con.setActivas(this.setProductos.bind(this));
    }
    setProductos(productos?: Array<Productos>) {
        this.setState({ productos });
    }

    render() {
        return <Modal size="xl" centered animation show={this.props.open} onHide={this.props.close}>
            <Modal.Header closeButton>
                <Col as="h6" md="9" className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                BUCAR PRODUCTO
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder="DESCRIPCIÓN O CÓDIGO DEL PRODUCTO" onKeyUp={this.page.setBuscar} />
                        <CategoriaSelector defaultValue={0} change={this.changeCategoria.bind(this)} />
                    </InputGroup>
                </Col>
            </Modal.Header>
            <Modal.Body>
                {(this.state.productos === undefined) ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                    <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                    <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Cargando....
                    </h4>
                </Row> :
                    <Row>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th onClick={() => { this.page.setOrden("code") }}>CÓDIGO{this.page.flechas("code")}</th>
                                    <th onClick={() => { this.page.setOrden("description") }}>DESCRIPCIÓN{this.page.flechas("description")}</th>
                                    <th>CANT</th>
                                    <th>MDO</th>
                                    <th>TURNOS</th>
                                    <th>PRECIO</th>
                                    <th>ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.page.body()}
                            </tbody>
                        </Table>
                    </Row>}
                <Row className="py-3 d-flex">
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">productos LISTADO {this.page.length}</h6>
                    {this.page.pagination()}
                </Row>
            </Modal.Body>
        </Modal>
    }
    componentWillUpdate(nextProps: any, nextState: { productos?: Array<Productos>, categoria: number }) {
        let { productos, categoria } = nextState;
        if (productos !== undefined) {
            this.page.setItens(productos);
            if (categoria !== this.state.categoria)
                if (categoria !== 0)
                    this.page.setItens(productos.filter(p => {
                        if (p.category_id === categoria) return true;
                        return false;
                    }));
                else
                    this.page.setItens(productos)
        }
    }
    changeCategoria(categoria: number) {
        this.setState({ categoria })
    }
    act() {
        this.setState({ act: this.state.act + 1 });
    }
}