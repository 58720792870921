import React from 'react'
import { Card, Row, Spinner } from 'react-bootstrap'
import Cotizacion, { conCotizacion, CotizacionDefault, } from '../../Model/Cotizacion'
import UtileServicios from '../../Utils/UtileServicios'
import { Storage } from '../../Storage'
import Cliente from '../../Model/Clientes'
import ClienteSelector from '../Clientes/ClienteSelector'
import { toast } from 'react-toastify'
import { zfill } from '../../Utils/Formats'
import { Redirect } from 'react-router-dom'
import { SentData } from '../../Model/Generales'
import { Permisos } from '../../config/Permisos'
import ReactTooltip from 'react-tooltip'
import { don } from '../../Conexiones/Minimo'
interface Props {
    match: { params: { id?: number } }
}
interface State extends Cotizacion {
    print: boolean
    procesar: boolean
    redirect: boolean
}
class NuevaCotizacion extends React.Component<Props, State> {
    con = conCotizacion()
    id = 0;
    constructor(p: any) {
        super(p)
        this.state = { ...CotizacionDefault, procesar: false, print: false, redirect: false }
    }
    render() {
        let { authFuncion } = Permisos()
        if (!authFuncion("cotizacion"))
            return <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        No esta autorizado para entrar en esta seccion
                </h1>
                </Card.Header>
            </Card>
        if (this.state.redirect)
            return <Redirect to="/cotizaciones" />
        let editable: boolean = true
        return <Card style={{ height: "100%" }} className="shadow mb-4">
            <Card.Body style={{ height: "100%" }}>
                {this.editable() ?
                    <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                        <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                        <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                            Cargando....
                        </h4>
                    </Row> : this.state.print ? <Row>
                        <iframe src={don + "/api/pdf/quotation/store/" + this.id} width='100%' height='700wh' title="print" scrolling='no' frameBorder='0'></iframe>
                    </Row> : <>
                        <ClienteSelector add={this.addCliente.bind(this)} Cliente={this.state.client} />
                        <ReactTooltip />
                        <UtileServicios key={"productosCot" + this.id} editable={editable} Prosesar={this.prosesar.bind(this)} Cotizacion={this.state} setData={this.setState.bind(this)} />
                    </>}
            </Card.Body>
        </Card>
    }
    addCliente(client: Cliente) {
        this.setState({ client })
    }

    getTotal() {
        let exempt = 0, taxable = 0;
        this.state.products.forEach(p => {
            if (p.product.exempt) {
                exempt += p.price * p.quantity;
            } else {
                taxable += p.price * p.quantity;
            }
        })
        return { exempt, taxable };
    }

    prosesar() {
        if (this.state.client.id === 0)
            toast('Debe seleccionar un cliente', { type: toast.TYPE.ERROR })
        else if (this.state.products.length === 0)
            toast('Debe colocar al menus un servicio', { type: toast.TYPE.ERROR })
        else {
            this.setState({ procesar: true })
            if (this.props.match !== undefined)
                if (this.props.match.params.id !== undefined) {
                    this.con.update(SentData(this.state, this.getTotal()), () => {
                        this.setState({ procesar: false, print: true })
                    })
                } else {
                    this.con.create(SentData(this.state, this.getTotal()), (data) => {
                        this.id = data.id;
                        this.setState({ procesar: false, print: true })
                    })
                }
        }
    }

    editable() {
        if (this.state.procesar) {
            return true
        }
        const { id } = this.props.match.params
        if (id !== undefined) {
            if (this.state.id !== 0) {
                return false
            } else {
                return true
            }
        }
        return false
    }

    componentDidMount() {
        this.context.set({ title: "NUEVA COTIZACION" })
        const { id } = this.props.match.params
        let { set } = this.context
        let setState = this.setState.bind(this)
        if (id !== undefined) {
            this.id = id;
            this.con.select(id, (t: Cotizacion) => {
                set({ title: "EDITAR COTIZACIÓN N° " + zfill(t.id) })
                if (t.status === 0) {
                    toast('no se puede editar una cotizacion eliminada', { type: toast.TYPE.ERROR })
                    setState({ redirect: true })
                } else if (t.status === 2) {
                    toast('no se puede editar una cotizacion ya procesada', { type: toast.TYPE.ERROR })
                    setState({ redirect: true })
                }
                setState(t)
            })
        }
    }
}
NuevaCotizacion.contextType = Storage
export default NuevaCotizacion