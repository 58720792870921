import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap';
import { Storage } from '../../Storage';
import Cliente, { conCliente } from '../../Model/Clientes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import FormCliente from './FormCliente';

class FilaCliente extends React.Component<{ cliente: Cliente, update?: () => void }, { open: boolean }> {
    con = conCliente()
    constructor(p: any) {
        super(p);
        this.state = { open: false };
    }
    render() {
        let cliente = this.props.cliente
        return <tr>
            <th>{cliente.code}</th>
            <th>{cliente.name}</th>
            <th>
                {this.state.open ? <FormCliente cliente={this.props.cliente} title={"EDITAR CLIENTE " + this.props.cliente.name} close={() => { this.setState({ open: false }) }} /> : <></>}
                <ReactTooltip />
                <Button data-html={true} data-type="light" data-class="toolbody" data-tip={this.tooltip()} variant="link">
                    {cliente.contact_person}
                </Button>
            </th>
            <th style={{ width: "100px" }}>{this.getStatus()}</th>
            <th>
                <ButtonGroup>
                    <Button data-tip="editar" onClick={() => { this.setState({ open: true }) }} variant="info">
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    {this.getBotton()}
                </ButtonGroup>
            </th>
        </tr >
    }

    tooltip() {
        let { cliente } = this.props
        return "<h9 class='m-0 font-weight-bold text-primary mr-auto align-self-center'>DATOS DE CONTACTO</h9><br />" +
            "<b>CORREO ELECTRONICO:</b><br />" +
            cliente.email + "<br />" +
            "<b>TELEFONO:</b><br />" +
            cliente.phone
    }

    getStatus() {
        switch (this.props.cliente.status) {
            case 0:
                return <span className="bg-danger text-white p-1 d-inline-block">ELIMINADO</span>
            case 1:
                return <span className="bg-success text-white p-1 d-inline-block">ACTIVADO</span>
        }
    }
    activar() {
        this.con.stateChange({ id: this.props.cliente.id, status: 1 }, this.props.update)
    }

    delete() {
        this.con.stateChange({ id: this.props.cliente.id, status: 0 }, this.props.update)
    }

    getBotton() {
        if (this.props.cliente.status === 1)
            return <Button data-tip="INHABILITAR" onClick={this.delete.bind(this)} variant="danger"><FontAwesomeIcon icon={faTimes} /></Button>
        else
            return <Button data-tip="HABILITAR" onClick={this.activar.bind(this)} variant="success"><FontAwesomeIcon icon={faCheck} /></Button>
    }

}
FilaCliente.contextType = Storage
export default FilaCliente