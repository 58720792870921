
import React from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { zfill } from '../../Utils/Formats'
import moment from 'moment'
import Servicio, { conServicios, ServicioDefault } from '../../Model/Sevicios'
import Chat from '../Servicios/Chat'
import CalendarioServicio from '../Servicios/CalendarioServicio'
import DetalleCotizacion from '../Cotizacion/DetalleCotizacion'
import ReactTooltip from 'react-tooltip'
moment.locale('es')
interface Props {
    cotizacion: Servicio
    select: (c: any) => void
}
interface State extends Servicio {
    chat: boolean
    verCalendario: boolean
    verDetalles: boolean
}
export default class FilaServicio extends React.Component<Props, State>{
    con = conServicios()
    constructor(p: Props) {
        super(p)
        this.state = {
            ...p.cotizacion,
            chat: false,
            verCalendario: false,
            verDetalles: false,
        }
    }
    render() {
        let cot = this.props.cotizacion
        let { chat, verDetalles, verCalendario, id } = this.state
        return <tr>
            {chat ? <Chat open={chat} id={id} close={this.close.bind(this)} /> : <></>}
            {verDetalles ? <DetalleCotizacion select={cot} open={verDetalles} close={this.close.bind(this)} /> : <></>}
            {verCalendario ? <CalendarioServicio open={verCalendario} select={this.state} close={this.close.bind(this)} /> : <></>}
            <th style={{ width: "115px" }}>
                <ReactTooltip />
                <Button data-tip="ver detalles" variant="link" onClick={() => { this.setState({ verDetalles: true }) }}>
                    {zfill(cot.id)}
                </Button>
            </th>
            <th style={{ width: "150px" }}>{moment(cot.created_at).format("DD-MM-YYYY")}</th>
            <th style={{ width: "100px" }}>{this.getStatus()}</th>
            <th style={{ width: "128px" }}>

                <ButtonGroup>
                    <Button data-tip="Ver chat" onClick={() => { this.setState({ chat: true }) }} variant="dark">
                        <FontAwesomeIcon icon={faComments} />
                    </Button>
                    <Button data-tip="Ver calendario" variant="secondary" onClick={() => { this.setState({ verCalendario: true }) }}>
                        <FontAwesomeIcon icon={faCalendar} />
                    </Button>
                </ButtonGroup>
            </th>
        </tr>
    }
    getStatus() {
        switch (this.props.cotizacion.status) {
            case 1:
                return <span className="bg-warning text-black p-1 d-inline-block">Espera</span>
            case 2:
                return <span className="bg-info text-white p-1 d-inline-block">Activa</span>
            case 3:
                return <span className="bg-success text-white p-1 d-inline-block">Procesada</span>
        }
    }
    async set() {
        if (this.state.products === undefined) {
            this.setState(ServicioDefault)
            this.setState(this.props.cotizacion)
            await this.con.select(this.props.cotizacion.id, (selected: Servicio) => {
                this.setState(selected)
            })
        }
    }
    close() {
        this.setState({
            verCalendario: false,
            verDetalles: false,
            chat: false
        })
    }
}