import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import { ButtonGroup, Card, Col, FormControl, InputGroup, Row, Button } from "react-bootstrap"
import { ContribuyenteSelector } from "../../Clientes/FormCliente";
import { CategoriaSelector } from "../../Productos/CategoriaSelector";
import Fechas from "./Fechas";
import ModalPrint from "../../../Utils/ModalPrint";
import { RolSelector } from "../../Users/RolSelector";
import Graficas from "../Graficas";
import { Permisos } from "../../../config/Permisos";

interface State {
    modulo: string
    metodo: string
    filtro: string
    status: string
    open: boolean
}
export class Impresos extends React.Component<any, State> {
    per = Permisos()
    constructor(p: any) {
        super(p);
        this.state = {
            modulo: "graficas",
            metodo: "list",
            filtro: "a",
            status: "10",
            open: false
        }
    }
    render() {
        let { authFuncion } = this.per;
        let { open, modulo } = this.state;
        return <Card>
            {open ? <ModalPrint title="IMPRIMIR" url={"pdf/" + this.getUrl()} close={() => this.setState({ open: false })} /> : <></>}
            <Card.Header>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Reportes Generales
                </h6>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col md="2">
                        <ButtonGroup vertical>
                            <Button variant={"graficas" === modulo ? "primary" : "outline-dark"}
                                onClick={() => { this.setState({ modulo: "graficas" }) }}>
                                GRAFICAS
                            </Button>
                            {authFuncion("cotizacion") ?
                                <Button variant={"quotation" === modulo ? "primary" : "outline-dark"}
                                    onClick={() => { this.setState({ modulo: "quotation" }) }}>
                                    COTIZACIONES
                                </Button> : <></>}
                            {authFuncion("servicio") ?
                                <Button variant={"services" === modulo ? "primary" : "outline-dark"}
                                    onClick={() => { this.setState({ modulo: "services" }) }}>
                                    SERVICIOS
                                </Button> : <></>}
                            {authFuncion("valoracion") ?
                                <Button variant={"feedback" === modulo ? "primary" : "outline-dark"}
                                    onClick={() => { this.setState({ modulo: "feedback" }) }}>
                                    VALORACIONES
                                </Button> : <></>}
                            {authFuncion("cliente") ?
                                <Button variant={"clients" === modulo ? "primary" : "outline-dark"}
                                    onClick={() => { this.setState({ modulo: "clients", metodo: "list", filtro: "a" }) }}>
                                    CLIENTES
                                </Button> : <></>}
                            {authFuncion("producto") ?
                                <Button variant={"products" === modulo ? "primary" : "outline-dark"}
                                    onClick={() => { this.setState({ modulo: "products", metodo: "list", filtro: "a" }) }}>
                                    PRODUCTOS
                                </Button> : <></>}
                            {authFuncion("user") ?
                                <Button variant={("users" === modulo) ? "primary" : "outline-dark"}
                                    onClick={() => { this.setState({ modulo: "users", metodo: "list", filtro: "a" }) }}>
                                    USUARIO
                                </Button> : <></>}
                        </ButtonGroup>
                    </Col>
                    {"graficas" === modulo ?
                        <Col md="10">
                            <Graficas />
                        </Col> : <Col md="10">
                            <Row>
                                <Col md="6">{this.getStatus()}</Col>
                                <Col md="6">{this.getFiltro()}</Col>
                            </Row>
                            {this.getFechas()}
                            <Button className="float-right"
                                onClick={() => this.setState({ open: true })}
                                variant="primary">
                                <FontAwesomeIcon icon={faPrint} />
                            </Button>
                        </Col>
                    }
                </Row>
            </Card.Body>
        </Card>
    }
    getFiltro() {
        switch (this.state.modulo) {
            case "clients":
                return this.contribuyente();
            case "products":
                return this.categoria();
            case "users":
                return this.roles();
            default:
                return <></>;
        }

    }
    getStatus() {
        switch (this.state.modulo) {
            case "quotation":
                return this.statusCotizacion();
            case "services":
                return this.statusServicio();
            case "feedback":
                return this.statusValoracion();
            default:
                return this.statusModel();
        }
    }
    contribuyente() {
        let d = this.state.filtro === "a" ? 0 : parseInt(this.state.filtro);
        return <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>
                    TIPO DE CONTRIBUYENTE
                </InputGroup.Text>
            </InputGroup.Prepend>
            <ContribuyenteSelector defaultValue={d} change={this.setFiltro.bind(this)} />
        </InputGroup >
    }
    categoria() {
        let d = this.state.filtro === "a" ? 0 : parseInt(this.state.filtro);
        return <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>
                    CATEGORIAS
                </InputGroup.Text>
            </InputGroup.Prepend>
            <CategoriaSelector defaultValue={d} change={this.setFiltro.bind(this)} />
        </InputGroup>
    }
    roles() {
        let d = this.state.filtro === "a" ? 0 : parseInt(this.state.filtro);
        return <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>
                    Roles
                </InputGroup.Text>
            </InputGroup.Prepend>
            <RolSelector defaultValue={d} change={this.setFiltro.bind(this)} />
        </InputGroup>
    }
    setFiltro(n: number) {
        let filtro = n === 0 ? "a" : n + "";
        this.setState({ filtro })

    }
    statusModel() {
        return <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>ESTADO</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl onChange={this.setStatus.bind(this)} as="select" defaultValue="a">
                <option value="a">TODOS LOS ESTADOS</option>
                <option value="0">INHABILITADO</option>
                <option value="1">HABILITADO</option>
            </FormControl>
        </InputGroup>
    }
    statusCotizacion() {
        return <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>ESTADO</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl onChange={this.setStatus.bind(this)} as="select" defaultValue="a">
                <option value="a">TODOS LOS ESTADOS</option>
                <option value="0">CANCELADO</option>
                <option value="1">ESPERA</option>
                <option value="2">PROCESADOS</option>
            </FormControl>
        </InputGroup>
    }
    statusServicio() {
        return <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>ESTADO</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl onChange={this.setStatus.bind(this)} as="select" defaultValue="a">
                <option value="a">TODOS LOS ESTADOS</option>
                <option value="0">CANCELADO</option>
                <option value="1">ESPERA</option>
                <option value="2">ACTIVO</option>
                <option value="3">PROCESADOS</option>
            </FormControl>
        </InputGroup>
    }
    statusValoracion() {
        return <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>ESTADO</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl onChange={this.setStatus.bind(this)} as="select" defaultValue="a">
                <option value="a">TODOS LOS ESTADOS</option>
                <option value="1">ESPERA</option>
                <option value="2">POR VALORAR</option>
                <option value="3">VALORADO</option>
            </FormControl>
        </InputGroup>
    }
    setStatus(Rol: React.ChangeEvent<HTMLSelectElement>) {
        let status = Rol.currentTarget.value as string
        this.setState({ status });
    }
    setMetodo(metodo: string) {
        this.setState({ metodo })
    }
    getUrl() {
        let { modulo, metodo, filtro, status } = this.state;
        if (modulo === "products" || modulo === "users" || modulo === "clients")
            return modulo + "/" + metodo + "/" + filtro + "/" + status;
        return modulo + "/" + metodo + "/" + status;
    }
    getFechas() {
        let { modulo } = this.state;
        if (modulo === "products" || modulo === "users" || modulo === "clients")
            return <></>;
        return <Fechas metodo={this.setMetodo.bind(this)} />;

    }
}