
import User from "./User"
import moment from 'moment'
import { DataOperacion, DefaultNull, SentData } from "./Generales"
import { General } from "../Conexiones/General"
import Axios from "axios"
import { toast } from "react-toastify"
import app from "../config/firebase"
import { Valor } from "./valoracion"

export class servicios extends General {
    tabla = 'tablas'
    coleccion = 'services'
    lista = 'servicios'
    async liDates(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(this.dominio + "/api/dates")
            let Ocu: Array<Cita> = rest.data.data
            let Ocupados = Ocu.map(U => {
                return {
                    ...U,
                    state: 1
                }
            })
            setData({ Ocupados })
        } catch (e: any) {
            if (e.response !== undefined)
                toast(e.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    upDates(setData: (d: any) => void) {
        let mi = this
        app.ref(this.tabla + '/' + this.coleccion)
            .on('value', snap => {
                if (mi.sent < snap.val()) {
                    this.sent = snap.val()
                    this.liDates(setData)
                }
            })
    }

    async selectTurn(data: Ocupado, setData: (d: any) => void) {
        try {
            let rest = await Axios({ method: 'put', url: this.dominio + "/api/services/turn", data })
            setData(rest.data)
        } catch (e: any) {
            if (e.response !== undefined)
                toast(e.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
}
var conSer: servicios
export function conServicios() {
    if (conSer === undefined)
        conSer = new servicios()
    return conSer
}
export default interface Servicio extends DataOperacion {
    address: string
    feedback: null | Valor
    dates: Array<Ocupado>
    users: Array<Tecnico>
}
export interface Cita {
    id?: number
    turn: number,
    date: string,
    status: number
}
export interface Ocupado extends Cita {
    service_id?: number
    Tecnicos: Array<{ user_id: number }>
    state: number
}

export interface marcado extends Ocupado {
    start?: null | string
    end?: null | string
}
export interface Tecnico extends User {
    user_id: number
    citas?: Array<Cita>
    state: number
}
export var ServicioDefault: Servicio = {
    ...DefaultNull,
    address: "",
    feedback: null,
    dates: [],
    users: []
}
function SendUsers(users: Array<User>) {
    return users.map(u => {
        return {
            user_id: u.id,
        }
    })
}
function SendTurnos(citas: Array<Cita>) {
    return citas.map(c => {
        return SendCita(c)
    })
}
export function SendCita(Cita: Cita) {
    return {
        date: moment(Cita.date).format("YYYY-MM-DD"),
        turn: Cita.turn
    }

}
export function SendServicio(Servicio: Servicio, total: { taxable: number, exempt: number }) {
    return {
        ...SentData(Servicio, total),
        address: Servicio.address,
        users: SendUsers(Servicio.users),
        dates: SendTurnos(Servicio.dates)
    }
}