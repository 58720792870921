import React from 'react'
import { Storage } from '../../Storage'
import Cliente, { conCliente } from '../../Model/Clientes'
import Fila from '../Clientes/FilaCliente'
import FormCliente from '../Clientes/FormCliente'
import { PermisosType } from '../../config/Permisos'
import Tabla from '../../Utils/Table'
import { Button, Col, FormCheck, Row } from 'react-bootstrap'
import ClientesExcel from '../Reportes/Excel/Cliente'
class ListClientes extends Tabla {
    labelBusqueda = "BUSCAR CLIENTE";
    bucarLabel = ["code", "name", "contact_person", "address"];
    permiso: PermisosType = "cliente";
    con = conCliente();
    eliminados = false;
    componentWillMount() {
        this.context.set({ title: "LISTA DE CLIENTES" });
        this.con.setCargar(this.setItens.bind(this));
    }
    filtros(itens: Cliente[]) {
        if (this.eliminados)
            return itens;
        return itens.filter(c => {
            if (c.status === 1) return true;
            return false;
        })
    }
    busquedaMostrar() {
        return <Row>
            <Col as="h6" md="6" className="m-0 font-weight-bold text-primaryalign-self-center">
                {this.Buscador(this.labelBusqueda)}
            </Col>
            <Col xs='4' className="mr-auto">
                <FormCheck
                    id="imp"
                    type="switch"
                    label="MOSTRAR ELIMINADOS"
                    checked={this.eliminados}
                    onClick={() => { this.eliminados = !this.eliminados; this.forceUpdate() }}
                />
            </Col>
            <ClientesExcel clientes={this.busqueda()} />
            <Button onClick={() => { this.setState({ open: true }) }} variant="primary">NUEVO</Button>
        </Row>
    }
    Row(p: Cliente) {
        return <Fila update={() => { this.con.setLista(this.setItens.bind(this)) }} key={"cliente" + p.id + p.status} cliente={p} />
    }
    Header() {
        return <tr>
            <th onClick={() => { this.setOrden("code") }}>RUC {this.flechas("code")}</th>
            <th onClick={() => { this.setOrden("name") }}>
                {this.state.open ? <FormCliente cliente={null} title={"nuevo cliente"} close={this.close.bind(this)} /> : <></>}
                NOMBRE {this.flechas("name")}</th>
            <th onClick={() => { this.setOrden("contact_person") }}>CONTACTO {this.flechas("contact_person")}</th>
            <th>ESTATUS</th>
            <th>ACCIONES</th>
        </tr>
    }
}
ListClientes.contextType = Storage
export default ListClientes