import React from 'react'
import { Button, ButtonGroup, Modal, Row } from 'react-bootstrap'
import moment from 'moment'
import "moment/locale/es"
import { Storage } from '../Storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
moment.locale('es')
interface Props { msn: string, close: () => void, accion: () => void }
class Confimacion extends React.Component<Props> {
    render() {
        return <Modal className="modal-warning" size="sm" centered animation show={true} onHide={this.props.close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold mr-auto align-self-center">
                    <span><FontAwesomeIcon icon={faExclamationTriangle} /> <b>confirmar</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>{this.props.msn}</Row>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup className="float-right col-12">
                    <Button variant="success" onClick={() => { this.props.accion(); this.props.close(); }}>
                        Si
                    </Button>
                    <Button variant="danger" onClick={this.props.close}>
                        No
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    }
}
Confimacion.contextType = Storage;
export default Confimacion;