import React from "react"
import { Button, Card, Col, FormControl, InputGroup, Row, Spinner } from "react-bootstrap"
import Servicio, { ServicioDefault, SendServicio, conServicios } from "../../../Model/Sevicios"
import UtileServicios from "../../../Utils/UtileServicios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faEdit, faPrint, faQuestion } from "@fortawesome/free-solid-svg-icons"
import ClienteSelector from "../../Clientes/ClienteSelector"
import Cliente from "../../../Model/Clientes"
import { Storage } from "../../../Storage"
import { toast } from "react-toastify"
import { zfill } from "../../../Utils/Formats"
import { Permisos } from "../../../config/Permisos"
import SelectorTurnos from "./SelectorTurnos"
import SelectorTecnico from "./SelectorTecnico"
import Leyenda from "./Leyenda"
import ReactTooltip from "react-tooltip"
import { don } from "../../../Conexiones/Minimo"
interface State extends Servicio {
    procesar: boolean,
    etapa: 1 | 2 | 3
    sugeridos: number
    tecnicos: number
    conflicto: boolean
    editable: boolean
    leyenda: boolean
}
class NuevaCita extends React.Component<any, State> {
    con = conServicios();
    id = 0;
    constructor(p: any) {
        super(p)
        this.state = {
            ...ServicioDefault,
            etapa: 1,
            sugeridos: 0,
            tecnicos: 0,
            conflicto: false,
            editable: true,
            leyenda: false,
            procesar: false

        }
        this.setState = this.setState.bind(this)
    }
    render() {
        let { authFuncion } = Permisos()
        let cl = "m-0 font-weight-bold text-primary mr-auto align-self-center";
        if (!authFuncion("servicio"))
            return <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h1 className={cl}>
                        No esta autorizado para entrar en esta seccion
                </h1>
                </Card.Header>
            </Card>
        return <Card style={{ height: "100%" }} className="shadow mb-4">
            {this.state.leyenda ? <Leyenda close={() => { this.setState({ leyenda: false }) }} /> : <></>}
            <Card.Header className="py-3 d-flex">
                {(this.state.etapa === 1) ? <h6 className={cl}> Selecionar Servicios</h6> : ""}
                {(this.state.etapa === 2) ? <>
                    <h6 className={cl}> Asignar Cita</h6>
                    <Button onClick={() => { this.setState({ leyenda: true }) }} className={cl} variant="outline-info"><FontAwesomeIcon icon={faQuestion} /> </Button>
                </> : ""}
                {(this.state.etapa === 3) ? <h6 className={cl}> Imprimir Factura</h6> : ""}
                {this.pasos()}
            </Card.Header>
            <ReactTooltip />
            <Card.Body>
                {this.body()}
            </Card.Body>
        </Card>
    }
    setSugeridos(s: number) {
        this.setState({ sugeridos: s })
    }
    body() {
        let { etapa } = this.state
        return this.editable() ?
            <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Cargando....
                </h4>
            </Row> : <>
                <div style={{ display: (etapa === 1) ? "" : "none" }}>
                    <ClienteSelector key="cliente" add={this.addCliente.bind(this)} Cliente={this.state.client} />
                    <Col xs="12">
                        <Row>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Direccion del Servicio</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl onKeyUp={this.setDireccion.bind(this)} defaultValue={this.state.address} />
                            </InputGroup>
                        </Row>
                    </Col>
                    <UtileServicios key={"productosSer" + this.id}  editable={this.state.editable} Cotizacion={this.state} setData={this.setState} />
                </div>
                <Row style={{ height: "100%", display: (this.state.etapa === 2) ? "" : "none" }}>
                    <Col md="9" style={{height:'calc(100hw - 300px)'}}>
                        <SelectorTurnos key={"citas"} servicio={this.state} setData={this.setState} />
                    </Col>
                    <Col md="3">
                        <SelectorTecnico key={"tecnicos"} servicio={this.state} setData={this.setState} />
                    </Col>
                </Row>
                {etapa === 3 ? <Row>
                    <iframe src={don + "/api/pdf/service/store/" + this.id} width='100%' height='700wh' title="print" scrolling='no' frameBorder='0'></iframe>
                </Row> : <></>}
            </>

    }
    addCliente(client: Cliente) {
        console.log(client);

        this.setState({ client })
    }
    setDireccion(e: React.KeyboardEvent<HTMLInputElement>) {
        let { value } = e.currentTarget
        this.setState({ address: value })
    }
    pasos() {
        let a: string, b: string, c: string
        switch (this.state.etapa) {
            case 1:
                a = "f1-step active"
                b = "f1-step"
                c = "f1-step"
                break
            case 2:
                a = "f1-step activated"
                b = "f1-step active"
                c = "f1-step"
                break
            case 3:
                a = "f1-step activated"
                b = "f1-step activated"
                c = "f1-step active"
                break
        }
        return <div style={{ width: "210px", height: "50px", marginTop: "-3px" }} className="f1-steps">
            <div className="f1-progress">
                <div className="f1-progress-line"></div>
            </div>
            <div data-tip="editar elementos de la factura" onClick={() => { this.etapa(1) }} style={{ width: "70px" }} className={a}>
                <div className="f1-step-icon">
                    <FontAwesomeIcon icon={faEdit} />
                </div>
            </div>
            <div data-tip="asignar feha y tecnicos" onClick={() => { this.etapa(2) }} style={{ width: "70px" }} className={b}>
                <div className="f1-step-icon">
                    <FontAwesomeIcon icon={faCalendar} />
                </div>
            </div>
            <div data-tip="imprimir factura" style={{ width: "70px" }} className={c}>
                <div onClick={this.procesar.bind(this)} className="f1-step-icon">
                    <FontAwesomeIcon icon={faPrint} />
                </div>
            </div>
        </div>
    }
    getTotal() {
        let exempt = 0, taxable = 0;
        this.state.products.forEach(p => {
            if (p.product.exempt) {
                exempt += p.price * p.quantity;
            } else {
                taxable += p.price * p.quantity;
            }
        })
        return { exempt, taxable };
    }
    componentWillUpdate(p: any, s: State) {
        let { sugeridos, tecnicos } = this.state
        if (s.etapa === 2) {
            if (sugeridos === 0) {
                s.products.forEach(iten => {
                    if (iten.quantity_turns > sugeridos)
                        this.setState({ sugeridos: iten.quantity_turns })
                })
            }
            if (tecnicos === 0) {
                s.products.forEach(iten => {
                    if (iten.techs > tecnicos)
                        this.setState({ tecnicos: iten.techs })
                })
            }
        }
    }
    async procesar() {
        if (this.state.etapa !== 3)
            if (this.state.address === "")
                toast('Debe Colocar una Direccion para el servicio', { type: toast.TYPE.ERROR })
            else if (this.state.client.id === 0)
                toast('Debe seleccionar un cliente', { type: toast.TYPE.ERROR })
            else if (this.state.products.length === 0)
                toast('Debe colocar al menos un producto', { type: toast.TYPE.ERROR })
            else if (this.state.dates.length === 0)
                toast('No a asignado fecha para el servicio', { type: toast.TYPE.ERROR })
            else if (this.state.users.length === 0)
                toast('No a asignado tecnico al servicio', { type: toast.TYPE.ERROR })
            else if (this.state.conflicto)
                toast('Uno o más técnicos estan ocupados en las fecha asignados', { type: toast.TYPE.ERROR })
            else {
                this.setState({ procesar: true });
                if (this.props.match !== undefined)
                    if (this.props.match.params.id !== undefined) {
                        this.con.update(SendServicio(this.state, this.getTotal()), () => {
                            this.setState({ procesar: false });
                            this.etapa(3)
                        })
                    } else {
                        this.con.create(SendServicio(this.state, this.getTotal()), (data) => {
                            this.id = data.id;
                            this.setState({ procesar: false });
                            this.etapa(3)
                        })
                    }
            }
    }
    componentDidMount() {
        this.context.set({ title: "NUEVO SERVICIO" })
        const { id } = this.props.match.params
        let { set } = this.context
        let setState = this.setState.bind(this)
        let { sugeridos, tecnicos } = this.state
        if (id !== undefined) {
            this.id = id;
            this.con.select(id, (t: Servicio) => {
                setTimeout(() => {
                    set({ title: "EDITAR COTIZACIÓN N° " + zfill(t.id) })
                    if (t.status === 0) {
                        toast('no se puede editar una cotizacion eliminada', { type: toast.TYPE.ERROR })
                        setState({ etapa: 3 })
                    } else if (t.status === 2) {
                        setState({ editable: false })
                    } else if (t.status === 3) {
                        toast('no se puede editar una cotizacion ya terminada', { type: toast.TYPE.ERROR })
                        setState({ etapa: 3 })
                    }
                    set({ title: "EDITAR SERVICIO N° " + zfill(t.id) })
                    if (sugeridos < t.dates.length) {
                        setState({ sugeridos: t.dates.length })
                    }
                    if (tecnicos < t.users.length) {
                        setState({ tecnicos: t.users.length })
                    }
                    setState({ ...t })
                }, 200)
            })
        }
    }
    editable() {
        if (this.state.procesar) {
            return true
        }
        const { id } = this.props.match.params
        if (id !== undefined) {
            if (this.state.id !== 0) {
                return false
            } else {
                return true
            }
        }
        return false
    }
    etapa(e: 1 | 2 | 3) {
        if (this.state.etapa !== 3)
            this.setState({ etapa: e })
    }
}
NuevaCita.contextType = Storage
export default NuevaCita