import React from 'react'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputText from '../../Utils/InputText'
import { conReferidos, inputReferido, ReferidoNull } from '../../Model/Referidos'
import SetText from '../../Utils/setTex'
interface Props {
    open: boolean
    client_id?: number
    close: () => void
}
export default class Referir extends React.Component<Props, inputReferido> {
    con = conReferidos()
    constructor(p: Props) {
        super(p)
        this.state = ReferidoNull
        this.setState = this.setState.bind(this)
    }
    render() {
        let { setState, state: { name, phone, note } } = this
        return <Modal centered animation show={this.props.open} onHide={this.props.close}>
            <Modal.Header closeButton>
                referir cliente
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs="12">
                        <InputText label="NOMBRE" value={name} setData={setState} update="name" />
                    </Col>
                    <Col xs="12">
                        <InputText label="TELÉFONO" value={phone} setData={setState} update="phone" />
                    </Col>
                    <Col xs="12">
                        <b>Comentario</b>
                        <SetText as="textarea" value={note} setData={setState} update="note" />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.create.bind(this)} variant="primary"><FontAwesomeIcon icon={faSave} /></Button>
            </Modal.Footer>
        </Modal>
    }
    async create() {
        let { state, props: { client_id } } = this
        await this.con.create({ ...state, client_id })
        this.props.close()
    }

}