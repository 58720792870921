import React from 'react'
import { FormControl } from 'react-bootstrap'
import Categoria, { conCategorias } from '../../Model/Categoria';

interface Props {
    change: (r: number) => void
    defaultValue: number
}
export class CategoriaSelector extends React.Component<Props, { categorias?: Categoria[] }>{
    con = conCategorias()
    constructor(p: Props) {
        super(p);
        this.state = { categorias: undefined };
        this.con.setActivas(this.setCategorias.bind(this));
    }
    setCategorias(c: Categoria[]) {
        this.setState({ categorias: c })
    }
    render() {
        return <FormControl onChange={this.setRol.bind(this)} as="select" defaultValue={this.props.defaultValue}>
            <option value={0}>SELECCIONAR CATEGORÍA</option>
            {(this.state.categorias !== undefined) ? this.state.categorias.map(c => {
                return <option key={"cat" + c.id} value={c.id}>{c.description}</option>
            }) : <></>}
        </FormControl>
    }

    setRol(Rol: React.ChangeEvent<HTMLSelectElement>) {
        let value = Rol.currentTarget.value as string
        let r = parseInt(value)
        this.props.change(r)
    }
}