import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Storage } from '../../Storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import Productos, { conProductos } from '../../Model/Productos'
import { numberFormat } from '../../Utils/Formats'
import ReactTooltip from 'react-tooltip'
import FormProductos from './FormProductos'

class FilaProducto extends React.Component<{ producto: Productos, update?: () => void }, { open: boolean }> {
    con = conProductos()
    constructor(p: any) {
        super(p);
        this.state = { open: false };
    }
    render() {
        let producto = this.props.producto
        let money = localStorage.getItem("money");
        return <tr>
            <th>{producto.code}</th>
            <th>{producto.description}</th>
            <th style={{ textAlign: 'right' }}>{money} {numberFormat(producto.cost)}</th>
            <th style={{ textAlign: 'right' }}>{producto.quantity}</th>
            <th style={{ width: "100px" }}>{this.getStatus()}</th>
            <th>
                {this.state.open ? <FormProductos add={() => { }} Producto={this.props.producto} close={() => { this.setState({ open: false }) }} title={"editar " + this.props.producto.description} /> : <></>}
                <ReactTooltip />
                <ButtonGroup>
                    <Button data-tip="Editar Producto" onClick={() => { this.setState({ open: true }) }} variant="info">
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    {this.getBotton()}

                </ButtonGroup>
            </th>
        </tr >
    }
    getStatus() {
        switch (this.props.producto.status) {
            case 0:
                return <span className="bg-danger text-white p-1 d-inline-block">ELIMINDADO</span>
            case 1:
                return <span className="bg-success text-white p-1 d-inline-block">ACTIVADO</span>
        }
    }
    activar() {
        this.con.stateChange({ id: this.props.producto.id, status: 1 }, this.props.update)
    }
    delete() {
        this.con.stateChange({ id: this.props.producto.id, status: 0 }, this.props.update)
    }
    getBotton() {
        if (this.props.producto.status === 1)
            return <Button data-tip="INHABILITAR" onClick={this.delete.bind(this)} variant="danger"><FontAwesomeIcon icon={faTimes} /></Button>
        else
            return <Button data-tip="HABILITAR" onClick={this.activar.bind(this)} variant="success"><FontAwesomeIcon icon={faCheck} /></Button>
    }

}
FilaProducto.contextType = Storage
export default FilaProducto