import { Activas } from "../Conexiones/Activas"
import { taxpayer_type } from "./Contribuyente"
import { fechas } from "./Generales"

export class cliente extends Activas {
    tabla = 'tablas'
    coleccion = 'clients'
    lista: string = 'clientes'
}
var concli: cliente
export function conCliente() {
    if (concli === undefined)
        concli = new cliente()
    return concli
}
interface proto extends fechas {
    id: number,
    code: string,
    name: string,
    email: string,
    address: string,
    contact_person: string,
    phone: string,
    taxpayer_type_id: number,
    status: number,
}
export default interface Cliente extends proto {
    referrer_id?: number
    taxpayer_type: taxpayer_type
}
export interface ClienteInput extends proto { }

export var ClienteNull: ClienteInput = {
    id: 0,
    code: "",
    name: "",
    email: "",
    address: "",
    contact_person: "",
    phone: "",
    taxpayer_type_id: 0,
    status: 1,
}

export var ClienteDefault: Cliente = {
    ...ClienteNull,
    taxpayer_type: {
        id: 0,
        description: "",
        tax: 0,
        status: 0
    },
    created_at: "",
    updated_at: "",
    deleted_at: ""
}