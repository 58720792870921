import Axios from 'axios'
import React from 'react'
import User, { userPrueba } from './Model/User'
import { auth } from './database/Auth'
import { don } from './Conexiones/Minimo'

interface State {
    actualizar: number
    User: User
    title: string
    Permisos: Array<any>
}

export const Storage = React.createContext<{
    actializar: () => void,
    data: State,
    set: Function
} | null>(null)

export class Provider extends React.Component<any, State> {
    auth = auth()
    constructor(props: any) {
        super(props)
        this.state = {
            title: "inicio",
            User: userPrueba,
            Permisos: [],
            actualizar: 0
        }
        this.auth.setProvider(this)
        this.setState = this.setState.bind(this)
    }

    render() {
        let token = localStorage.getItem("Autorization")
        Axios.defaults.headers.common['Authorization'] = token
        Axios.defaults.headers.common['Content-Type'] = "application/json"
        return <Storage.Provider value={{ data: this.state, set: this.setState, actializar: this.actializar.bind(this) }}>
            {this.props.children}
        </Storage.Provider>
    }

    actializar() {
        let token = localStorage.getItem("Autorization")
        Axios.defaults.headers.common['Authorization'] = token
        Axios.defaults.headers.common['Content-Type'] = "application/json"
        if (this.state.User.id === 0) {
            this.auth.Login(this.setState)
            this.auth.LoadPermisos(this.setState)
            this.setState({ actualizar: this.state.actualizar + 1 })
        }
    }
    componentDidMount() {
        this.money();
        this.auth.Login(this.setState)
        this.auth.LoadPermisos(this.setState)
    }

    async money() {
        try {
            let rest = await Axios.get(don + "/api/getCompany");
            localStorage.setItem("money", rest.data.money)
        } catch (e) {

        }

    }

}
export const Consumer = Storage.Consumer
