import { General } from "../Conexiones/General"
import { fechas } from "./Generales"

export class referidos extends General {
    tabla = 'tablas'
    coleccion = 'referrals'
    lista: string = 'Referidos'
}
var conRef: referidos
export function conReferidos() {
    if (conRef === undefined)
        conRef = new referidos()
    return conRef
}
export default interface Referido extends fechas {
    id: number
    phone: string
    client?: string | null
    note: string
    name: string
    status: number
    user_id: number
    client_id?: number
}
export interface inputReferido {
    phone: string
    name: string
    note: string
    status: number
    client_id?: number
}

export var ReferidoNull: inputReferido = {
    phone: "",
    name: "",
    note: "",
    status: 1
}