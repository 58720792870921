import { faCheck, faSave, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, ButtonGroup, FormControl } from "react-bootstrap"
import ReactTooltip from "react-tooltip"
import { conContribuyente, taxpayer_type } from "../../../Model/Contribuyente"
interface Props {
    taxpayer_type: taxpayer_type
    update?: () => void
}
export class FilaContribuyente extends React.Component<Props, taxpayer_type> {
    con = conContribuyente()
    constructor(p: Props) {
        super(p)
        this.state = p.taxpayer_type
    }
    render() {
        let { tax, description } = this.props.taxpayer_type
        return <tr>
            <th>
                <FormControl defaultValue={description} onChange={this.setChangeDesc.bind(this)} />
            </th>
            <th>
                <ReactTooltip />
                <FormControl defaultValue={tax} onChange={this.setChangeTax.bind(this)} type="number" style={{ maxWidth: 150 }} />
            </th>
            <th>
                {this.getStatus()}
            </th>
            <th>
                <ButtonGroup>
                    <Button data-tip="editar" onClick={this.guardar.bind(this)}>
                        <FontAwesomeIcon icon={faSave} />
                    </Button>
                    {this.getBotton()}
                </ButtonGroup>
            </th>
        </tr>
    }
    getStatus() {
        switch (this.props.taxpayer_type.status) {
            case 0:
                return <span className="bg-danger text-white p-1 d-inline-block">inactivo</span>
            case 1:
                return <span className="bg-success text-white p-1 d-inline-block">ACTIVADO</span>
        }
    }
    getBotton() {
        if (this.state.status === 1)
            return <Button data-tip="INHABILITAR" onClick={this.delete.bind(this)} variant="danger"><FontAwesomeIcon icon={faTimes} /></Button>
        else
            return <Button data-tip="HABILITAR" onClick={this.activar.bind(this)} variant="success"><FontAwesomeIcon icon={faCheck} /></Button>
    }
    delete() {
        let { id } = this.props.taxpayer_type
        this.con.stateChange({ id, status: 0 }, this.props.update)
    }
    activar() {
        let { id } = this.props.taxpayer_type
        this.con.stateChange({ id, status: 1 }, this.props.update)
    }
    setChangeTax(e: React.ChangeEvent<HTMLSelectElement>) {
        let { value } = e.currentTarget
        let r = parseInt(value)
        e.currentTarget.value = r + ""
        this.setState({ tax: r })
    }
    setChangeDesc(e: React.ChangeEvent<HTMLSelectElement>) {
        let { value } = e.currentTarget
        let r = value.toUpperCase()
        e.currentTarget.value = r
        this.setState({ description: r })
    }
    guardar() {
        let { id, tax, description } = this.state
        this.con.update({ id, tax, description, status: 1 })
    }

}
