import { ResponsiveLine, Datum } from "@nivo/line";
import React from "react";
import { Row, Spinner } from "react-bootstrap";

interface Props {
    data: Datum[]
    title: string
}

export default class Linea extends React.Component<Props> {

    render() {
        let { data, title } = this.props;
        if (data.length === 0) return <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
            <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
            <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                Cargando....
            </h4>
        </Row>;
        let scala: "DIAS DEL MES" | "MESES DEL AÑO";
        if (data.length < 20)
            scala = "MESES DEL AÑO";
        else
            scala = "DIAS DEL MES";
        return <ResponsiveLine
            data={[{
                id: title,
                color: "#007bff",
                data
            }]}
            lineWidth={3}
            colors={["#28A745"]}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: scala,
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'MONTO',
                legendOffset: -50,
                legendPosition: "middle"
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    }
}