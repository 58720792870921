import React from "react";
import { Card, Col } from "react-bootstrap";
import { Permisos } from "../../../config/Permisos";
import { Cotizaciones } from "./Cotizaciones";
import { Servicios } from "./Servicios";
import moment from 'moment';
import SetMes from "../SelectorDeMes";
interface State {
    mont: number
    year: number
}
export default class Graficas extends React.Component<any, State> {
    per = Permisos()
    constructor(p: any) {
        super(p);
        this.state = { year: parseInt(moment().format("YYYY")), mont: parseInt(moment().format("MM")) };
    }
    render() {
        let { year, mont } = this.state;
        return <Card>
            <Card.Header>
                <SetMes mont={mont} year={year} setData={this.setState.bind(this)} />
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    {this.Cotizacion(mont, year)}
                </Col>
                <Col md={12}>
                    {this.Servicio(mont, year)}
                </Col>
            </Card.Body>
        </Card >
    }
    Servicio(mont: number, year: number) {
        if (this.per.authFuncion("servicio"))
            return <Servicios mont={mont} year={year} />
        return <></>
    }
    Cotizacion(mont: number, year: number) {
        if (this.per.authFuncion("cotizacion"))
            return <Cotizaciones mont={mont} year={year} />
        return <></>
    }
}