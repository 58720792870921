import React from "react"
import { Card, InputGroup, Row, Spinner } from "react-bootstrap"
import Servicio, { Tecnico } from "../../../Model/Sevicios"
import User from "../../../Model/User"
import { conTecnico } from "../../../Model/Tecnico"
import RowTecnico from "./RowTecnico"
import moment from 'moment'
import { noRepit, removeElement } from "../../../Utils/ArrayFun"
import SetText from "../../../Utils/setTex"
import { toast } from "react-toastify"
moment.locale('es')

interface s extends Servicio {
    tecnicos: number
    conflicto: boolean
}
interface Props {
    servicio: s
    setData: (d: any) => void
}
interface State {
    Users?: Array<Tecnico>
}
export default class SelectorTecnico extends React.Component<Props, State> {
    syncTecnicos = conTecnico()
    auxTec: Array<Tecnico> = []
    constructor(p: Props) {
        super(p)
        this.state = {
            Users: undefined
        }
        this.setState = this.setState.bind(this)
    }
    render() {
        let height = window.innerHeight - 300
        let { state: { Users }, props: { servicio: { users, tecnicos }, setData } } = this
        if (Users === undefined || Users === null)
            return <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Cargando....
                </h4>
            </Row>
        return <>
            <Card>
                <Card.Header>
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Tecnicos Seleccionados
                    </h6>
                </Card.Header>
                <Card.Body style={{ overflowY: "scroll", height: height * 0.35 - 50 }}>
                    {Users.filter(t => {
                        let pasa = false
                        users.forEach(s => {
                            if (s.id === t.id)
                                pasa = true
                        })
                        return pasa
                    }).map(t => {
                        return <RowTecnico key={"tecSelect" + t.id} tenico={t} add={this.removeTecnico.bind(this)} />
                    })}
                </Card.Body>
            </Card>
            <Card><InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text className="m-0 font-weight-bold text-primary mr-auto align-self-center"> Seleccionados</InputGroup.Text>
                    <InputGroup.Text>{users.length}</InputGroup.Text>
                    <InputGroup.Text className="m-0 font-weight-bold text-primary mr-auto align-self-center">de</InputGroup.Text>
                </InputGroup.Prepend>
                <SetText key={"tec" + tecnicos} id="tecnicos" step={1} type="number" setData={setData} value={tecnicos} minValue={tecnicos} update="tecnicos" />
            </InputGroup></Card>
            <Card>
                <Card.Header>
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Lista de Tecnicos
                    </h6>
                </Card.Header>
                <Card.Body style={{ overflowY: "scroll", height: height * 0.65 - 100 }}>
                    {Users.filter(t => {
                        let pasa = true
                        users.forEach(s => {
                            if (s.id === t.id)
                                pasa = false
                        })
                        return pasa
                    }).map(t => {
                        return <RowTecnico key={"tec" + t.id} tenico={t} add={this.agregarTecnico.bind(this)} />
                    })}
                </Card.Body>
            </Card>
        </>
    }
    componentDidMount() {
        let { setState } = this
        this.syncTecnicos.setListaTecnicos(setState)
    }
    componentWillReceiveProps(p: Props) {
        if (this.props.servicio.tecnicos !== p.servicio.tecnicos)
            p.setData({ users: [] })
        if (this.auxTec.length !== 0) {
            this.auxTec.forEach(t => {
                this.removeTecnico(t)
                this.agregarTecnico(t)
            })
            this.auxTec = []
        }
        if (this.props.servicio.id !== p.servicio.id) {
            this.auxTec = p.servicio.users
        }
        this.actualizarUsers(p)
    }
    async agregarTecnico(t: Tecnico) {
        let { servicio: { users, id, tecnicos }, setData } = this.props
        if (tecnicos !== undefined)
            if (tecnicos > users.length) {
                t.citas = await this.syncTecnicos.signaciones({ user_id: t.id, service_id: id })
                setData({ users: [...users, t] })
            }else{
                toast('selecciono el numero limite de tecnicos', { type: toast.TYPE.ERROR })
            }
    }
    removeTecnico(tecnico: User) {
        var users: Array<User> = removeElement({ a: this.props.servicio.users, b: tecnico, label: "id" })
        this.props.setData({ users })
    }
    actualizarUsers(p: Props) {
        let conflicto = false
        let { dates, users } = p.servicio
        let { Users } = this.state
        let aux: Array<Array<{ user_id: number }>> = dates.map(t => { if (t.Tecnicos !== undefined) return t.Tecnicos; return [] })
        let tecnicos: Array<{ user_id: number }> = noRepit({ a: aux, label: "user_id" })
        if (Users !== undefined)
            Users = Users.map(t => {
                let alter = true
                tecnicos.forEach(l => {
                    if (l.user_id === t.id) {
                        alter = false
                        t.state = 2
                        users.forEach(k => {
                            if (t.id === k.id) {
                                t.state = 3
                                conflicto = true
                            }
                        })
                    }
                })
                if (alter)
                    t.state = 1
                return t
            })
        if (conflicto !== this.props.servicio.conflicto) {
            p.setData({ conflicto: conflicto })
        }
        this.setState({ Users })
    }
}