import { faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import { ButtonGroup, Card, Col, FormControl, InputGroup, Row, Button, Spinner, Container } from "react-bootstrap"
import { Permisos } from "../../config/Permisos";
import Cliente, { ClienteDefault } from "../../Model/Clientes";
import { Tecnico } from "../../Model/Sevicios";
import { conUsers } from "../../Model/User";
import ModalPrint from "../../Utils/ModalPrint";
import BuscarCliente from "../Clientes/BuscarCliente";
import Fechas from "./Impresos/Fechas";
import { SelectorUser } from "./SelectorUser";

interface State {
    modulo: string
    metodo: string
    filtro: string
    status: string
    open: boolean
    clientes: boolean
    tech: number
    cliente: Cliente
    Users?: Tecnico[]
}
export class ReferidosReport extends React.Component<any, State> {
    con = conUsers();
    constructor(p: any) {
        super(p);
        this.state = {
            modulo: "",
            metodo: "list",
            filtro: "a",
            status: "10",
            open: false,
            tech: 0,
            clientes: false,
            cliente: ClienteDefault,
            Users: undefined
        }
    }

    componentWillMount() {
        var yo = this;
        this.con.setCargar((Users: Array<any>) => {
            yo.setState({ Users })
        });
    }
    render() {
        let { authFuncion } = Permisos()
        if (!authFuncion("reporte_postventa"))
            return <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        No esta autorizado para entrar en esta seccion
                </h1>
                </Card.Header>
            </Card>
        let { open, modulo } = this.state;
        return <Card>
            {open ? <ModalPrint title="IMPRIMIR" url={"pdf/" + this.getUrl()} close={() => this.setState({ open: false })} /> : <></>}
            <Card.Header>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Reportes Generales
                </h6>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col md="2">
                        <ButtonGroup vertical>
                            <Button variant={"" === modulo ? "primary" : "outline-dark"}
                                onClick={() => { this.setState({ modulo: "" }) }}>
                                TODOS
                            </Button>
                            <Button variant={"client/" === modulo ? "primary" : "outline-dark"}
                                onClick={() => { this.setState({ modulo: "client/", filtro: "a", tech: 0, cliente: ClienteDefault }) }}>
                                CLIENTES
                            </Button>
                            <Button variant={("user/" === modulo) ? "primary" : "outline-dark"}
                                onClick={() => { this.setState({ modulo: "user/", filtro: "a", tech: 0 }) }}>
                                USUARIOS
                            </Button>
                        </ButtonGroup>
                    </Col>
                    <Col md="10">
                        <Row>
                            {this.getFiltro()}
                        </Row>
                        <Fechas metodo={this.setMetodo.bind(this)} />
                        <Button className="float-right"
                            onClick={() => this.setState({ open: true })}
                            variant="primary">
                            <FontAwesomeIcon icon={faPrint} />
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    }
    getStatus() {
        return <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text>ESTADO</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl onChange={this.setStatus.bind(this)} as="select" defaultValue="a">
                <option value="a">TODOS LOS ESTADOS</option>
                <option value="1">ELIMINADO</option>
                <option value="1">ESPERA</option>
                <option value="2">CONTACTADO</option>
                <option value="3">REGISTRADO</option>
            </FormControl>
        </InputGroup>;
    }
    getFiltro() {
        let { Users, tech, modulo, cliente, clientes } = this.state;
        if ("user/" === modulo) {
            if (Users === undefined)
                return <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                    <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                    <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Cargando....
                </h4>
                </Row>
            return <SelectorUser Users={Users} tech={tech} setData={this.setState.bind(this)} >
                {this.getStatus()}
            </SelectorUser>
        } else if (modulo === "client/") {
            return <>
                {clientes ? <BuscarCliente open={true} add={(c) => { this.setState({ cliente: c, tech: c.id }) }} close={() => this.setState({ clientes: false })} /> : <></>}
                <Col xs="6">
                    <Card>
                        <Card.Header className="d-flex">
                            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">datos del cliente</h6>
                            <Button className="float-right" size="sm"
                                onClick={() => this.setState({ clientes: true })}
                                variant="primary">
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </Card.Header>
                        <Container className="mh-3">
                            <b>Nombre: </b>
                            <br />{cliente.name}<br />
                            <b>PERSONA DE CONTACTO: </b>
                            <br />{cliente.contact_person}<br />
                            <b>CORREO ELECTRONICO: </b>
                            <br />{cliente.email}<br />
                            <b>TELEFONO: </b>{cliente.phone}<br />
                        </Container>
                    </Card>
                </Col>
                <Col xs="6">
                    {this.getStatus()}
                </Col>
            </>
        } else {
            return this.getStatus()
        }
    }
    setStatus(Rol: React.ChangeEvent<HTMLSelectElement>) {
        let status = Rol.currentTarget.value as string
        this.setState({ status });
    }
    setMetodo(metodo: string) {
        this.setState({ metodo })
    }
    getUrl() {
        let { modulo, status, metodo, tech } = this.state;
        if (modulo === '' || tech === 0)
            return "referrals/" + modulo + metodo + '/' + status;
        return "referrals/" + modulo + metodo + '/' + status + '/' + tech;
    }
}