
import React, { useState } from 'react'
import { FormControl, ButtonGroup, Button, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { numberFormat } from './Formats'
import InputText from './InputText'
import { Producto } from '../Model/Generales'

interface Props {
    producto: Producto,
    edit: (iten: Producto) => void,
    remove: (iten: Producto) => void
}
export default function FilaUtileServicios({ producto, edit, remove }: Props) {
    const [open, setOpen] = useState(false);
    const { price, techs, quantity, quantity_turns, comment, product: { code, description, price_1, price_2, price_3, cost, id } } = producto;
    let min = cost + (cost * price_1 / 100)
    let money = (localStorage.getItem("money") === null) ? undefined : localStorage.getItem("money");
    const setPriceChance = (Rol: React.ChangeEvent<HTMLSelectElement>) => {
        const value = Rol.currentTarget.value as string
        const price = (value === "") ? 0 : parseFloat(value)
        edit({ ...producto, price })
    }
    const minValue = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value as string
        const r = (value === "") ? 0 : parseFloat(value);
        const price = (r > min) ? r : min
        edit({ ...producto, price });
        e.currentTarget.value = "" + price;
    }
    return <>
        <tr>
            <th>{code}</th>
            <th>{description}</th>
            <th><InputText type="number" setData={({ quantity }) => edit({ ...producto, quantity })} value={quantity} update="quantity" /></th>
            <th><InputText type="number" setData={({ techs }) => edit({ ...producto, techs })} value={techs} update="techs" /></th>
            <th><InputText type="number" setData={({ quantity_turns }) => edit({ ...producto, quantity_turns })} value={quantity_turns} update="quantity_turns" /></th>
            <th>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {money}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <datalist id={"edit" + id}>
                        <option value={cost + (cost * price_1 / 100)}>{cost + (cost * price_1 / 100)}</option>
                        <option value={cost + (cost * price_2 / 100)}>{cost + (cost * price_2 / 100)}</option>
                        <option value={cost + (cost * price_3 / 100)}>{cost + (cost * price_3 / 100)}</option>
                    </datalist>
                    <FormControl onBlur={minValue} min={min} onChange={setPriceChance}
                        list={"edit" + id} type="number" step="0.01" defaultValue={price} />
                </InputGroup>
            </th>
            <th>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {money}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl value={numberFormat(price * quantity)} disabled />
                </InputGroup>
            </th>
            <th>
                <ButtonGroup>
                    <Button data-tip="Dejar Nota" onClick={() => setOpen(!open)} variant="outline-dark">
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button data-tip="Quitar Producto" onClick={() => remove(producto)} variant="outline-dark">
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </ButtonGroup>
            </th>
        </tr>
        <tr></tr>
        <tr className={"collapse" + ((open) ? " show" : "")}>
            <th colSpan={8}>
                <InputText label="Nota Interna" setData={({ comment }) => edit({ ...producto, comment })} value={comment} update="comment" />
            </th>
        </tr>
    </>
}