import React from 'react'
import { Spinner, Button, Col, FormControl, Image, InputGroup, Modal, Row } from 'react-bootstrap'
import moment from 'moment'
import "moment/locale/es"
import { Storage } from '../../Storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import User, { userPrueba } from '../../Model/User'
import { zfill } from '../../Utils/Formats'
import Mensaje_chat from '../../Model/Chat'
import { conChat } from '../../Model/Chat'
import { ifArray } from '../../Utils/ArrayFun'
import { users } from '../../database/Users'
import { auth } from '../../database/Auth'
moment.locale('es')
interface Props {
    open: boolean,
    close: () => void,
    id: number
}
interface PropsMensaje {
    msn: Mensaje_chat,
    user: User
}
interface State {
    msn: string
    mensajes?: Array<Mensaje_chat>
    tec: User[]
}
class Mensaje extends React.Component<PropsMensaje>{
    render() {
        let { msn: { msn, created_at }, user: { id, image } } = this.props
        if (id === this.context.data.User.id)
            return <div className="outgoing_msg">
                <div className="sent_msg">
                    <p>{msn}</p>
                    <span className="time_date">{moment(created_at).format("DD-MM-Y hh:mm A")}</span>
                </div>
            </div>
        return <div className="incoming_msg">
            <div className="incoming_msg_img">
                <img src={image} alt="sunil" />
            </div>
            <div className="received_msg">
                <div className="received_withd_msg">
                    <p>{msn}</p>
                    <span className="time_date"> {moment(created_at).format("DD-MM-Y hh:mm A")}</span>
                </div>
            </div>
        </div>
    }
}
Mensaje.contextType = Storage
class Chat extends React.Component<Props, State> {
    msn: any = undefined
    con = conChat(0)
    ref = React.createRef<HTMLDivElement>()
    constructor(p: Props) {
        super(p)
        this.state = { msn: "", mensajes: undefined, tec: [] }
        this.con = conChat(p.id)
        this.setState = this.setState.bind(this)
    }
    render() {
        return <Modal size="xl" centered animation show={this.props.open} onHide={this.props.close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>CHAT DEL SERVICIO N°:</b>{zfill(this.props.id)}</span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <div style={{ height: "570px", overflowY: "auto" }} className="user_history">
                        {this.state.tec.length === 0 ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                            <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                            <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                                Cargando....
                            </h4>
                        </Row> : this.state.tec.map(t => {
                            return <Row className="m-1 user_cation">
                                <div style={{ width: "60px" }}>
                                    <Image fluid src={t.image} />
                                </div>
                                <Col>
                                    {t.name}<br />
                                    {t.email}
                                </Col>
                            </Row>
                        })}
                    </div>
                    <div ref={this.ref} style={{ height: "570px", overflowY: "auto" }} className="msg_history">
                        {this.state.mensajes === undefined ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                            <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                            <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                                Cargando....
                        </h4>
                        </Row> : this.state.mensajes.map(m => {
                            return <Mensaje key={"msg" + m.id} msn={m} user={this.getUser(m.user_id)} />
                        })}
                    </div>
                </Row>
                <Row style={{ height: "30" }}>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Mensaje</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl onKeyUp={this.setMsn.bind(this)} defaultValue={this.state.msn} />
                        <InputGroup.Append>
                            <Button onClick={() => { this.con.enviar(this.state.msn); this.clear() }}>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Row>
            </Modal.Body>
        </Modal>
    }
    setMsn(e: React.KeyboardEvent<HTMLInputElement>) {
        let { value } = e.currentTarget
        this.setState({ msn: value })
        if (this.msn === undefined)
            this.msn = e.currentTarget
        if (e.keyCode === 13) {
            this.enviar()
        }
    } 
    enviar() {
        this.con.enviar(this.state.msn)
        let m: Array<Mensaje_chat> = (this.state.mensajes === undefined) ? [] : this.state.mensajes
        this.setState({
            mensajes: [...m, {
                id: 0,
                deleted_at: "",
                msn: this.state.msn,
                created_at: moment().format("Y-MM-DD HH:mm"),
                user_id: auth().id,
                service_id: this.props.id,
                read: 0,
            }]
        })
        this.clear()
    }
    componentWillMount() {
        if (this.props.open === true) {
            this.con.actualizar = this.setState.bind(this);
            this.con.setUsers();
            this.con.updateLista()
        }
    }
    setMensajes(data: { mensajes: Mensaje_chat[] }) {
        this.setState({ mensajes: data.mensajes });
        this.forceUpdate();
    }
    componentDidUpdate() {
        if (this.ref.current !== null) {
            let tbody = this.ref.current
            setTimeout(() => {
                tbody.scrollTop = tbody.scrollHeight
            }, 200)
        }
    }
    add(tec: User) {
        let m: Array<User> = (this.state.tec === undefined) ? [] : this.state.tec
        if (tec.id !== 0) {
            if (!ifArray({ a: m, b: tec, label: "id" })) {
                this.setState({ tec: [...m, tec] })
            }
        } else {
            if (this.state.tec === undefined)
                this.con.setUsers();
        }
    }
    getUser(id: number) {
        let tec = userPrueba
        if (this.state.tec !== undefined)
            this.state.tec.forEach(t => {
                if (t.id === id)
                    tec = t
            })
        if (tec.id === 0)
            tec = users().getUser(id, this.add.bind(this))
        return tec
    }
    clear() {
        if (this.msn !== undefined)
            this.msn.value = ''
    }
    componentWillUnmount() {
        this.con.escuchar = false;
    }
    componentWillReceiveProps(p: Props) {
        this.con.actualizar = this.setState.bind(this);
        this.con.updateLista()
        this.con.escuchar = p.open;
    }

}
Chat.contextType = Storage
export default Chat