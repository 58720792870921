import React from 'react'
import { Storage } from '../../Storage'
import { Col, Row } from 'react-bootstrap'
import FilaServicio from './FilaServicio'
import Servicio from '../../Model/Sevicios'
import { conTecnico } from "../../Model/Tecnico"
import { PermisosType } from '../../config/Permisos'
import Tabla from '../../Utils/Table'
class MisServicios extends Tabla {
    bucarLabel = ["id", "name"];
    permiso: PermisosType = "tecnico";
    con = conTecnico();
    categotia = 0;
    componentWillMount() {
        this.context.set({ title: "LISTA DE SERVICIOS" });
        this.con.misServicios(this.setItens.bind(this));
        this.con.updateMisServicios(this.setItens.bind(this));
    }

    Row(c: Servicio) {
        return <FilaServicio key={'ser' + c.id + c.status} select={this.setState} cotizacion={c} />
    }
    Header() {
        return <tr>
            <th onClick={() => { this.setOrden("id") }}>NUMERO{this.flechas("id")}</th>
            <th>FECHA</th>
            <th>ESTATUS</th>
            <th>ACCIONES</th>
        </tr>
    }
    busquedaMostrar() {
        return <Row>
            <Col as="h6" md="6" className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                {this.Buscador("BUSCAR SERVICIO")}
            </Col>
        </Row>
    }
    close() {
        this.setState({ open: false })
    }
}
MisServicios.contextType = Storage
export default MisServicios