import React from "react"
import moment from 'moment';
import { Card, FormControl, InputGroup } from "react-bootstrap"
interface State {
    date1: string
    date2: string
    aux: string
}
export default class Fechas extends React.Component<{ metodo: (m: string) => void }, State> {
    mes = React.createRef<HTMLSelectElement>();
    year1 = React.createRef<HTMLSelectElement>();
    year2 = React.createRef<HTMLSelectElement>();
    del = React.createRef<HTMLInputElement>();
    al = React.createRef<HTMLInputElement>();
    constructor(p: any) {
        super(p);
        this.state = { aux: "month", date2: moment().format("MM"), date1: moment().format("YYYY") }
        p.metodo("month/" + moment().format("YYYY") + "/" + moment().format("MM"));
    }
    componentDidUpdate(p: any, s: State) {
        let { date1, date2, aux } = this.state;
        if (date1 !== s.date1 || date2 !== s.date2 || aux !== s.aux) {
            let url = "";
            if (s.aux !== "range") { 
                if (aux === "range") {
                    date2 = this.al.current ? moment(this.al.current.value).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY");
                    date1 = this.del.current ? moment(this.del.current.value).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY");
                    this.setState({ date1, date2 })
                }
            }
            if (s.aux !== "month") {
                if (aux === "month") {
                    date2 = this.mes.current ? this.mes.current.value : moment().format("MM");
                    date1 = this.year1.current ? this.year1.current.value : moment().format("YYYY");
                    this.setState({ date1, date2 })
                }
            }
            url = aux + "/" + date1 + "/" + date2
            if (aux === "year") {
                if (s.aux !== "year") {
                    date1 = this.year2.current ? this.year2.current.value : moment().format("YYYY");
                    this.setState({ date1 })
                }
                url = "year/" + date1;
            }
            this.props.metodo(url);
        }
    }
    render() {
        let { aux } = this.state;
        return <Card>
            <Card.Header>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Selecciones rango de fechas
                </h6>
            </Card.Header>
            <Card.Body>
                <div>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Checkbox aria-label="Checkbox for following text input"
                                checked={aux === "range"}
                                onClick={() => { this.setState({ aux: "range" }) }} />
                            <InputGroup.Text>en el rango de</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl ref={this.del} onChange={this.setDel.bind(this)} disabled={aux !== "range"}
                            type="date" defaultValue={moment().format("YYYY-MM-DD")} />
                        <InputGroup.Append>
                            <InputGroup.Text>al</InputGroup.Text>
                            <div />
                        </InputGroup.Append>
                        <FormControl ref={this.al} onChange={this.setAl.bind(this)} disabled={aux !== "range"}
                            type="date" defaultValue={moment().format("YYYY-MM-DD")} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Checkbox aria-label="Checkbox for following text input"
                                checked={aux === "month"}
                                onClick={() => { this.setState({ aux: "month" }) }} />
                            <InputGroup.Text>Mes</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl ref={this.mes} onChange={this.setChangeMont.bind(this)} disabled={aux !== "month"}
                            as="select" defaultValue={parseInt(moment().format("MM"))}>
                            <option value={1}>ENERO </option>
                            <option value={2}>FEBRERO </option>
                            <option value={3}>MARZO </option>
                            <option value={4}>ABRIL </option>
                            <option value={5}>MAYO </option>
                            <option value={6}>JUNIO </option>
                            <option value={7}>JULIO </option>
                            <option value={8}>AGOSTO </option>
                            <option value={9}>SEPTIEMBRE </option>
                            <option value={10}>OCTUBRE </option>
                            <option value={11}>NOVIEMBRE </option>
                            <option value={12}>DICIEMBRE </option>
                        </FormControl>
                        <InputGroup.Append>
                            <InputGroup.Text>del</InputGroup.Text>
                            <div />
                        </InputGroup.Append>
                        <FormControl ref={this.year1} onChange={this.setChangeYear.bind(this)} disabled={aux !== "month"}
                            as="select" defaultValue={parseInt(moment().format("YYYY"))}>
                            {this.getYear()}
                        </FormControl>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Checkbox aria-label="Checkbox for following text input"
                                checked={aux === "year"}
                                onClick={() => { this.setState({ aux: "year" }) }} />
                            <InputGroup.Text>año</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl ref={this.year2} onChange={this.setChangeYear.bind(this)} disabled={aux !== "year"}
                            as="select" defaultValue={parseInt(moment().format("YYYY"))}>
                            {this.getYear()}
                        </FormControl>
                    </InputGroup>
                </div>
            </Card.Body>
        </Card>
    }
    setChangeYear(e: React.ChangeEvent<HTMLInputElement>) {
        let date1 = e.currentTarget.value
        this.setState({ date1 })
    }
    setChangeMont(e: React.ChangeEvent<HTMLInputElement>) {
        let date2 = e.currentTarget.value
        this.setState({ date2 })
    }
    setAl(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ date2: moment(e.currentTarget.value).format("DD-MM-YYYY") });
    }
    setDel(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ date1: moment(e.currentTarget.value).format("DD-MM-YYYY") });
    }
    getYear() {
        let yearActual = parseInt(moment().format("YYYY"));
        let years: any[] = [];
        for (let i = 2020; i < yearActual; i++) {
            years.push(<option value={i}>{i}</option>);
        }
        years.push(<option value={yearActual}>{yearActual}</option>);
        return years;
    }
}