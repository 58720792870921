import React from "react"
import { Row, Spinner, } from "react-bootstrap"
import Servicio, { Cita, conServicios, Ocupado, } from "../../../Model/Sevicios"
import { conTecnico } from "../../../Model/Tecnico"
import { Calendario, celda } from "../../../Utils/Calendario"
import moment from 'moment'
import { toast } from "react-toastify"
import { ifArray, noRepit, removeElement } from "../../../Utils/ArrayFun"
moment.locale('es')
interface s extends Servicio {
    sugeridos: number
}
interface Props {
    servicio: s,
    setData: (data: any) => void,
}
interface State {
    Ocupados?: Array<Ocupado>
}
export default class SelectorTurnos extends React.Component<Props, State> {
    Dates = conServicios()
    Tecnicos = conTecnico()
    constructor(p: Props) {
        super(p)
        this.state = {
            Ocupados: undefined
        }
        this.setState = this.setState.bind(this)
    }
    render() {
        let { props: { servicio, setData }, state: { Ocupados } } = this
        if (Ocupados === undefined || Ocupados === null)
            return <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Cargando....
                </h4>
            </Row>
        return <Calendario key="calendario" servicio={servicio} setData={setData} celdas={this.getCeldas()} selectDate={this.selecTurno.bind(this)} />
    }
    componentDidMount() {
        let { setState } = this
        this.Dates.liDates(setState)
        this.Dates.upDates(setState)
    }
    getColor(state: number) {
        switch (state) {
            case 1:
                return "#007bff"
            case 2:
                return "#ffc107"
            case 3:
                return "#dc3545"
            case 4:
                return "#28a745"
            default:
                return ""
        }
    }
    getCeldas(): Array<celda> {
        let { state: { Ocupados }, props: { servicio: { dates } } } = this
        let citas: Array<celda> = []
        if (Ocupados !== undefined) {
            let aux: Array<Ocupado> = noRepit({ a: [Ocupados, dates], isCompare: this.buscarTurno })
            citas = aux.map((o: Ocupado) => {
                return {
                    ...o,
                    color: this.getColor(o.state)
                }
            })
        }
        return citas
    }
    paso(date: Ocupado) {
        let actual = parseInt(moment().format("YYYYMMDD"))
        let fecha = parseInt(moment(date.date).format("YYYYMMDD"))
        let hora = parseInt(moment().format("HHmm"))
        if (fecha < actual) return false
        else if (fecha === actual && hora > 1100 && date.turn === 1) return false
        return true
    }
    selecTurno(e: Ocupado) {
        let aux = { a: this.props.servicio.dates, b: e, isCompare: this.buscarTurno }
        if (ifArray(aux)) {
            this.removeTurno(e)
        } else {
            if (this.paso(e)) {
                let { servicio: { dates, sugeridos } } = this.props
                if (sugeridos > dates.length) {
                    this.addTurno(e)
                } else {
                    toast('No puede seleccionar mas turnos de los requeridos', { type: toast.TYPE.ERROR })
                }
            } else {
                toast('No puede seleccionar mas turnos que ya ha pasado', { type: toast.TYPE.ERROR })
            }
        }
    }
    async addTurno(e: Ocupado) {
        let { servicio: { dates, id }, setData } = this.props
        let t: any = await this.Tecnicos.ocupados({ date: moment(e.date).format("YYYY-MM-DD"), turn: e.turn, service_id: id })
        if (t !== undefined) {
            this.removeTurno(e)
            e.Tecnicos = t
            setData({ dates: [...dates, e] })
        }
    }
    removeTurno(e: Ocupado) {
        var dates: Array<Ocupado> = removeElement({ a: this.props.servicio.dates, b: e, isCompare: this.buscarTurno })
        this.props.setData({ dates })
    }
    buscarTurno(a: Ocupado, b: Ocupado) {
        let date = parseInt(moment(a.date).format("YYYYMMDD"))
        let evento = parseInt(moment(b.date).format("YYYYMMDD"))
        if (evento === date)
            if (a.turn === b.turn)
                return true
        return false
    }
    componentWillReceiveProps(p: Props) {
        let { state: { Ocupados } } = this
        let { servicio: { dates, users, sugeridos }, setData } = p
        if (sugeridos !== this.props.servicio.sugeridos)
            setData({ dates: [] })
        let aux: Array<Array<Cita>> = users.map(t => { if (t.citas !== undefined) return t.citas; return [] })
        let citas: Array<Cita> = noRepit({ a: aux, isCompare: this.buscarTurno })
        if (Ocupados !== undefined)
            Ocupados = Ocupados.map(o => {
                o.state = 1
                citas.forEach(c => {
                    if (c.date === o.date) {
                        if (c.turn === o.turn) {
                            o.state = 2
                        }
                    }
                })
                dates.forEach(d => {
                    if (d.date === o.date)
                        if (d.turn === o.turn)
                            if (o.state === 2) {
                                o.state = 3
                            } else {
                                o.state = 4
                            }
                })
                return o
            })
        this.setState({ Ocupados })
    }
}