import React, { CSSProperties } from "react"
import { Card, Col, Row, Spinner, Tab, Tabs } from "react-bootstrap"
import Servicio, { conServicios, marcado } from "../../../Model/Sevicios"
import { conTecnico } from "../../../Model/Tecnico"
import { Calendario } from "../../../Utils/Calendario"
import Mostrar from "../../Servicios/Mostrar"
import { manejadorColores } from "../../../Utils/ManejadorColores"
import { don } from "../../../Conexiones/Minimo"
import Axios from "axios"
import LeyendaCitas from "../../Tecnicos/LeyendaCitas"
import { Valorarcion } from "../../Valoracion/Valorar"
import { zfill } from "../../../Utils/Formats"
interface State {
    servicio?: Servicio | null,
    citas?: Array<marcado>
    select?: marcado
    open: boolean
}
interface Props {
    Tecnicos: number
}
var scc: CSSProperties = { border: "1px solid #e3e6f0", borderRadius: ".35rem", marginTop: "10px", marginLeft: "-1rem", padding: "1.25rem 1.25rem 1.25rem 1.25rem" }
export default class Asistencia extends React.Component<Props, State> {
    con = conTecnico()
    servicios = conServicios()
    constructor(p: any) {
        super(p)
        this.state = { open: false, servicio: null, select: undefined, citas: undefined }
        this.getCitas();
    }
    async getCitas() {
        try {
            let { Tecnicos } = this.props;
            if (Tecnicos !== 0) {
                let rest = await Axios.get(don + "/api/report_dates_tech/" + Tecnicos);
                this.setState({ citas: rest.data.data });
            }
        } catch (e) {
            console.log(e);
        }
    }
    render() {
        return <Card.Body>
            {(this.state.citas === undefined) ?
                this.cargar() : this.state.citas.length < 1 ? <Row className="d-flex justify-content-center">
                    <h4 style={{ position: "absolute" }}
                        className="m-0 font-weight-bold text-primary mr-auto align-self-center"
                    >NO HAY CITAS REGISTRADAS</h4>
                </Row> : this.getTrabajo(this.state.citas)}
        </Card.Body>
    }
    getTrabajo(dates: marcado[]) {
        let height = window.innerHeight - 300
        let start: any = undefined;
        if (this.state.select !== undefined)
            start = this.state.select.date;
        return <Row>
            <Col style={{ height }} md={8}>
                {this.state.open ? <LeyendaCitas close={() => { this.setState({ open: false }) }} /> : <></>}
                <Calendario key={'calen' + this.props.Tecnicos}
                    leyenda={() => { this.setState({ open: true }) }}
                    start={start}
                    selectDate={this.selectDate.bind(this)}
                    celdas={dates.map(d => {
                        let color = this.printColor(d);
                        return { ...d, color }
                    })} />
            </Col>
            <Col md={4}>
                <Tabs defaultActiveKey="Servicio" id="tap">
                    <Tab style={scc} eventKey="Servicio" title="Servicio">
                        {this.getMarcado()}<br />
                        {this.Servicio()}
                    </Tab>
                    {this.feedback()}
                </Tabs>
            </Col>
        </Row>
    }
    feedback() {
        let { servicio } = this.state;
        if (servicio === undefined || servicio === null)
            return <></>;
        if (servicio.feedback === null)
            return <></>;
        return <Tab style={scc} eventKey="Valoracion" title="VAloracion">
            <Valorarcion setData={() => { }} data={servicio.feedback} />
        </Tab>;
    }
    getMarcado() {
        let { select } = this.state;
        if (select === undefined)
            return <></>;
        let s = select.service_id ? select.service_id : 0;
        return <Card>
            <Card.Header>
                <h4 className="m-0 font-weight-bold text-primary mr-auto align-self-center">asistencia</h4>
            </Card.Header>
            <Card.Body>
                <b>Servicio N°:</b> {zfill(s)}<br />
                <b>Fecha:</b>{select.date}<br />
                <b>turno:</b>{select.turn === 1 ? "mañana" : "tarde"}<br />
                <b>Inicia:</b>{select.start !== null ? select.start : "N/A"}<br />
                <b>Cierre:</b>{select.end !== null ? select.end : "N/A"}<br />
            </Card.Body>
        </Card>
    }
    getValor() {
        let { servicio } = this.state;
        if (servicio === undefined || servicio === null)
            return <></>;
        let { feedback } = servicio;
        if (feedback === undefined || feedback === null)
            return <></>;
        let val = "";
        switch (feedback.rank_tech) {
            case 5: val = "EXELENTE"; break;
            case 4: val = "BUENO"; break;
            case 3: val = "PROMEDIO"; break;
            case 2: val = "MEJORABLE"; break;
            case 1: val = "MALO"; break;
        }
        return <Card>
            <Card.Header>
                <h4 style={{ position: "absolute" }}
                    className="m-0 font-weight-bold text-primary mr-auto align-self-center"
                >Valoracion del tecnico</h4>
            </Card.Header>
            <Card.Body>
                <b>Valoracion:</b>{val}<br />
                <b>Comentario:</b><br />
                <p>{feedback.comment_tech}</p>
            </Card.Body>
        </Card>
    }
    cargar() {
        return <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
            <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
            <h4 style={{ position: "absolute" }}
                className="m-0 font-weight-bold text-primary mr-auto align-self-center"
            >Cargando....</h4>
        </Row>
    }
    Servicio() {
        switch (this.state.servicio) {
            case undefined:
                return this.cargar()
            case null:
                return <h4 className="m-0 font-weight-bold text-primary mr-auto align-self-center"
                >Seleccione una fecha</h4>
            default:
                return <Mostrar servicio={this.state.servicio} />
        }
    }
    printColor(d: marcado) {
        let manejador = manejadorColores()
        if (this.state.servicio !== undefined && this.state.servicio !== null)
            manejador.setServicio(this.state.servicio)
        return manejador.printColor(d)

    }
    selectDate(date: marcado) {
        this.setState({ select: date })
        if (date.service_id !== undefined) {
            this.setState({ servicio: undefined })
            this.servicios.select(date.service_id, this.add.bind(this))
        } else {
            this.setState({ servicio: null })
        }
    }
    add(servicio: Servicio) {
        this.setState({ servicio })
    }
    componentDidUpdate(p: Props) {
        let { Tecnicos } = this.props;
        if (p.Tecnicos !== Tecnicos) {
            this.setState({ servicio: null, select: undefined })
            this.getCitas();
        }
    }
    componentWillReceiveProps(p: Props) {
        if (p.Tecnicos !== this.props.Tecnicos)
            this.getCitas();
    }
}