import React from "react"
import { Row, Table } from "react-bootstrap"
import Leyendas from "../../Utils/Leyendas"
export default class LeyendaDashboard extends Leyendas {
    Body() {
        return <Row>
            <Table>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#007bff" }}></td>
                    <td>turnos que tiene asignado para este servicio</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#FFC107" }}></td>
                    <td>turnos en el que inicio pero no cerro</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#28A745" }}></td>
                    <td>turnos con asistencia correcta en este servicio</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#DC3545" }}></td>
                    <td>turnos con inasistencia en este servicio</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#BF7BAD" }}></td>
                    <td>proximo trabajo a realizar</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#FFFF00" }}></td>
                    <td>turnos en curso para marcar cierre</td>
                </tr>
            </Table>
        </Row>
    }
}