import { faSave } from "@fortawesome/free-solid-svg-icons"
import Axios from "axios"
import React from "react"
import { Card, Col, Button, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import { don } from "../../Conexiones/Minimo"
import { Permisos } from "../../config/Permisos"
import { Storage } from "../../Storage"
import InputText from "../../Utils/InputText"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SetText from "../../Utils/setTex"
interface Props {
    actulalizar: () => void
}
interface State {
    number_fiscal?: string,
    name: string,
    email: string,
    address: string,
    phone: string,
    paid: string
    slogan: string
    web: string
    tax_name: string
    money: string
}
class ConfigGeneral extends React.Component<Props, State> {
    constructor(p: Props) {
        super(p)
        this.state = {
            number_fiscal: undefined,
            name: '',
            email: '',
            address: '',
            phone: '',
            paid: '',
            slogan: '',
            web: '',
            tax_name: '',
            money: ''
        };
        this.setState = this.setState.bind(this);
        this.company();
    }
    render() {
        let { authFuncion } = Permisos()
        if (!authFuncion("general"))
            return <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        No esta autorizado para entrar en esta seccion
                    </h1>
                </Card.Header>
            </Card>
        let { setState, state } = this;
        return <Card>
            <Card>
                <Card.Header>
                    datos de la empresa
                </Card.Header>{(state.number_fiscal === undefined) ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                    <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                    <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Cargando....
                    </h4>
                </Row> : <Card.Body>
                    <Col>
                        <InputText label="ruc" value={state.number_fiscal} setData={setState} update="number_fiscal" />
                    </Col>
                    <Col>
                        <InputText label="nombre" value={state.name} setData={setState} update="name" />
                    </Col>
                    <Col>
                        <InputText label="TELÉFONO" value={state.phone} setData={setState} update="phone" />
                    </Col>
                    <Col>
                        <InputText label="CORREO" value={state.email} setData={setState} update="email" />
                    </Col>
                    <Col>
                        <InputText label="ESLOGAN" value={state.slogan} setData={setState} update="slogan" />
                    </Col>
                    <Col>
                        <InputText label="WEB" value={state.web} setData={setState} update="web" />
                    </Col>
                    <Col>
                        <InputText label="nombre del impuesto" value={state.tax_name} setData={setState} update="tax_name" />
                    </Col>
                    <Col>
                        <InputText label="nombre de la moneda" value={state.money} setData={setState} update="money" />
                    </Col>
                    <Col xs="12">
                        <b>DIRECCIÓN</b>
                        <SetText as="textarea" value={state.address} setData={setState} update="address" />
                    </Col>
                    <Col xs="12">
                        <b>METODO DE PAGO</b>
                        <SetText as="textarea" value={state.paid} setData={setState} update="paid" />
                    </Col>
                    <Col xs="12">
                        <Button
                            onClick={() => { this.save() }}
                            className="float-right"
                            variant="success">
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </Col>
                </Card.Body>}
            </Card>
        </Card>
    }
    componentDidMount() {
        this.context.set({ title: "configuracion general" })
    }
    async company() {
        try {
            let rest = await Axios.get(don + "/api/getCompany");
            this.setState(rest.data);
        } catch (e: any) {
            if (e.response !== undefined)
                toast(e.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async save() {
        try {
            let data = this.state;
            let rest = await Axios({ method: 'post', url: don + "/api/setCompany", data });
            toast(rest.data.message, { type: toast.TYPE.SUCCESS });
            this.setState({ number_fiscal: undefined });
            this.company();
        } catch (e: any) {
            if (e.response !== undefined)
                toast(e.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
}
ConfigGeneral.contextType = Storage
export default ConfigGeneral