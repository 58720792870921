import Axios from "axios"
import { General } from "./General"
import { toast } from "react-toastify"
import app from "../config/firebase"

export class Activas extends General {


    async setActivas(setData: (d: any) => void) {
        await this.setListaActivas(setData)
        this.updateListaActiva(setData)
    }
    updateListaActiva(setData: (d: any) => void) {
        app.ref(this.tabla + '/' + this.coleccion)
            .on('value', snap => {
                if (this.sent < snap.val()) {
                    this.sent = snap.val()
                    this.setLista(setData)
                }
            })
    }
    async setListaActivas(setData: (d: any) => void) {
        try {
            let rest = await Axios.get(this.dominio + "/api/" + this.coleccion + "/list/active")
            setData(rest.data.data)
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }

}