import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { Permisos } from "../../../config/Permisos"
import { Valoraciones } from "../Graficas/valoraciones"
import moment from 'moment';
import SetMes from "../SelectorDeMes"
import ValorRank, { Rank } from "../../../Utils/ValorRank";
import Axios from "axios";
import { don } from "../../../Conexiones/Minimo";
interface State {
    servicios: Rank
    tecnicos: Rank
    mont: number
    year: number
}
export default class ValorTecnico extends React.Component<any, State> {
    per = Permisos()
    constructor(p: any) {
        super(p);
        this.state = {
            servicios: { rank1: 0, rank2: 0, rank3: 0, rank4: 0, rank5: 0, top: 0 },
            tecnicos: { rank1: 0, rank2: 0, rank3: 0, rank4: 0, rank5: 0, top: 0 },
            year: parseInt(moment().format("YYYY")),
            mont: parseInt(moment().format("MM"))
        }
    }
    async getServicios() {
        try {
            let { year, mont } = this.state;
            let repot = "year/" + year;
            if (mont !== 0) {
                repot = "month/" + year + "/" + mont;
            }
            let rest = await Axios.get(don + "/api/graph/feedback/service/" + repot);
            this.setState({ servicios: this.convert(rest.data.data) });
        } catch (e) {
            console.log(e);
        }
    }
    async getTecnicos() {
        try {
            let { year, mont } = this.state;
            let repot = "year/" + year;
            if (mont !== 0) {
                repot = "month/" + year + "/" + mont;
            }
            let rest = await Axios.get(don + "/api/graph/feedback/tech/" + repot);
            this.setState({ tecnicos: this.convert(rest.data.data) });
        } catch (e) {
            console.log(e);
        }
    }
    convert(data: { RANK: number, CANT: number }[]) {
        let R = { rank1: 0, rank2: 0, rank3: 0, rank4: 0, rank5: 0, top: 0 };
        data.forEach(r => {
            switch (r.RANK) {
                case 1:
                    R.rank1 = r.CANT;
                    break;
                case 2:
                    R.rank2 = r.CANT;
                    break;
                case 3:
                    R.rank3 = r.CANT;
                    break;
                case 4:
                    R.rank4 = r.CANT;
                    break;
                case 5:
                    R.rank5 = r.CANT;
                    break;
            }
            if (R.top < r.CANT)
                R.top = r.CANT;
        })
        return R;
    }
    render() {
        let { year, mont, servicios, tecnicos } = this.state;
        return <Card>
            <Card.Header>
                <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center"> Valoraciones </h1>
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    <Card>
                        <Card.Header>
                            <SetMes mont={mont} year={year} setData={this.setState.bind(this)} />
                        </Card.Header>
                        <Row className="p-2">
                            <Col md="12" className="p-2">
                                <ValorRank title="Servicios" data={servicios} />
                            </Col>
                            <Col md="12" className="p-2">
                                <ValorRank title="tecnicos" data={tecnicos} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Card.Body>
        </Card >
    }
    Valoracion(mont: number, year: number) {
        if (this.per.authFuncion("valoracion"))
            return <Valoraciones mont={mont} year={year} />
        return <></>
    }
}