import React from 'react'
import { FormControl } from 'react-bootstrap'
import { Rol, conAuthAsing } from '../../Model/Roles'
interface Props {
    change: (r: number) => void
    defaultValue?: number
}
export class RolSelector extends React.Component<Props, { Roles: Array<Rol> }>{
    con = conAuthAsing()
    constructor(p: { change: () => void }) {
        super(p)
        this.state = { Roles: [] }
        this.setState = this.setState.bind(this)
    }
    async getRoles(Roles: Rol[]) {
        this.setState({ Roles })
    }
    render() {
        if (this.state.Roles.length === 0)
            return <></>;
        let { defaultValue } = this.props;
        return <FormControl onChange={this.setRol.bind(this)} defaultValue={this.props.defaultValue} as="select">
            <option value={0}>seleccionar Rol</option>
            {this.state.Roles.map(rol => {
                return <option {...(rol.id === defaultValue) ? "selected" : ""} value={rol.id}>{rol.name}</option>
            })}
        </FormControl>
    }
    setRol(Rol: React.ChangeEvent<HTMLSelectElement>) {
        let value = Rol.currentTarget.value as string
        let r = parseInt(value)
        this.props.change(r)
    }
    componentDidMount() {
        this.con.setListaRoles(this.getRoles.bind(this))
    }

}