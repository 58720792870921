import React from "react"
import Notificaciones from "./Notificaciones"
import User from "./User"
import Referir from "../app/Referenciados/Referir"
import { Storage } from "../Storage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons"
import Mensajes from "./Mensajes"
import Chats from "./Chats"
class Navbar extends React.Component<any, { open: boolean }> {
    constructor(p: any) {
        super(p)
        this.state = { open: false }
    }
    render() {
        let { open } = this.state
        return <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            {open ? <Referir open close={this.Open.bind(this)} /> : <></>}
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars"></i>
            </button>
            <h3 className="m-0 font-weight-bold text-primary mr-auto align-self-center">{this.context.data.title}</h3>
            <ul className="navbar-nav ml-auto" style={{fontSize:'25px'}}>
                <li className="nav-item dropdown no-arrow">
                    <div onClick={this.Open.bind(this)} style={{ color: '#28a745' }} className="nav-link dropdown-toggle">
                        <FontAwesomeIcon icon={faPhoneSquareAlt} />
                    </div>
                </li>
                <Chats />
                <Mensajes />
                <Notificaciones />
                <div className="topbar-divider d-none d-sm-block"></div>
                <User />
            </ul>
        </nav >
    }
    Open() {
        this.setState({ open: !this.state.open })
    }
}
Navbar.contextType = Storage
export default Navbar