export function numberFormat(number: number) {
    return (number
        .toFixed(2) // always two decimal digits
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) // use . as a separator
}

export function zfill(number: number, width = 6) {
    var numberOutput = Math.abs(number) /* Valor absoluto del número */
    var length = number.toString().length /* Largo del número */
    var zero = "0" /* String de cero */

    if (width <= length) {
        if (number < 0) {
            return ("-" + numberOutput.toString())
        } else {
            return numberOutput.toString()
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString())
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString())
        }
    }
}

export function ordenText(label: string, array: Array<any>) {

}

export function ordenNumber(label: string, array: Array<any>) {

}