import React from "react"
import { Card, Col } from "react-bootstrap"
import { Permisos } from "../../../config/Permisos"
import { Valoraciones } from "../Graficas/valoraciones"
import moment from 'moment';
import SetMes from "../SelectorDeMes"
interface State {
    mont: number
    year: number
}
export default class ValorTodos extends React.Component<any, State> {
    per = Permisos()
    constructor(p: any) {
        super(p);
        this.state = {
            year: parseInt(moment().format("YYYY")),
            mont: parseInt(moment().format("MM"))
        }
    }
    render() {
        let { year, mont } = this.state;
        return <Card>
            <Card.Header>
                <SetMes mont={mont} year={year} setData={this.setState.bind(this)} />
            </Card.Header>
            <Card.Body>
                <Col md={12}>
                    {this.Valoracion(mont, year)}
                </Col>
            </Card.Body>
        </Card >
    }
    Valoracion(mont: number, year: number) {
        if (this.per.authFuncion("valoracion"))
            return <Valoraciones mont={mont} year={year} />
        return <></>
    }
}