import User from "../Model/User"
import { Permission } from "../Model/Roles"
import { img } from "../Utils/img"
import { Permisos } from "../config/Permisos"
import Axios from "axios"
import { don } from "../Conexiones/Minimo"
import { Provider } from "../Storage"
import { Notificaciones } from "../Model/Notificaciones"
// import { Categorias } from "./Categorias"
// import { Clientes } from "./Cliente"
// import { Contribuyentes } from "./Contribuyentes"
// import { Productoss } from "./Productos"
// import { Servicios } from "./Servicios"
// import { Referidos } from "./Referidos"
// import { Cotizaciones } from "./Cotizaciones"
// import { Valores } from "./Valoraciones"
import { users } from "./Users"

class Auth implements User {
    id = 0
    name = ""
    email = ""
    phone = ""
    permisos = Permisos()
    status = 1
    roles = []
    image = img
    provider?: Provider
    setPermisos(per: Permission[]) {
        per.forEach(permi => {
            this.permisos.setPermisos(permi)
        })
    }
    setUser(d: User) {
        this.id = d.id
        this.name = d.name
        this.email = d.email
        this.phone = d.phone
        this.status = d.status
        this.image = d.image
    }
    setProvider(provider: Provider) {
        this.provider = provider
    }
    actualizar() {
        if (this.provider)
            this.provider.actializar()
    }
    async Login(setData: (d: { User: User }) => void) {
        try {
            let rest = await Axios.get(don + "/api/auth/user")
            let User = rest.data
            this.setUser(User)
            Notificaciones(User.id)
            setData({ User })
        } catch (error) {
        }
    }
    async LoadPermisos(setData: (d: { Permisos: Permission[] }) => void) {
        try {
            let rest = await Axios.get(don + "/api/mypermissions")
            let Permisos: Array<any> = rest.data.data
            this.setPermisos(Permisos)
            setData({ Permisos })
            if (this.id > 0)
                users()
        } catch (error) {
        }
        // Cotizaciones()
        // Servicios()
        // Categorias()
        // Clientes()
        // Referidos()
        // Contribuyentes()
        // Productoss()
        // Valores()
    }
}
var a: Auth
export function auth() {
    if (a === undefined)
        a = new Auth()
    return a
}