import Axios from "axios"
import { toast } from "react-toastify"
import app from "../config/firebase"
import { don } from "./Minimo"
export class Mensajes {
    sent = 0;
    tabla: string = '';
    coleccion: string = '';
    ruta: string = "";
    dominio = don;
    escuchar: boolean = false;
    actualizar: (d: any) => void = () => { };
    constructor(id: number) {
        this.coleccion = id + "";
        setTimeout(() => {
            this.setSent();
        }, 100)
    }
    async setSent() {
        const snat = await app.ref(this.tabla + '/' + this.coleccion).once('value')
        const data = snat.val()
        if (data !== undefined)
            this.sent = data
    }
    updateLista() {
        this.setMSN()
        app.ref(this.tabla + '/' + this.coleccion)
            .on('value', snap => {
                if (this.sent < snap.val()) {
                    this.sent = snap.val()
                    if (this.escuchar)
                        this.setMSN()
                }
            })
    }
    async setUsers() {
        try {
            this.actualizar({ tec: [] })
            let rest = await Axios.get(this.dominio + "/api/" + this.tabla + "/" + this.ruta + "/users/" + this.coleccion)
            this.actualizar({ tec: rest.data.data })
        } catch (error) {
            //toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async setMSN() {
        try {
            let rest = await Axios.get(this.dominio + "/api/" + this.tabla + "/" + this.ruta + "/msg/" + this.coleccion)
            this.actualizar({ mensajes: rest.data.data })
        } catch (error) {
            //toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async enviar(msn: string) {
        try {
            await Axios({ method: 'post', url: this.dominio + "/api/" + this.ruta + "s", data: { msn, quotation_id: this.coleccion, service_id: this.coleccion } })
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
}