import React from "react"
import { Card } from "react-bootstrap"
import { Route } from "react-router-dom";
import { Impresos } from "./Impresos";
import { ReportTecnicos } from "./Tecnicos";
import Usuarios from "./Usuarios";
import { Selector } from "./Selector";
import {ReferidosReport} from './Referidos'
export class Reportes extends React.Component {
    render() {
        return <Card>
            <Route path="/reportes/generales" component={Impresos} />
            <Route path="/reportes/usuarios" component={Usuarios} />
            <Route path="/reportes/tecnicos" component={ReportTecnicos} />
            <Route path="/reportes/referidos" component={ReferidosReport} />
            <Route exact path="/reportes" component={Selector} />
        </Card>;
    }
}