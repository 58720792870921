import React from 'react'
import { Button, Modal, Image, Row, Col, InputGroup } from 'react-bootstrap'
import User, { conUsers, InputUser, UserNull } from '../../Model/User'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputText from '../../Utils/InputText'
import { RolSelector } from './RolSelector'
import { toast } from 'react-toastify'
interface Props {
    close: () => void,
    add?: (user: User) => {}
}
export default class UserCreate extends React.Component<Props, InputUser>{
    con = conUsers()
    constructor(p: Props) {
        super(p)
        this.setState = this.setState.bind(this)
        this.state = UserNull
    }
    setRol(id: number) {
        this.setState({ Rol: id })
    }
    setImage(e: React.ChangeEvent<HTMLInputElement>) {
        let t = this
        let f = e.currentTarget.files
        let reader = new FileReader()
        reader.onloadend = function () {
            t.setState({ image: reader.result as string })
        }
        if (f != null)
            reader.readAsDataURL(f[0])
    }
    render() {
        let { setState, state } = this
        return <Modal size="lg" centered animation show={true} onHide={this.props.close}>
            <Modal.Header closeButton>
                Nuevo usuario
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col as="label" xs="4">
                        <Image fluid rounded src={this.state.image} />
                        <input style={{ display: "none" }} onChange={this.setImage.bind(this)} type="file" />
                    </Col>
                    <Col xs="8">
                        <Col xs="12">
                            <InputText label="NOMBRE" value={state.name} setData={setState} update="name" />
                        </Col>
                        <Col xs="12">
                            <InputText label="CORREO" value={state.email} setData={setState} update="email" />
                        </Col>
                        <Col xs="12">
                            <InputText label="TELEFONO" value={state.phone} setData={setState} update="phone" />
                        </Col>
                        <Col xs="12">
                            <InputText type="password" label="CLAVE" value={state.password} setData={setState} update="password" />
                        </Col>
                        <Col xs="12">
                            <InputText type="password" invalid={this.validClave()} invalidMsn="LAS CLAVES NO COINSIDEN" label="REPETIR CLAVE" value={state.passwordConfirm} setData={setState} update="passwordConfirm" />
                        </Col>
                        <Col xs="12">
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        ROL
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <RolSelector change={this.setRol.bind(this)} />
                            </InputGroup>
                        </Col>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.get.bind(this)} variant="primary"><FontAwesomeIcon icon={faSave} /></Button>
            </Modal.Footer>
        </Modal>
    }
    validClave() {
        let { password, passwordConfirm } = this.state
        return password !== passwordConfirm
    }
    async get() {
        let { name, password, passwordConfirm, phone, email, Rol } = this.state
        if (name === "" || password === "" || passwordConfirm === "" || phone === "" || email === "" || Rol === 0) {
            toast("debes completar todos los campos", { type: toast.TYPE.ERROR })
        } else {
            await this.con.create(this.state, this.props.add)
            this.props.close()
        }
    }
}