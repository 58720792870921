import Axios from "axios"
import React from "react"
import { Card, Col, Row } from "react-bootstrap";
import { don } from "../../Conexiones/Minimo";
import moment from 'moment';
import ValorRank, { Rank } from "../../Utils/ValorRank";
var year = moment().format("YYYY");
var mont = moment().format("MM");
interface state {
    servicios: Rank
    tecnicos: Rank
}
export class Dashboard extends React.Component<any, state> {
    constructor(p: any) {
        super(p);
        this.state = {
            servicios: {
                rank1: 0,
                rank2: 0,
                rank3: 0,
                rank4: 0,
                rank5: 0,
                top: 0
            },
            tecnicos: {
                rank1: 0,
                rank2: 0,
                rank3: 0,
                rank4: 0,
                rank5: 0,
                top: 0
            }
        }
        this.send()
    }
    send() {
        this.getServicios();
        this.getTecnicos();
    }
    convert(data: { RANK: number, CANT: number }[]) {
        let R = {
            rank1: 0,
            rank2: 0,
            rank3: 0,
            rank4: 0,
            rank5: 0,
            top: 0
        };
        data.forEach(r => {
            switch (r.RANK) {
                case 1:
                    R.rank1 = r.CANT;
                    break;
                case 2:
                    R.rank2 = r.CANT;
                    break;
                case 3:
                    R.rank3 = r.CANT;
                    break;
                case 4:
                    R.rank4 = r.CANT;
                    break;
                case 5:
                    R.rank5 = r.CANT;
                    break;
            }
            if (R.top < r.CANT)
                R.top = r.CANT;
        })
        return R;
    }
    async getServicios() {
        try {
            let rest = await Axios.get(don + "/api/graph/feedback/service/month/" + year + "/" + mont);
            this.setState({ servicios: this.convert(rest.data.data) });
        } catch (e) {
            console.log(e);
        }
    }
    async getTecnicos() {
        try {
            let rest = await Axios.get(don + "/api/graph/feedback/tech/month/" + year + "/" + mont);
            this.setState({ tecnicos: this.convert(rest.data.data) });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        let { servicios, tecnicos } = this.state;
        return <Card>
            <Card.Header>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Valoraciones
                </h6>
            </Card.Header>
            <Row className="p-2">
                <Col md="6" className="p-2">
                    <ValorRank title="Servicios" data={servicios} />
                </Col>
                <Col md="6" className="p-2">
                    <ValorRank title="tecnicos" data={tecnicos} />
                </Col>
            </Row>
        </Card>
    }



}