import React from 'react'
import { Spinner, Table, Image, Modal, Row, InputGroup, FormControl, Button } from 'react-bootstrap'
import moment from 'moment'
import "moment/locale/es"
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Storage } from '../../Storage'
import User, { userPrueba } from '../../Model/User'
import { conBitacora, Seguimiento } from '../../Model/Bitacora'
import { users } from '../../database/Users'
import { ifArray } from '../../Utils/ArrayFun'
import { auth } from '../../database/Auth'
moment.locale('es')
interface Props {
    open: boolean,
    close: () => void,
    id: number
}
interface State {
    tec?: Array<User>
    msn: string
    mensajes?: Array<Seguimiento>
}
class FilaSeguimiento extends React.Component<{ seguimiento: Seguimiento, user: User }> {
    render() {
        let { image, name, email } = this.props.user
        let { msn } = this.props.seguimiento, fecha
        if (this.props.seguimiento.created_at !== undefined)
            fecha = this.props.seguimiento.created_at
        else
            fecha = ""
        return <tr>
            <th style={{ width: "100px" }}>
                <Image fluid src={image} />
            </th>
            <th style={{ width: "300px", maxWidth: "300px" }}>
                {name}<br />
                {email}
            </th>
            <th style={{ width: "200px" }}>
                {moment(fecha).format("DD-MM-Y")}<br />
                {moment(fecha).format("hh:mm A")}
            </th>
            <th>
                {msn}
            </th>
        </tr>
    }
}
class Seguimientos extends React.Component<Props, State> {
    con = conBitacora(0)
    msn: any = undefined
    ref = React.createRef<HTMLDivElement>()
    constructor(p: Props) {
        super(p)
        this.con = conBitacora(p.id)
        this.state = { msn: "", tec: undefined };
        this.setState = this.setState.bind(this);
    }
    render() {
        let { mensajes } = this.state
        return <Modal size="xl" centered animation show={this.props.open} onHide={this.props.close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>BITACORA DE COTIZACIÓN N° {this.props.id}</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ width: "100px", color: "white" }}>foto</th>
                                <th style={{ width: "300px" }}>USUARIO</th>
                                <th style={{ width: "200px" }}>FECHA</th>
                                <th>MENSAJE</th>
                            </tr>
                        </thead>
                    </Table>
                    <div ref={this.ref} style={{ overflowY: "auto", height: 400, maxHeight: 400, width: "100%" }}>
                        <Table>
                            <tbody>
                                {mensajes === undefined ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                                    <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                                    <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                                        Cargando....
                                    </h4>
                                </Row> : mensajes.map(s => {
                                    return <FilaSeguimiento key={'chatmsn' + s.id} seguimiento={s} user={users().getUser(s.user_id)} />
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Mensaje</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl onKeyUp={this.setMsn.bind(this)} defaultValue={this.state.msn} />
                        <InputGroup.Append>
                            <Button onClick={this.enviar.bind(this)}>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Row>
            </Modal.Body>
        </Modal>
    }
    setMsn(e: React.KeyboardEvent<HTMLInputElement>) {
        let { value } = e.currentTarget
        if (this.msn === undefined)
            this.msn = e.currentTarget
        this.setState({ msn: value })
        if (e.keyCode === 13) {
            this.enviar()
        }
    }
    enviar() {
        this.con.enviar(this.state.msn)
        let m: Array<Seguimiento> = (this.state.mensajes === undefined) ? [] : this.state.mensajes
        this.setState({
            mensajes: [...m, {
                id: 0,
                deleted_at: "",
                msn: this.state.msn,
                created_at: moment().format("Y-MM-DD HH:mm"),
                user_id: auth().id
            }]
        })
        this.clear()
    }
    clear() {
        if (this.msn !== undefined)
            this.msn.value = ''
    }
    actualizar() {
        this.con.setMSN();
    }
    add(tec: User) {
        if (tec.id === 0) {
            let m: Array<User> = (this.state.tec === undefined) ? [] : this.state.tec;
            if (!ifArray({ a: m, b: tec, label: "id" })) {
                this.setState({ tec: [...m, tec] })
            }
        } else {
            if (this.state.tec === undefined)
                this.con.setUsers()
        }
    }
    getUser(id: number) {
        let tec = userPrueba
        let m: Array<User> = (this.state.tec === undefined) ? [] : this.state.tec;
        m.forEach(t => {
            if (t.id === id)
                tec = t
        })
        if (tec.id === 0)
            tec = users().getUser(id, this.add.bind(this))
        return tec
    }
    componentWillMount() {
        if (this.props.open === true) {
            this.con.actualizar = this.setState.bind(this);
            this.con.updateLista()
        }
    }
    setMensajes(data: { mensajes: Seguimiento[] }) {
        this.setState({ mensajes: data.mensajes });
        this.forceUpdate();
    }
    componentDidUpdate() {
        if (this.ref.current !== null) {
            let tbody = this.ref.current
            setTimeout(() => {
                tbody.scrollTop = tbody.scrollHeight
            }, 200)
        }
    }
    componentWillUnmount() {
        this.con.escuchar = false;
    }
    componentWillReceiveProps(p: Props) {
        this.con.actualizar = this.setState.bind(this);
        this.con.updateLista()
        this.con.escuchar = p.open;
    }
}
Seguimientos.contextType = Storage
export default Seguimientos