import React from "react"
import { Dropdown, Image, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faComment } from "@fortawesome/free-solid-svg-icons"
import { Storage } from "../Storage"
import { Notificaciones, notify } from "../Model/Notificaciones"
import { auth } from "../database/Auth"
import { users } from "../database/Users"
import { zfill } from "../Utils/Formats"
import Chat from "../app/Servicios/Chat"
import { UpdateElement } from "../Utils/ArrayFun"
import { toast } from "react-toastify"
interface State {
    notificaciones: notify[]
    cont: number
    chat: number
    open: boolean
}
class Notify extends React.Component<notify & { select: (chat: number) => void }>{
    render() {
        let { props: { select, service_id, user_id, msn } } = this,
            user = users().getUser(user_id)
        return <Dropdown.Item className="d-flex align-items-center">
            <div onClick={() => { select(service_id) }} className="dropdown-list-image mr-3">
                <Image width="60" height="60" roundedCircle src={user.image} alt="" />
            </div>
            <div onClick={() => { select(service_id) }} style={{ width: 180 }} className="font-weight-bold">
                <div className="text-truncate">{user.name}</div>
                <div className="small float-right">{zfill(service_id)}</div>
                <div style={{ maxWidth: 180, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} className="small">{msn}</div>
            </div>
        </Dropdown.Item>
    }
}
class Chats extends React.Component<any, State> {
    con = Notificaciones(auth().id)
    constructor(p: any) {
        super(p)
        this.state = { cont: 0, notificaciones: [], open: false, chat: 0 }
        this.setState = this.setState.bind(this)
    }
    render() {
        let height = window.innerHeight / 2
        let { chat, open } = this.state;
        return <Dropdown as="li" className="nav-item no-arrow mx-1">
            <Dropdown.Toggle className="nav-link" as="div">
                {chat > 0 ? open ? <Chat open={open} id={chat}
                    close={() => { this.setState({ open: false, chat: 0 }) }} /> : <></> : <></>}
                <FontAwesomeIcon onClick={() => { this.setState({ cont: 0 }) }} icon={faComment} />
                {this.state.cont > 0 ?
                    <span className="badge badge-danger badge-counter">{this.state.cont}</span> : <></>}
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="dropdown-list dropdown-menu p-0 shadow animated--grow-in">
                <Dropdown.Header className="bg-warning border-warning" as="h6">ultimos Mensajes</Dropdown.Header>
                <div style={{ height, overflowY: "scroll" }}>
                    {this.getNotificaciones()}
                </div>
                <Dropdown.Item href="#/action-1" className="text-center small text-gray-500">ultimos Mensajes</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    }
    componentDidMount() {
        this.start();
    }
    start() {
        if (this.con.id !== 0) {
            if (this.state.notificaciones.length === 0) {
                this.con.updateServicio(this.setData.bind(this));
            } else {
                this.con.setServicio(this.setLimpia.bind(this));
                setTimeout(() => {
                    this.start();
                }, 108000000);
            }
        } else {
            setTimeout(() => {
                this.start();
            }, 500);
        }
    }
    updateNotifi(n: notify) {
        n.read = 2;
        this.setState({ cont: this.state.cont + 1, notificaciones: UpdateElement({ a: this.state.notificaciones, b: n, label: "id" }) });
        let user = users().getUser(n.user_id)
        toast(<Row onClick={() => { this.select(n.service_id) }} className="px-2">
            <div className="dropdown-list-image mr-3">
                <Image width="60" height="60" roundedCircle src={user.image} alt="" />
            </div>
            <div style={{ width: 180 }} className="font-weight-bold">
                <div className="text-truncate">{user.name}</div>
                <div className="small float-right">{zfill(n.service_id)}</div>
                <div style={{ maxWidth: 180, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} className="small">{n.msn}</div>
            </div>
        </Row>, { type: toast.TYPE.WARNING })
    }
    setData(d: notify[]) {
        this.setState({
            notificaciones: [...this.state.notificaciones, ...d].sort((a, b) => {
                return (b.id - a.id)
            })
        });
    }
    setLimpia(d: notify[]) {
        this.setState({
            notificaciones: d.sort((a, b) => {
                return (b.id - a.id)
            })
        });
    }
    select(chat: number) {
        this.setState({ chat, open: true })
    }
    getNotificaciones() {
        let { notificaciones } = this.state
        return notificaciones.map((m: notify) => {
            if (m.read !== 2)
                this.updateNotifi(m);
            return <Notify select={this.select.bind(this)} key={"chat" + m.id} {...m} />
        })
    }
}
Chats.contextType = Storage
export default Chats
