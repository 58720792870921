import React from "react"
import { Card, Row, Col } from "react-bootstrap"
import { Permisos } from "../config/Permisos"
import { Dashboard as Tecnico } from "./Tecnicos/Dashboard"
import { Dashboard as Cotizacion } from "./Cotizacion/Dashboard"
import { Dashboard as Servicios } from "./Servicios/Dashboard"
import { Dashboard as Valoracion } from "./Valoracion/Dashboard"
export class Dashboard extends React.Component {
    per = Permisos()
    render() {
        return <Card>
            <Card.Header>
                <h4 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Datos del Mes
                </h4>
            </Card.Header>
            <Row className="p-3">
                <Col>
                    {this.Cotizacion()}
                    {this.Servicios()}
                    {this.Valoracion()}
                    {this.tecnico()}
                </Col>
            </Row>
        </Card >
    }
    tecnico() {
        if (this.per.authFuncion("tecnico"))
            return <Tecnico />
        return <></>
    }
    Servicios() {
        if (this.per.authFuncion("servicio"))
            return <Servicios />
        return <></>
    }
    Cotizacion() {
        if (this.per.authFuncion("cotizacion"))
            return <Cotizacion />
        return <></>
    }
    Valoracion() {
        if (this.per.authFuncion("valoracion"))
            return <Valoracion />
        return <></>
    }
}