import { Minimo } from "../Conexiones/Minimo"
import Cliente, { ClienteDefault } from "./Clientes"
import Productos from "./Productos"

export interface Status {
    id: number
    status: number
}
export interface fechas {
    created_at?: string,
    updated_at?: string,
    deleted_at?: string,
}

export interface DataOperacion extends fechas {
    discount: number
    id: number
    quotation_id: number
    status: number
    tax: number
    exempt: number
    taxable: number
    transport: number
    user_id?: number
    note: string
    name: string
    with_tax: boolean
    client: Cliente,
    products: Array<Producto>
}
export interface Producto {
    product: Productos,
    quantity_turns: number,
    price: number
    quantity: number
    techs: number
    comment: string
}
export const DefaultNull: DataOperacion = {
    id: 0,
    discount: 0,
    tax: 0,
    note: "",
    exempt: 0,
    taxable: 0,
    status: 1,
    transport: 0,
    quotation_id: 0,
    name: "",
    with_tax: false,
    client: ClienteDefault,
    products: [],
}

export function SendProducto(Productos: Array<Producto>) {
    return Productos.map(p => {
        return {
            product_id: p.product.id,
            quantity: p.quantity,
            price: p.price,
            techs: p.techs,
            quantity_turns: p.quantity_turns,
            comment: p.comment
        }
    })
}
export function SentData(Cotizacion: DataOperacion, total: { taxable: number, exempt: number }) {
    let send: any = {
        id: Cotizacion.id,
        client_id: Cotizacion.client.id,
        taxable: total.taxable,
        exempt: total.exempt,
        transport: Cotizacion.transport,
        tax: Cotizacion.client.taxpayer_type.tax,
        discount: Cotizacion.discount,
        note: Cotizacion.note,
        status: 1,
        with_tax: Cotizacion.with_tax,
        products: SendProducto(Cotizacion.products),
    }
    if (Cotizacion.quotation_id !== 0)
        send.quotation_id = Cotizacion.quotation_id
    return send
}

export interface DataList {
    todas: any[]
    activas: any[]
    con: Minimo
    actualizar: () => void
    setTodas: () => void
    setActivas: () => void
}