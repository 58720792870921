import React from 'react'
import { Storage } from '../../Storage'
import { Button, Col, Row } from 'react-bootstrap';
import FilaCotizacion from './FilaCotizacion'
import { Link } from 'react-router-dom'
import Cotizacion, { conCotizacion } from '../../Model/Cotizacion'
import { PermisosType } from '../../config/Permisos'
import Tabla from '../../Utils/Table'
class ListCotizaciones extends Tabla {
    labelBusqueda = "BUSCAR COTIZACIÓN";
    bucarLabel = ["id", "name"];
    permiso: PermisosType = "cotizacion";
    con = conCotizacion();
    componentWillMount() {
        var yo = this;
        this.context.set({ title: "LISTA DE COTIZACIONES DE CLIENTES" })
        this.con.setCargar((itens: Array<any>) => {
            yo.setState({ itens })
        });
    }
    Row(p: Cotizacion) {
        return <FilaCotizacion update={()=>{this.con.setLista(this.setItens.bind(this))}} key={"cot" + p.id + p.status} cotizacion={p} />
    }
    Header() {
        return <tr>
            <th onClick={() => { this.setOrden("id") }}>NUMERO{this.flechas("id")}</th>
            <th>FECHA</th>
            <th onClick={() => { this.setOrden("name") }}>CLIENTE{this.flechas("name")}</th>
            <th style={{textAlign:'right' }} >MONTO</th>
            <th>ESTATUS</th>
            <th>ACCIONES</th>
        </tr>
    }
    busquedaMostrar() {
        return <Row>
            <Col as="h6" md="6" className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                {this.Buscador(this.labelBusqueda)}
            </Col>
            <Button to="/cotizacion/nueva" as={Link} variant="primary">NUEVO</Button>
        </Row>
    }
}
ListCotizaciones.contextType = Storage
export default ListCotizaciones