import { General } from "../Conexiones/General"
import { Seguimiento } from "./Bitacora"
import { DataOperacion, DefaultNull } from "./Generales"
export class cotizacion extends General {
    tabla = 'tablas'
    coleccion = 'quotations'
    lista: string = 'cotizaciones'
}
var conCot: cotizacion
export function conCotizacion() {
    if (conCot === undefined)
        conCot = new cotizacion()
    return conCot
}
export default interface Cotizacion extends DataOperacion {
    tracings: Array<Seguimiento>
}
export var CotizacionDefault: Cotizacion = {
    ...DefaultNull,
    tracings: [],
}