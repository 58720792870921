import { faClone, faEdit, faPrint, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Row, Col, Button, Table, ButtonGroup, FormCheck } from 'react-bootstrap'
import BuscarProducto from '../app/Productos/BuscarProducto'
import FormProductos from '../app/Productos/FormProductos'
import BuscarCotizacion from '../app/Cotizacion/BuscarCotizacion'
import Cotizacion from '../Model/Cotizacion'
import FilaUtileServicios from "./FilaUtileServicios"
import Servicio from '../Model/Sevicios'
import { toast } from 'react-toastify'
import { removeElement, addUpdateArray } from './ArrayFun'
import InputText from './InputText'
import CalcularPagar from './CalcularPagar'
import { Producto } from '../Model/Generales'
import Productos from '../Model/Productos'
interface State {
    nuevoProducto: boolean
    buscarProducto: boolean
    verSeguimientos: boolean
    buscarCotizacion: boolean
}
interface Props {
    Cotizacion: Servicio | Cotizacion
    setData: (data: any) => void
    Prosesar?: () => void
    editable: boolean
}
export default class UtileServicios extends React.Component<Props, State> {
    setData: (data: any) => void
    KeyDescuento = "des"
    keyNota = "not"
    keyTransport = "tarns"
    constructor(p: Props) {
        super(p)
        this.setData = p.setData
        this.state = { nuevoProducto: false, buscarProducto: false, verSeguimientos: false, buscarCotizacion: false }
    }
    addProductos(producto: Producto) {
        if (this.props.editable) {
            let aux = { a: this.props.Cotizacion.products, b: producto, isCompare: (a: Producto, b: Producto) => { if (a.product.id === b.product.id) return true; return false } }
            this.setData({ products: addUpdateArray(aux) })
        } else {
            toast("no esta habilitada la edicion de productos", { type: toast.TYPE.ERROR })
        }
    }
    removeProductos(producto: Producto) {
        let aux = { a: this.props.Cotizacion.products, b: producto, isCompare: (a: Producto, b: Producto) => { if (a.product.id === b.product.id) return true; return false } }
        this.setData({ products: removeElement(aux) })
        toast('Producto ' + producto.product.description + " Quitado", { type: toast.TYPE.SUCCESS })
    }
    createProducto(p: Productos) {
        this.addProductos({
            product: p,
            techs: p.quantity,
            quantity: 1,
            price: p.cost + (p.cost * p.price_2 / 100),
            comment: "",
            quantity_turns: p.quantity
        });
    }
    render() {
        let imp: number = 0;
        let ext: number = 0;
        return <>
            {this.state.buscarCotizacion ? <BuscarCotizacion open={this.state.buscarCotizacion} add={this.cargarCotizacion.bind(this)} close={this.close.bind(this)} /> : <></>}
            {this.state.nuevoProducto ? <FormProductos Producto={null} title="NUEVO PRODUCTOS" close={this.close.bind(this)} add={this.createProducto.bind(this)} /> : <></>}
            {this.state.buscarProducto ? <BuscarProducto open={this.state.buscarProducto} add={this.addProductos.bind(this)} close={this.close.bind(this)} /> : <></>}
            <Row>
                <Col xs="3">
                    <InputText key={this.KeyDescuento} after="%" type="number" label="DESCUENTO" value={this.props.Cotizacion.discount} setData={this.setData} update="discount" />
                </Col>
                <Col xs="3">
                    <InputText key={this.keyTransport} label="transporte" type="number" value={this.props.Cotizacion.transport} setData={this.setData} update="transport" />
                </Col>
                <Col xs="6">
                    <InputText key={this.keyNota} label="NOTA" value={this.props.Cotizacion.note} setData={this.setData} update="note" />
                </Col>
            </Row>
            <Row>
                <Col xs="1">
                    <Button data-tip="Copiar Cotizacion" onClick={() => { this.setState({ buscarCotizacion: true }) }} variant="info">
                        <FontAwesomeIcon icon={faClone} />
                    </Button>
                </Col>
                <Col xs={{ span: 1, offset: 8 }}>
                    <FormCheck className="float-right"
                        id="imp"
                        type="switch"
                        label="IMPUESTO"
                        checked={this.props.Cotizacion.with_tax}
                        onClick={this.impuesto.bind(this)}
                    />
                </Col>
                <Col xs="2">
                    {this.Prosesar()}
                    <ButtonGroup className="float-right">
                        <Button data-tip="Nuevo Producto" onClick={() => { this.setState({ nuevoProducto: true }) }} variant="primary">
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button data-tip="Buscar Producto" onClick={() => { this.setState({ buscarProducto: true }) }} variant="info">
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <hr />
            <Row>
                <Table className="accordion" striped hover>
                    <thead>
                        <tr>
                            <th style={{ width: "100px" }}>CODIGO</th>
                            <th>DESCRIPCIÓN</th>
                            <th style={{ width: "80px" }}>CANT</th>
                            <th style={{ width: "80px" }}>MDO</th>
                            <th style={{ width: "80px" }}>TURNOS</th>
                            <th style={{ width: "200px" }}>PRE/UNT</th>
                            <th style={{ width: "200px" }}>PRECIO</th>
                            <th style={{ width: "150px" }}>ACCIONES</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.Cotizacion.products.map(producto => {
                            if (producto.product.exempt) {
                                ext += producto.price * producto.quantity
                            } else {
                                imp += producto.price * producto.quantity
                            }
                            return <FilaUtileServicios key={producto.product.id} producto={producto} edit={this.addProductos.bind(this)} remove={this.removeProductos.bind(this)} />
                        })}
                        <CalcularPagar transport={this.props.Cotizacion.transport} imp={this.props.Cotizacion.with_tax} exento={ext} imponible={imp} discount={this.props.Cotizacion.discount} tax={this.props.Cotizacion.client.taxpayer_type.tax} />
                    </tbody>
                </Table>
            </Row>
        </>
    }
    impuesto(e: React.MouseEvent<HTMLInputElement>) {
        this.setData({ with_tax: !this.props.Cotizacion.with_tax })
    }
    close() {
        this.setState({ nuevoProducto: false, buscarProducto: false, verSeguimientos: false, buscarCotizacion: false })
    }
    Prosesar() {
        if (this.props.Prosesar !== undefined) {
            return <Button data-tip="PROCESAR COTIZACIÓN" className="float-right ml-3" onClick={this.props.Prosesar} variant="success">
                <FontAwesomeIcon icon={faPrint} />
            </Button>
        }
    }
    setTrasing(data: { selected: Cotizacion }) {
        this.setData({ tracings: data.selected.tracings })
    }
    cargarCotizacion(data: Cotizacion) {
        let { discount, note, id, client } = this.props.Cotizacion
        this.KeyDescuento = this.KeyDescuento + data.id
        this.keyNota = this.keyNota + data.id
        this.keyTransport = this.keyTransport + data.id
        data.quotation_id = data.id
        data.id = id
        if (this.props.Cotizacion.client.id !== 0)
            data.client = client
        if (discount > 0)
            data.discount = discount
        if (note !== "")
            data.note = note
        let products = [...this.props.Cotizacion.products, ...data.products]
        data.products = []
        this.setData({ ...data })
        products.forEach(p => {
            this.addProductos(p)
        })
        this.close()
    }
    componentWillReceiveProps(p: Props) {
        if (p.Cotizacion.id !== this.props.Cotizacion.id) {
            this.KeyDescuento = this.KeyDescuento + p.Cotizacion.id
            this.keyNota = this.keyNota + p.Cotizacion.id
        }
    }
}