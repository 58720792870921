import { faHandSparkles, faPhone, faPortrait, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import { Card, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom";
import { Permisos } from "../../config/Permisos"
export class Selector extends React.Component {
    per = Permisos()
    render() {
        let { authFuncion } = this.per;
        return <Card>
            <Card.Body>
                <Row>
                    {authFuncion("reporte_basico") ?
                        <Col md="6" className="my-2">
                            <Card as={Link} to="/reportes/generales" className="border-left-primary shadow h-100 py-2 ">
                                <Card.Body>
                                    <Row className="no-gutters align-items-center">
                                        <Col className="mr-2 text-center">
                                            <FontAwesomeIcon size="10x" icon={faPrint} /><br />
                                            <h2 style={{ fontSize: 30 }} className="text-xs font-weight-bold text-primary text-uppercase mb-1">reportes generales</h2>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col> : <></>}
                    {authFuncion("reporte_callcenter") ?
                        <Col md="6" className="my-2">
                            <Card as={Link} to="/reportes/usuarios" className="border-left-primary shadow h-100 py-2 ">
                                <Card.Body>
                                    <Row className="no-gutters align-items-center">
                                        <Col className="mr-2 text-center">
                                            <FontAwesomeIcon size="10x" icon={faPortrait} /><br />
                                            <h2 style={{ fontSize: 30 }} className="text-xs font-weight-bold text-primary text-uppercase mb-1">ventas</h2>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col> : <></>}
                    {authFuncion("reporte_tecnico") ?
                        <Col md="6" className="my-2">
                            <Card as={Link} to="/reportes/tecnicos" className="border-left-primary shadow h-100 py-2 ">
                                <Card.Body>
                                    <Row className="no-gutters align-items-center">
                                        <Col className="mr-2 text-center">
                                            <FontAwesomeIcon size="10x" icon={faHandSparkles} /><br />
                                            <h2 style={{ fontSize: 30 }} className="text-xs font-weight-bold text-primary text-uppercase mb-1">tecnicos</h2>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col> : <></>}
                    {authFuncion("reporte_postventa") ?
                        <Col md="6" className="my-2">
                            <Card as={Link} to="/reportes/referidos" className="border-left-primary shadow h-100 py-2 ">
                                <Card.Body>
                                    <Row className="no-gutters align-items-center">
                                        <Col className="mr-2 text-center">
                                            <FontAwesomeIcon size="10x" icon={faPhone} /><br />
                                            <h2 style={{ fontSize: 30 }} className="text-xs font-weight-bold text-primary text-uppercase mb-1">REFERIDOS</h2>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col> : <></>}



                </Row>
            </Card.Body>
        </Card>;
    }
}