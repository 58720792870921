import * as firebase from 'firebase'

// const settings = {timestampsInSnapshots: true}

const config = {
  apiKey: "AIzaSyAJbW-jf-xmaYnUy04oo0bWi0SQLQ1-J0Q",
  authDomain: "telimpie-prueba.firebaseapp.com",
  databaseURL: "https://telimpie-prueba.firebaseio.com",
  projectId: "telimpie-prueba",
  storageBucket: "telimpie-prueba.appspot.com",
  messagingSenderId: "816598833610",
  appId: "1:816598833610:web:11b1c8c99f8d56b00e9733",
  measurementId: "G-2NHYTPDJ3Y"
} 
// const config = {
//   apiKey: "AIzaSyCv-4QpJfN6BSkAHTDUCO4sgrmp2PdEb0o",
//   authDomain: "sistema-citas-ee89f.firebaseapp.com",
//   projectId: "sistema-citas-ee89f",
//   storageBucket: "sistema-citas-ee89f.appspot.com",
//   messagingSenderId: "675684592993",
//   appId: "1:675684592993:web:d95cd3c8d61953777be920",
//   measurementId: "G-2JTJ5XF4VY"
// }
const app = firebase.initializeApp(config)
// firebase.firestore().settings(settings)
export default app.database()




/*
<!-- The core Firebase JS SDK is always required and must be listed first -->
<script src="https://www.gstatic.com/firebasejs/7.23.0/firebase-app.js"></script>

<!-- TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#available-libraries -->
<script src="https://www.gstatic.com/firebasejs/7.23.0/firebase-analytics.js"></script>

<script>
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyAJbW-jf-xmaYnUy04oo0bWi0SQLQ1-J0Q",
    authDomain: "telimpie-prueba.firebaseapp.com",
    databaseURL: "https://telimpie-prueba.firebaseio.com",
    projectId: "telimpie-prueba",
    storageBucket: "telimpie-prueba.appspot.com",
    messagingSenderId: "816598833610",
    appId: "1:816598833610:web:11b1c8c99f8d56b00e9733",
    measurementId: "G-2NHYTPDJ3Y"
  }
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig)
  firebase.analytics()
</script> */