import React from "react"
import { Card } from "react-bootstrap"
import { Cotizaciones } from "./Cotizaciones"
import { Servicios } from "./Servicios"
import { Permisos } from "../../../config/Permisos"
import SetMes from "../SelectorDeMes";
import { conUsers } from "../../../Model/User";
import { Storage } from "../../../Storage"
import moment from 'moment';
interface Props {
    tech: number
}
interface State {
    mont: number
    year: number
}
class Graficas extends React.Component<Props,State> {
    per = Permisos();
    con = conUsers();
    sent = 0;
    constructor(p: any) {
        super(p);
        this.state = {
            year: parseInt(moment().format("YYYY")),
            mont: parseInt(moment().format("MM"))
        }
    }
    render() {
        let { year, mont } = this.state;
        let {  tech } = this.props;
        return <Card style={{ width: "100%" }}>
            <Card.Header>
                <SetMes mont={mont} year={year} setData={this.setState.bind(this)} />
            </Card.Header>
            <Card.Body>
                {this.Cotizacion(mont, year, tech)}
                {this.Servicio(mont, year, tech)}
            </Card.Body>
        </Card>
    }
    Servicio(mont: number, year: number, user: number) {
        if (this.per.authFuncion("servicio"))
            return <Servicios mont={mont} year={year} user={user} />
        return <></>
    }
    Cotizacion(mont: number, year: number, user: number) {
        if (this.per.authFuncion("cotizacion"))
            return <Cotizaciones mont={mont} year={year} user={user} />
        return <></>
    }
}
Graficas.contextType = Storage;
export default Graficas;
