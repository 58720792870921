import React from 'react'
import { Modal, Row, Col, Card } from 'react-bootstrap'
import moment from 'moment'
import "moment/locale/es"
import { Storage } from '../../Storage'
import { Calendario } from '../../Utils/Calendario'
import Servicio, { Tecnico } from '../../Model/Sevicios'
import RowTecnico from "./NuevaCita/RowTecnico"
import { Permisos } from '../../config/Permisos'
import { manejadorColores } from '../../Utils/ManejadorColores'
moment.locale('es')
interface Props {
    open: boolean,
    close: () => void,
    select: Servicio
}
class CalendarioServicio extends React.Component<Props> {
    render() {
        let { dates, users, address } = this.props.select
        if (dates === undefined)
            return <></>
        return <Modal size="xl" centered animation show={this.props.open} onHide={this.props.close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>VER CALENDARIO Y TECNICOS</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ height: "600px" }}>
                    <Col md="9">
                        <Calendario start={dates[0].date} celdas={dates.map(d => {
                            if (Permisos().authFuncion("tecnico")) {
                                let m = manejadorColores()
                                let color = (m.paso(d) && d.status === 1) ? m.getColor(4) : m.getColor(d.status)
                                return { ...d, color }
                            } else {
                                return { ...d, color: "#28a745" }
                            }
                        })} servicio={this.props.select} />
                    </Col>
                    <Col md="3">
                        <Card.Header className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                            DIRECCION
                            </Card.Header>
                        <Row>
                            {address}
                        </Row>
                        <Card.Header className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                            LISTA DE TECNICOS
                            </Card.Header>
                        {users.map(u => {
                            return <RowTecnico add={(t: Tecnico) => { }} tenico={u} />
                        })}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    }
}
CalendarioServicio.contextType = Storage
export default CalendarioServicio