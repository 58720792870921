import { ResponsivePie } from "@nivo/pie";
import React from "react";
interface color {
    id: string,
    label: string,
    value: number,
    color: string
}

interface Props {
    data: color[]
}

export default class Torta extends React.Component<Props> {

    render() {
        let { data } = this.props;
        return <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={(e) => {
                switch (e.label) {
                    case "CANCELADO":
                        return "#DC3545";
                    case "ESPERA":
                        return "#FFC107";
                    case "Activa":
                        return "#007bff";
                    case "PROCESADO":
                        return "#28A745";
                }
                return "white";
            }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor="#333333"
            radialLabelsLinkColor={{ from: 'color' }}
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#333333"
            legends={[{
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }]}
        />
    }
}