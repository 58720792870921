import Axios from "axios"
import { toast } from "react-toastify"
import app from "../config/firebase"
export var don = "https://sistema.telimpie.com";
// export var don = "https://api.telimpie.com"
// export var don = "";
// export var don = "http://telimpieapi.test"
export class Minimo {
    sent = 0;
    tabla: string = '';
    coleccion: string = '';
    lista: string = '';
    dominio = don;
    constructor() {
        setTimeout(() => this.setSent(), 100)
    }
    async setSent() {
        const snat = await app.ref(this.tabla + '/' + this.coleccion).once('value')
        const data = snat.val()
        if (data !== undefined)
            this.sent = data
    }
    async setCargar(setData: (d: any) => void) {
        await this.setLista(setData)
        this.updateLista(setData)
    }
    updateLista(setData: (d: any) => void) {
        app.ref(this.tabla + '/' + this.coleccion)
            .on('value', snap => {
                if (this.sent < snap.val()) {
                    this.setLista(setData)
                    this.sent = snap.val()
                }
            })
    }
    async setLista(setData: (d: any) => void) {
        try {
            var rest = await Axios.get(this.dominio + "/api/" + this.coleccion)
            setData(rest.data.data);
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async update(data: any, add?: (t: any) => void) {
        try {
            let rest = await Axios({ method: 'put', url: this.dominio + "/api/" + this.coleccion + '/' + data.id, data })
            if (add !== undefined) {
                add(rest.data.data)
            }
            toast(rest.data.message, { type: toast.TYPE.SUCCESS })
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async select(id: number, add: (t: any) => void) {
        try {
            let rest = await Axios.get(this.dominio + "/api/" + this.coleccion + "/" + id)
            if (add !== undefined) {
                add(rest.data.data)
            }
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
}