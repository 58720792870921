import React from "react"
import { Col, Image, Row, } from "react-bootstrap"
import { Tecnico } from "../../../Model/Sevicios"
interface Props {
    tenico: Tecnico
    add: (t: Tecnico) => void
}
export default class RowTecnico extends React.Component<Props>{
    constructor(p: Props) {
        super(p)
        this.state = { select: false }
    }
    render() {
        return <Row className="m-1" style={{ backgroundColor: this.getBackground(), color: this.getColor() }} onDoubleClick={() => { this.props.add(this.props.tenico) }}>
            <div style={{ width: "60px" }}>
                <Image fluid src={this.props.tenico.image} />
            </div>
            <Col>
                {this.props.tenico.name}
            </Col>
        </Row>
    }

    getBackground() {
        switch (this.props.tenico.state) {
            case 2:
                return "#bf7bad"
            case 3:
                return "#dc3545"
            default:
                return ""
        }
    }
    getColor() {
        switch (this.props.tenico.state) {
            case 2:
                return "#fff"
            case 3:
                return "#fff"
            default:
                return ""
        }
    }
}