import React from 'react'
import { Storage } from '../../Storage'
import { Button, Row, InputGroup, FormControl, Col, FormCheck } from 'react-bootstrap'
import Fila from '../Productos/FilaProductos'
import Productos, { conProductos } from '../../Model/Productos'
import FormProductos from '../Productos/FormProductos'
import { CategoriaSelector } from '../Productos/CategoriaSelector'
import { PermisosType } from '../../config/Permisos'
import Tabla from '../../Utils/Table'

class ListProducto extends Tabla {
    bucarLabel = ["description", "code"]
    permiso: PermisosType = "producto";
    con = conProductos();
    categotia = 0;
    eliminados = false;
    componentWillMount() {
        var yo = this;
        this.context.set({ title: "LISTA DE PRODUCTOS" })
        this.con.setCargar((itens: Array<any>) => {
            yo.setState({ itens })
        });
    }
    filtros(itens: Productos[]) {
        if (this.eliminados)
            return itens;
        if (this.categotia !== 0)
            itens = itens.filter(p => {
                if (p.category_id === this.categotia) return true;
                return false;
            })
        return itens.filter(c => {
            if (c.status === 1) return true;
            return false;
        })
    }
    Row(p: Productos) {
        return <Fila update={() => { this.con.setLista(this.setItens.bind(this)) }} key={"Productos" + p.id + p.status} producto={p} />
    }
    Header() {
        return <tr>
            <th onClick={() => { this.setOrden("code") }}>CÓDIGO{this.flechas("code")}</th>
            <th onClick={() => { this.setOrden("description") }}>DESCRIPCIÓN{this.flechas("description")}</th>
            <th onClick={() => { this.setOrden("cost") }}>COSTO{this.flechas("cost")}</th>
            <th onClick={() => { this.setOrden("quantity") }}>TURNOS{this.flechas("quantity")}</th>
            <th>ESTATUS</th>
            <th>ACCIONES</th>
        </tr>
    }
    busquedaMostrar() {
        return <Row>
            <Col as="h6" md="8" className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                {this.state.open ? <FormProductos add={() => { }} Producto={null} close={this.close.bind(this)} title={"nuevo producto"} /> : <></>}
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            BUSCAR PRODUCTO
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl placeholder="DESCRIPCIÓN O CÓDIGO DEL PRODUCTO" onKeyUp={this.setBuscar} />
                    <CategoriaSelector defaultValue={0} change={this.changeCategoria.bind(this)} />
                </InputGroup>
            </Col>
            <Col xs='3' className='mr-auto'>
                <FormCheck
                    id="imp"
                    type="switch"
                    label="MOSTRAR ELIMINADOS"
                    checked={this.eliminados}
                    onClick={() => { this.eliminados = !this.eliminados; this.forceUpdate() }}
                />
            </Col>
            <Button onClick={() => { this.setState({ open: true }) }} variant="primary">NUEVO</Button>
        </Row>
    }
    close() {
        this.setState({ open: false })
    }
    changeCategoria(categoria: number) {
        this.categotia = categoria;
        this.forceUpdate();
    }
}
ListProducto.contextType = Storage
export default ListProducto
// 1.- 264126 (Tenga cuidado con el final)

// 2.- 332336

// 3.- 332287

// 4.- 332255

// 5.- 332254

// 6.- 332179

// 7.- 332094

// 8.- 332049

// 9.- 331965

// 10.- 331160

// 11.- 330921

// 12.- 329938

// 13.- 330072

// 14.- 329828

// 15.- 329845

// 16.- 329579

// 17.- 329567

// 18.- 329556

// 19.- 329242

// 20.- 329221

// 21.- 328962

// 22.- 328870