import React from "react";
import { NuevaContribuyente } from "./Nuevo";
import { FilaContribuyente } from "./Fila";
import { conContribuyente, taxpayer_type } from "../../../Model/Contribuyente";
import { PermisosType } from "../../../config/Permisos";
import Tabla from "../../../Utils/Table";
import { Storage } from "../../../Storage";
class Contribuyentes extends Tabla {
    bucarLabel = ["description", "tax"];
    permiso: PermisosType = "tipocontribuyente";
    con = conContribuyente();
    componentWillMount() {
        this.context.set({ title: "configuracion de Tipos de contribuyente" })
        this.con.setCargar(this.setItens.bind(this));
    }
    Row(p: taxpayer_type) {
        return <FilaContribuyente update={() => { this.con.setLista(this.setItens.bind(this)) }} key={"con" + p.id + p.status} taxpayer_type={p} />
    }
    Header() {
        return <tr>
            <th onClick={() => { this.setOrden("description") }}>DESCRIPCIÓN{this.flechas("description")}</th>
            <th style={{ width: 100 }} onClick={() => { this.setOrden("tax") }}>IMPUESTO{this.flechas("tax")}</th>
            <th style={{ width: 100 }}>ESTATUS</th>
            <th style={{ width: 150 }}>ACCIONES</th>
        </tr>
    }
    busquedaMostrar() {
        return <NuevaContribuyente actulalizar={() => { this.con.setLista(this.setItens.bind(this)) }} />;
    }
}
Contribuyentes.contextType = Storage
export default Contribuyentes