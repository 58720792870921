import React, { CSSProperties } from "react"
import { FormControl } from "react-bootstrap"

interface Props {
    id?: string
    setData: (data: any) => void,
    update: string
    type?: "number" | "password"
    minValue?: number
    value: string | number
    step?: number | string
    as?: String,
    style?: CSSProperties
}

export default class SetText extends React.Component<Props> {

    render() {
        let { value, type, step, as, id, style } = this.props
        let p: any = { type, style }
        if (type === "number")
            p = { ...p, step, minLength: 0, min: 0 }
        if (as === "textarea")
            p = { ...p, rows: 5 }
        return <FormControl {...p} id={id} as={as} onBlur={this.minValue.bind(this)} onChange={this.setChangeString.bind(this)} defaultValue={value} />
    }
    minValue(e: React.FocusEvent<HTMLInputElement>) {
        let value = e.currentTarget.value as string
        let { update, setData, type, minValue } = this.props
        let r: string | number
        if (type === "number") {
            r = (value === "") ? 0 : parseFloat(value)
            if (r === 0)
                if (minValue !== undefined)
                    r = minValue
        } else {
            r = value.toUpperCase()
        }
        e.currentTarget.value = r + ""
        if (update !== undefined)
            setData({ [update]: r })
    }
    setChangeString(e: React.ChangeEvent<HTMLInputElement>) {
        let { update, setData, type, id } = this.props
        let { value } = e.currentTarget
        let r: string | number
        if (type === "number") {
            r = (value === "") ? 0 : parseFloat(value)
        } else {
            r = value.toUpperCase()
        }
        if (update !== undefined)
            setData({ [update]: r })
        if (id !== undefined)
            setTimeout(() => {
                if (id !== undefined) {
                    let input: any = document.getElementById(id)
                    input.focus()
                    input.value = r
                }
            }, 5)
    }
}