import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import "../css/bootstrap.min.css"
import "../css/sb-admin-2.min.css"
import "../css/style.css"
import { ToastContainer } from 'react-toastify'
import Menu from '../Template/Menu'
import Navbar from '../Template/Navbar'
import Footer from '../Template/Footer'
import { Storage } from '../Storage'
import Clientes from '../app/Clientes'
import Users from '../app/Users'
import Productos from '../app/Productos'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import NuevaCotizacion from '../app/Cotizacion/NuevaCotizacion'
import NuevaCita from '../app/Servicios/NuevaCita'
import ListCotizaciones from '../app/Cotizacion'
import ListServicios from '../app/Servicios'
import { Config } from '../app/Configuracion'
import ListaReferenciados from '../app/Referenciados'
import ListValoracion from '../app/Valoracion'
import Valorar from '../app/Valoracion/Valorar'
import { Reportes } from '../app/Reportes' 
import { Row, Spinner } from 'react-bootstrap'
import { Dashboard } from '../app/Dashboard'
import MisServicios from '../app/Tecnicos/MisServicios'
import ReactTooltip from "react-tooltip"
import MisCitas from '../app/Tecnicos/MisCitas'
import CalendarioServicios from '../app/Servicios/CalendarioServicios'
class Rutas extends React.Component {
    render() {
        let height = window.innerHeight - 170
        let { data } = this.context
        return <Router>
            <ReactTooltip place="top" effect="solid" className="" />
            <Menu />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navbar />
                    <div style={{ minHeight: height }} className="container-fluid">
                        {(data.Permisos.length === 0) ?
                            <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                                <Spinner style={{ width: "25rem", height: "25rem" }} variant="primary" animation="border" />
                                <h1 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                                    Cargando....
                            </h1>
                            </Row> :
                            <Switch>
                                <Route path="/clientes" component={Clientes} />
                                <Route path="/referidos" component={ListaReferenciados} />
                                <Route path="/usuarios" component={Users} />
                                <Route path="/productos" component={Productos} />
                                <Route path="/cotizaciones" component={ListCotizaciones} />
                                <Route path="/cotizacion/nueva" component={NuevaCotizacion} />
                                <Route path="/cotizacion/:id" component={NuevaCotizacion} />
                                <Route path="/servicios/calendario" component={CalendarioServicios} />
                                <Route path="/servicios" component={ListServicios} />
                                <Route path="/servicio/nueva" component={NuevaCita} />
                                <Route path="/servicio/:id" component={NuevaCita} />
                                <Route path="/configuracion" component={Config} />
                                <Route path="/valoraciones" component={ListValoracion} />
                                <Route path="/valorar/:id" component={Valorar} />
                                <Route path="/reportes" component={Reportes} />
                                <Route path="/misServicios" component={MisServicios} />
                                <Route path="/misCitas" component={MisCitas} />
                                <Route component={Dashboard} />
                            </Switch>}
                    </div>
                    <ToastContainer />
                    <Footer />
                </div>
            </div>
        </Router>
    }
}
Rutas.contextType = Storage
export default Rutas
