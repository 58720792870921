import { Mensajes } from "../Conexiones/Mensajes"
import { fechas } from "./Generales"
export class bitacora extends Mensajes {
    tabla = "quotations"
    ruta: string = "tracing"
}
var conB: bitacora[] = []
export function conBitacora(id: number) {
    if (conB[id] === undefined)
        conB[id] = new bitacora(id)
    conB[id].escuchar = true;
    return conB[id]
}
export interface Seguimiento extends fechas {
    id: number,
    user_id: number,
    msn: string
}