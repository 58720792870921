import React from "react"
import { Row, Table } from "react-bootstrap"
import Leyendas from "../../Utils/Leyendas"
export default class LeyendaCitas extends Leyendas {
    Body() {
        return <Row>
            <Table>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#007bff" }}></td>
                    <td>turnos que tiene asigado un trabajo</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#FFC107" }}></td>
                    <td>turnos en el que inicio pero no lo cerro</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#28A745" }}></td>
                    <td>turnos con asistencia correcta</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#DC3545" }}></td>
                    <td>turnos con inasistencia</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#BF7BAD" }}></td>
                    <td>turnos del servicio seleccionado</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#FFFF00" }}></td>
                    <td>turnos del servicio seleccionado en el que inicio pero no lo cerro</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#00FF00" }}></td>
                    <td>turnos del servicio seleccionado con asistencia correcta</td>
                </tr>
                <tr>
                    <td style={{ width: 40, padding: "3 3 3 3", backgroundColor: "#FF0000" }}></td>
                    <td>turnos del servicio seleccionado con inasistencia</td>
                </tr>
            </Table>
        </Row>
    }
}