
import Axios from "axios"
import { Status } from "../Model/Generales"
import { toast } from "react-toastify"
import { Minimo } from "./Minimo"
export class General extends Minimo {
    async create(send: any, add?: (t: any) => void) {
        try {
            let rest = await Axios({ method: 'post', url: this.dominio + "/api/" + this.coleccion, data: send })
            if (add !== undefined) {
                add(rest.data.data)
            }
            toast(rest.data.message, { type: toast.TYPE.SUCCESS })
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
        }
    }
    async stateChange(data: Status, add?: () => void) {
        try {
            let rest = await Axios({ method: 'put', url: this.dominio + "/api/" + this.coleccion + '/change/status', data })
            if (add !== undefined) {
                add()
            }
            toast(rest.data.message, { type: toast.TYPE.SUCCESS })
        } catch (error: any) {
            if (error.response !== undefined)
                toast(error.response.data.message, { type: toast.TYPE.ERROR })
            if (add !== undefined) {
                add()
            }
        }
    }
}