import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Storage } from '../../Storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faEdit, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons'
import Referido, { conReferidos } from '../../Model/Referidos'
import FormCliente from '../Clientes/FormCliente'
import Comentario from './Comentario'
import ReactTooltip from 'react-tooltip';
import moment from 'moment'
interface Props {
    Referido: Referido
    update?: () => void
}
interface State {
    registrar: boolean
    comentario: boolean
}
class FilaReferido extends React.Component<Props, State> {
    con = conReferidos()
    constructor(p: Props) {
        super(p)
        this.state = { registrar: false, comentario: false }
    }
    render() {
        let { id, name, phone, client, note,created_at } = this.props.Referido
        let { registrar, comentario } = this.state
        return <tr>
            {registrar ? <FormCliente close={this.close.bind(this)} cliente={null} title={"REGISTRAR A " + name} referido={this.props.Referido} /> : <></>}
            {comentario ? <Comentario close={this.close.bind(this)} id={id} /> : <></>}
            <th>{moment(created_at).format("DD-MM-YYYY")}</th>
            <th>{(client === null || client === undefined) ? "N/A" : client}</th>
            <th>{name}</th>
            <th>{phone}</th>
            <th>{note}</th>
            <th style={{ width: "100px" }}>{this.getStatus()}</th>
            <th style={{ width: "150px" }}>
                <ReactTooltip />
                <ButtonGroup>
                    <Button data-tip="comentar" onClick={() => { this.setState({ comentario: !comentario }) }} variant="info"><FontAwesomeIcon icon={faComment} /></Button>
                    {this.getBotton()}
                </ButtonGroup>
            </th>
        </tr >
    }

    getStatus() {
        switch (this.props.Referido.status) {
            case 0:
                return <span className="bg-danger text-white p-1 d-inline-block">eliminado</span>
            case 1:
                return <span className="bg-warning text-white p-1 d-inline-block">ESPERA</span>
            case 2:
                return <span className="bg-info text-white p-1 d-inline-block">CONTACTADO</span>
            case 3:
                return <span className="bg-success text-white p-1 d-inline-block">registrado</span>
        }
    }
    activar() {
        let { id } = this.props.Referido
        this.con.stateChange({ id, status: 2 }, this.props.update)
    }
    eliminar() {
        let { id } = this.props.Referido
        this.con.stateChange({ id, status: 0 }, this.props.update)
    }
    getBotton() {
        let { status } = this.props.Referido
        return <>
            {(status < 3) ? <Button data-tip="REGISTRAR" onClick={() => { this.setState({ registrar: !this.state.registrar }) }} variant="success"><FontAwesomeIcon icon={faEdit} /></Button> : <></>}
            {(status === 1) ? <Button data-tip="LLAMADO" onClick={this.activar.bind(this)} variant="secondary"><FontAwesomeIcon icon={faPhone} /></Button> : <></>}
            {(3 > status && status > 0) ? <Button data-tip="eliminar" onClick={this.eliminar.bind(this)} variant="danger"><FontAwesomeIcon icon={faTimes} /></Button> : <></>}
        </>
    }
    close() {
        this.setState({ registrar: false, comentario: false })
    }
}
FilaReferido.contextType = Storage
export default FilaReferido