import Axios from "axios"
import React from "react"
import { Card, Col, Row } from "react-bootstrap";
import { don } from "../../../Conexiones/Minimo";
import Torta from "../../../Utils/Torta";
import Linea from "../../../Utils/Linea";
import { getDias, getMoth } from "../../../Utils/ArrayFun";
interface state {
    pie: any[]
    line: any[]
}
interface Props {
    year: number
    mont: number
    user: number
}
export class Cotizaciones extends React.Component<Props, state> {
    constructor(p: Props) {
        super(p);
        this.state = {
            pie: [],
            line: []
        }
        this.send()
    }
    send() {
        this.getRank();
        this.getTotal();
    }
    async getRank() {
        try {
            let { year, mont, user } = this.props;
            let repot = "year";
            let get = year+"";
            if (mont !== 0) {
                repot = "month";
                get = year + "/" + mont
            }
            let rest = await Axios.get(don + "/api/graph/quotation/rank/user/" + repot + "/" + get + "/" + user);
            this.setState({ pie: rest.data.data });
        } catch (e) {
            console.log(e);
        }
    }
    async getTotal() {
        try {
            let { year, mont, user } = this.props;
            let repot = "year";
            let get = year+"";
            if (mont !== 0) {
                repot = "month";
                get = year + "/" + mont
            }
            let rest = await Axios.get(don + "/api/graph/quotation/user/" + repot + "/" + get + "/" + user);
            this.setState({ line: rest.data.data });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        let { pie, line } = this.state;
        return <Card>
            <Card.Header>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    Cotizaciones
                </h6>
            </Card.Header>
            <Row>
                <Col md="5" style={{ height: 300 }}>
                    <Torta data={pie.map(t => {
                        let label = "", color = "";
                        switch (t.RANK) {
                            case 0: label = "CANCELADO"; color = "#FF0000"; break;
                            case 1: label = "ESPERA"; color = "#FFFF00"; break;
                            case 2: label = "PROCESADO"; color = "#00FF00"; break;
                        }
                        return { id: label, label, value: t.CANT, color }
                    })} />
                </Col>
                <Col md="7" style={{ height: 300 }}>
                    {(line.length !== 0) ?
                        <Linea title="COTIZACIONES" data={this.getData()} /> : <></>}
                </Col>
            </Row>
        </Card>
    }
    componentDidUpdate(p: Props) {
        let { year, mont, user } = this.props;
        if (p.year !== year || p.mont !== mont || p.user !== user){
            this.setState({ pie: [], line: [] });
            this.send()
        }
    }
    getData() {
        let { year, mont } = this.props;
        if (mont !== 0) {
            return getDias(year, mont).map(d => {
                this.state.line.forEach(p => {
                    if (p.Day === d.x)
                        d.y = p.Total;
                })
                return d;
            })
        }else{
            return getMoth().map(m=>{
                this.state.line.forEach(p => {
                    if (p.Month === m.x)
                        m.y = p.Total;
                })
                return m;
            })
        }
    }
}