import React from 'react'
import { Card, Container } from 'react-bootstrap'
import Servicio from '../../Model/Sevicios'
import RowTecnico from './NuevaCita/RowTecnico'


export default class Mostrar extends React.Component<{ servicio: Servicio }> {
    render() {
        let { client, products, users, address } = this.props.servicio
        return <>
            <Card>
                <Card.Header>
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">datos del cliente</h6>
                </Card.Header>
                <Container className="mh-3">
                    <b>Nombre: </b>
                    <br />{client.name}<br />
                    <b>PERSONA DE CONTACTO: </b>
                    <br />{client.contact_person}<br />
                    <b>CORREO ELECTRONICO: </b>
                    <br />{client.email}<br />
                    <b>TELEFONO: </b>{client.phone}<br />
                    <b>direccion del servicio:</b><br />
                    <p>{address}</p>
                </Container>
            </Card>
            <br />
            <Card>
                <Card.Header className="mh-3">
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">Servivicos Contratados</h6>
                </Card.Header>
                <Container>
                    {products.map(p => {
                        return <p>{p.product.description} <b>x{p.quantity}</b></p>
                    })}
                </Container>
            </Card>
            <br />
            <Card>
                <Card.Header>
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">tecnicos enviados</h6>
                </Card.Header>
                <Container className="mb-3">
                    {users.map(p => {
                        return <RowTecnico add={(t: any) => { }} tenico={p} />
                    })}
                </Container>
            </Card>
        </>
    }
}