
import React from 'react'
import Cotizacion, { conCotizacion, CotizacionDefault } from '../../Model/Cotizacion'
import { ButtonGroup, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faBook, faTimes, faPrint } from '@fortawesome/free-solid-svg-icons'
import { numberFormat, zfill } from '../../Utils/Formats'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PopCliente from '../Clientes/PopCliente'
import Seguimientos from './Seguimientos'
import DetalleCotizacion from './DetalleCotizacion'
import ModalPrint from '../../Utils/ModalPrint'
import ReactTooltip from 'react-tooltip'
import Confimacion from '../../Utils/Confimacion'

moment.locale('es')
interface Props {
    cotizacion: Cotizacion
    update?: () => void
}
interface State extends Cotizacion {
    verSeguimientos: boolean
    verDetalle: boolean
    print: boolean
    eliminar: boolean
}
export default class FilaCotizacion extends React.Component<Props, State>{
    con = conCotizacion()
    constructor(p: Props) {
        super(p)
        this.state = {
            ...p.cotizacion,
            verSeguimientos: false,
            verDetalle: false,
            print: false,
            eliminar: false
        }
    }
    async set() {
        if (this.state.products === undefined) {
            this.setState(CotizacionDefault)
            this.setState(this.props.cotizacion)
            await this.con.select(this.props.cotizacion.id, (selected: Cotizacion) => { this.setState({ ...selected }) });
        }
    }
    render() {
        let money = localStorage.getItem("money");
        let cot = this.props.cotizacion
        let { verSeguimientos, verDetalle, print, eliminar } = this.state
        return <tr onMouseEnter={this.set.bind(this)} >
            {eliminar ? <Confimacion msn={"¿ESTA SEGURO QUE ELIMINARA LA COTIZACION " + zfill(this.state.id) + "?"} accion={this.delete.bind(this)} close={this.close.bind(this)} /> : <></>}
            {verSeguimientos ? <Seguimientos open={verSeguimientos} id={cot.id} close={this.close.bind(this)} /> : <></>}
            {verDetalle ? <DetalleCotizacion open={verDetalle} select={this.state} close={this.close.bind(this)} /> : <></>}
            {print ? <ModalPrint title={"Cotizacion N°:" + zfill(cot.id)} url={"pdf/quotation/store/" + cot.id} close={this.close.bind(this)} /> : <></>}
            <th style={{ width: "115px" }}>
                <ReactTooltip />
                <Button data-tip="VER DETALLEA" variant="link" onClick={this.detalles.bind(this)}>
                    {zfill(cot.id)}
                </Button>
            </th>
            <th style={{ width: "150px" }}>{moment(cot.created_at).format("DD-MM-YYYY")}</th>
            <th><PopCliente name={cot.name} cliente={this.state.client} /></th>
            <th style={{ width: "200px", textAlign: 'right' }}>{money} {this.calPagar()}</th>
            <th style={{ width: "100px" }}>{this.getStatus()}</th>
            <th style={{ width: "128px" }}>
                <ButtonGroup>
                    <Button data-tip="VER BITACORA" variant="secondary" onClick={() => { this.Seguimiento() }}>
                        <FontAwesomeIcon icon={faBook} />
                    </Button>
                    <Button data-tip="IMPRIMIR COTIZACION" variant="dark" onClick={() => { this.setState({ print: true }) }}>
                        <FontAwesomeIcon icon={faPrint} />
                    </Button>
                    {this.getBotonDelete()}
                </ButtonGroup>

            </th>
        </tr >
    }
    getBotonDelete() {
        let cot = this.props.cotizacion
        if (cot.status === 1)
            return <>
                <Button data-tip="EDITAR COTIZACION" variant="info" to={"/cotizacion/" + cot.id} as={Link} >
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button data-tip="CANCELAR COTIZACION" variant="danger" onClick={() => { this.setState({ eliminar: true }) }}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </>
        return <></>
    }
    getStatus() {
        switch (this.props.cotizacion.status) {
            case 0:
                return <span className="bg-danger text-white p-1 d-inline-block">Cancelada</span>
            case 1:
                return <span className="bg-warning text-black p-1 d-inline-block">Espera</span>
            case 2:
                return <span className="bg-success text-white p-1 d-inline-block">Procesada</span>
        }
    }
    calPagar() {
        let { tax, discount, with_tax, taxable, exempt, transport } = this.props.cotizacion
        let impuesto = (with_tax) ? (tax / 100) : 0
        let descuento = 1 - (discount / 100)
        return numberFormat((taxable + exempt) * descuento + taxable * descuento * impuesto + transport)
    }
    Seguimiento() {
        this.setState({ verSeguimientos: true })
    }
    detalles() {
        this.setState({ verDetalle: true })
    }
    delete() {
        let cot = this.props.cotizacion
        this.con.stateChange({ id: cot.id, status: 0 }, this.props.update)
    }
    close() {
        this.setState({
            verSeguimientos: false,
            verDetalle: false,
            print: false,
            eliminar: false
        })
    }
}