import { Datum } from "@nivo/line"

interface proto {
    label?: string,
    isCompare?: (a: any, b: any) => boolean
}
interface compareElement extends proto {
    a: any
    b: any
}
interface compareArray extends proto {
    a: Array<any>,
    b: Array<any>,
}
interface removeArray extends proto {
    a: Array<any>,
    b: any
}
interface notRepitArray extends proto {
    a: Array<Array<any>>
}
function comprare(com: compareElement) {
    let { a, b, label, isCompare } = com
    if (label !== undefined) {
        return (a[label] === b[label])
    } else if (isCompare !== undefined) {
        return isCompare(a, b)
    } else {
        return false
    }
}
export function inertJoin(com: compareArray) {
    let { a, b, label, isCompare } = com
    let c: Array<any> = []
    a.forEach(d => {
        let g = true
        b.forEach(e => {
            if (comprare({ a: d, b: e, label, isCompare }))
                c.forEach(f => {
                    if (comprare({ a: d, b: f, label, isCompare }))
                        g = false
                })
        })
        if (g)
            c.push(d)
    })
    return c
}
export function removeElement(com: removeArray) {
    let { a, b, label, isCompare } = com
    let c = a.filter(c => {
        return !comprare({ a: c, b, label, isCompare })
    })
    return c
}
export function ifArray(com: removeArray) {
    let { a, b, label, isCompare } = com
    let d = false
    a.forEach(c => {
        if (comprare({ a: c, b, label, isCompare }))
            d = true
    })
    return d
}
export function UpdateElement(com: removeArray) {
    let { a, b, label, isCompare } = com
    return a.map(c => {
        if (comprare({ a: c, b, label, isCompare })) {
            return b
        } else {
            return c
        }
    })
}
export function addUpdateArray(com: removeArray) {
    let { a } = com
    if (ifArray(com))
        a = UpdateElement(com)
    else
        a.push(com.b)
    return a
}
export function noRepit(com: notRepitArray) {
    let { a, label, isCompare } = com
    let ret: Array<any> = []
    a.forEach(arr => {
        arr.forEach(iten => {
            let n = true
            ret.forEach(b => {
                if (comprare({ a: iten, b, label, isCompare }))
                    n = false
            })
            if (n)
                ret.push(iten)
        })
    })
    return ret
}
export function getDias(year: number, mont: number) {
    let date = new Date(year, mont - 1, 1);
    let result: Datum[] = [];
    while (date.getMonth() === mont - 1) {
        result.push({
            y: 0,
            x: date.getDate()
        });
        date.setDate(date.getDate() + 1);
    }
    return result;
}
export function getMoth() {
    let year = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let result: Datum[] = [];
    result = year.map(m => { return { y: 0, x: m } });
    return result;
}