import React from 'react'
import { Button, Card, ButtonGroup } from 'react-bootstrap'
import User, { conUsers } from '../../Model/User'
import { faCheck, faEdit, faTimes, faUserLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserEdit from './UserEdit'
import Clave from './Clave'
import ReactTooltip from 'react-tooltip'
interface Props {
    user: User
    update?: () => void
}
interface State {
    clave: boolean
    edit: boolean
}
export default class UserCard extends React.Component<Props, State> {
    user: User
    con = conUsers()
    constructor(p: Props) {
        super(p)
        this.user = p.user
        this.state = { clave: false, edit: false }
    }
    render() {
        let { clave, edit } = this.state
        return <Card style={{ width: '15.66666666666%', marginRight: "1%", marginTop: "1%" }}>
            {edit ? <UserEdit user={this.user} open={edit} close={this.close.bind(this)} /> : <></>}
            {clave ? <Clave user={this.user} open={clave} close={this.close.bind(this)} /> : <></>}
            <Card.Img variant="top" src={this.user.image} />
            <Card.Body>
                <Card.Title>{this.user.name}</Card.Title>
                <Card.Text>
                    <strong>Correo: </strong>{this.user.email}<br />
                    <strong>Rol: </strong>{this.user.roles[0].name}
                </Card.Text>
                <ReactTooltip />
                <ButtonGroup style={{ bottom: 0 }} className="btn-block">
                    <Button onClick={() => { this.setState({ edit: true }) }} variant="primary"><FontAwesomeIcon icon={faEdit} /></Button>
                    <Button onClick={() => { this.setState({ clave: true }) }} variant="warning"><FontAwesomeIcon icon={faUserLock} /></Button>
                    {this.getBotton()}
                </ButtonGroup>
            </Card.Body>
        </Card>
    }

    getBotton() {
        if (this.user.status === 1)
            return <Button onClick={this.eliminar.bind(this)} variant="danger"><FontAwesomeIcon icon={faTimes} /></Button>
        else
            return <Button onClick={this.activar.bind(this)} variant="success"><FontAwesomeIcon icon={faCheck} /></Button>
    }
    close() {
        this.setState({ clave: false, edit: false })
    }
    eliminar() {
        this.con.stateChange({ id: this.user.id, status: 0 }, this.props.update)
    }
    activar() {
        this.con.stateChange({ id: this.user.id, status: 1 }, this.props.update)
    }

}