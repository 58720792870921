import React from 'react'
import { Table, Modal, Row } from 'react-bootstrap'
import moment from 'moment'
import "moment/locale/es"
import { Storage } from '../../Storage'
import CalcularPagar from '../../Utils/CalcularPagar'
import { DataOperacion } from '../../Model/Generales'
moment.locale('es')
interface Props {
    open: boolean,
    close: () => void,
    select: DataOperacion,
}
class DetalleCotizacion extends React.Component<Props> {
    render() {
        let { products, with_tax, discount, tax, taxable, exempt, transport } = this.props.select;
        let money = localStorage.getItem("money");
        if (products === undefined)
            return <></>
        return <Modal size="xl" centered animation show={true} onHide={this.props.close}>
            <Modal.Header closeButton>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>VER DETALLES</b></span>
                </h6>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ width: "100px" }}>COD</th>
                                <th>DESCRIPCION</th>
                                <th style={{ width: "40px" ,textAlign: "right" }}>CANT</th>
                                <th style={{ width: "40px" ,textAlign: "right" }}>MDO</th>
                                <th style={{ width: "40px" ,textAlign: "right" }}>TURNOS</th>
                                <th style={{ width: "120px",textAlign: "right"  }}>PRE/UNT {money}</th>
                                <th style={{ width: "150px" ,textAlign: "right" }}>PRECIO {money}</th>
                                <th>Nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((s) => {
                                return <tr>
                                    <th style={{ width: "100px" }}>{s.product.code}</th>
                                    <th>{s.product.description}</th>
                                    <th style={{ width: "40px" ,textAlign: "right" }}>{s.quantity}</th>
                                    <th style={{ width: "40px" ,textAlign: "right" }}>{s.techs}</th>
                                    <th style={{ width: "40px" ,textAlign: "right" }}>{s.quantity_turns}</th>
                                    <th style={{ width: "120px", textAlign: "right" }}>{s.price}</th>
                                    <th style={{ width: "150px", textAlign: "right" }}>{s.price * s.quantity}</th>
                                    <th>{s.comment}</th>
                                </tr>
                            })} 
                            <CalcularPagar transport={transport} imp={with_tax} exento={exempt} imponible={taxable} discount={discount} tax={tax} />
                        </tbody>
                    </Table>
                </Row>
            </Modal.Body>
        </Modal>
    }
}
DetalleCotizacion.contextType = Storage
export default DetalleCotizacion