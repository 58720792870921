import React from "react"
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import SetText from "./setTex"
import { Col, Button, ButtonGroup, Card, InputGroup } from "react-bootstrap"
import "moment/locale/es"
import Servicio, { marcado } from "../Model/Sevicios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestion } from "@fortawesome/free-solid-svg-icons"
moment.locale('es')
const localizer = momentLocalizer(moment)
export interface celda extends marcado {
    color: string,
}
interface s extends Servicio {
    sugeridos?: number
}
interface Props {
    celdas: Array<celda>
    servicio?: s
    setData?: (d: any) => void
    selectDate?: (e: marcado) => void
    start?: string
    leyenda?: () => void
}
interface State {
    celdas: Array<celda>
}
export class Calendario extends React.Component<Props, State> {
    can = 0
    constructor(p: Props) {
        super(p)
        this.state = { celdas: p.celdas }
    }
    goToBack(toolbar: any) {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1)
        toolbar.onNavigate('prev')
    }
    goToNext(toolbar: any) {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1)
        toolbar.onNavigate('next')
    }
    goToCurrent(toolbar: any) {
        const now = new Date()
        toolbar.date.setMonth(now.getMonth())
        toolbar.date.setYear(now.getFullYear())
        toolbar.onNavigate('current')
    }
    select(cita: marcado) {
        let { selectDate } = this.props;
        if (selectDate !== undefined) selectDate(cita);
    }
    render() {
        let date = new Date()
        if (this.props.start !== undefined)
            date = new Date(this.props.start)

        return <Card style={{ height: "100%", width: "100%" }}>
            <Calendar
                selectable
                localizer={localizer}
                culture="es"
                events={[]}
                date={date}
                components={{
                    toolbar: this.CustomToolbar.bind(this),
                    dateCellWrapper: this.MonthEvent.bind(this),
                }}
                view={"month"}
            />
        </Card>
    }
    MonthEvent(e: any) {
        let manana = this.getcelda(e.value + "", 1)
        let tarde = this.getcelda(e.value + "", 2)
        let border: string | undefined = undefined;
        if (moment(e.value + "").format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"))
            border = '#28A745 solid 3px'
        let style = {
            width: "100%",
            height: "50%",
            border: "1px solid #ddd",
        }
        return <div style={{ border }} key={"celdaFecha" + manana.date} className={e.children.props.className}>
            <div id={"manana" + manana.date + manana.color} onDoubleClick={this.select.bind(this, manana)} style={{
                ...style,
                backgroundColor: manana.color
            }}></div>
            <div key={"labelFecha" + manana.date} style={{
                width: "40px",
                height: "40px",
                borderRadius: "100%",
                marginTop: "-20px",
                marginLeft: "5%",
                border: "1px solid #ddd",
                backgroundColor: "white",
                position: "absolute",
                textAlign: "center",
                alignItems: "center",
                fontSize: "20px",
                fontWeight: 700,
                zIndex: 400
            }}>
                {moment(e.value + "").format("DD")}
            </div>
            <div id={"tarde" + tarde.date + tarde.color} onDoubleClick={this.select.bind(this, tarde)} style={{
                ...style,
                backgroundColor: tarde.color
            }}></div>
        </div>
    }
    leyenda() {
        if (this.props.leyenda !== undefined) {
            return <Button className="mr-auto" onClick={this.props.leyenda} variant="outline-info"><FontAwesomeIcon icon={faQuestion} /> </Button>
        }
        return <></>;
    }
    CustomToolbar(toolbar: any) {
        if (this.can === 0) {
            this.can++
            if (this.props.start === undefined)
                this.goToCurrent(toolbar)
        }
        const date = moment(toolbar.date)
        return (<Card.Header className="py-3 d-flex">
            {this.Sugeridos()}
            <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
            </h6>
            {this.leyenda()}
            <ButtonGroup>
                <Button variant="outline-dark" onClick={this.goToBack.bind(this, toolbar)}>{"<<"}</Button>
                <Button variant="outline-dark" onClick={this.goToCurrent.bind(this, toolbar)}>HOY</Button>
                <Button variant="outline-dark" onClick={this.goToNext.bind(this, toolbar)}>{">>"}</Button>
            </ButtonGroup>
        </Card.Header>)
    }
    Sugeridos() {
        let { setData, servicio } = this.props
        if (servicio !== undefined && servicio.sugeridos !== undefined && setData !== undefined)
            return <Col md="5" as="h6" className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>Turnos Requeridos</InputGroup.Text>
                    </InputGroup.Prepend>
                    <SetText style={{ width: "50px", maxWidth: "50px" }} id="requeridos" step={1} type="number"
                        setData={setData} value={servicio.sugeridos} minValue={servicio.sugeridos} update="sugeridos" />
                    <InputGroup.Append>
                        <InputGroup.Text>Asignados</InputGroup.Text>
                        <InputGroup.Text>{servicio.dates.length}</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
        return <></>
    }
    getcelda(f: string, turn: number) {
        let fecha = moment(f).format("YYYY-MM-DD")
        let celda: celda = { date: fecha, turn, color: "", state: 4, status: 0, Tecnicos: [] }
        this.state.celdas.forEach((cel: celda) => {
            if (cel.date === fecha && cel.turn === turn) {
                celda.color = cel.color
                celda.start = cel.start;
                celda.end = cel.end;
                if (cel.service_id !== undefined)
                    celda.service_id = cel.service_id
            }
        })
        return celda
    }
    componentWillReceiveProps(p: Props) {
        this.setState({ celdas: p.celdas })
    }

}