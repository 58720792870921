
import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faStar } from '@fortawesome/free-solid-svg-icons'
import { zfill } from '../../Utils/Formats'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { conValorar, Valor, valorFila } from '../../Model/valoracion'
import { ServicioDefault } from '../../Model/Sevicios'
import PopCliente from '../Clientes/PopCliente'
import DetalleCotizacion from '../Cotizacion/DetalleCotizacion'
import Referir from '../Referenciados/Referir'
import ReactTooltip from 'react-tooltip'
moment.locale('es')
interface Props {
    cotizacion: valorFila
    select: (c: any) => void
}
interface State extends Valor {
    verDetalles: boolean
    referir: boolean
}
export default class FilaValoracion extends React.Component<Props, State>{
    con = conValorar()
    constructor(p: Props) {
        super(p)
        this.state = {
            comment_service: "",
            rank_service: 1,
            comment_tech: "",
            rank_tech: 1,
            ...p.cotizacion,
            service: ServicioDefault,
            verDetalles: false,
            referir: false
        }
    }
    async set() {
        if (this.state.service.id === 0)
            await this.con.select(this.props.cotizacion.id, (selected: Valor) => {
                this.setState(selected)
            })
    }
    render() {
        let cot = this.props.cotizacion
        let { verDetalles, referir, service, rank_tech, rank_service } = this.state
        return <tr onMouseEnter={this.set.bind(this)}>
            {verDetalles ? <DetalleCotizacion select={service} open={verDetalles} close={this.close.bind(this)} /> : <></>}
            {referir ? <Referir client_id={service.client.id} open={referir} close={this.close.bind(this)} /> : <></>}
            <th style={{ width: "115px" }}>
                <ReactTooltip />
                <Button data-tip="Ver Detalles" variant="link" onClick={() => { this.setState({ verDetalles: true }) }}>
                    {zfill(cot.service_id)}
                </Button>
            </th>
            <th style={{ width: "150px" }}>{moment(cot.created_at).format("DD-MM-YYYY")}</th>
            <th><PopCliente name={cot.name} cliente={service.client} /></th>
            <th style={{ width: "100px" }}>{this.getStatus()}</th>
            <th style={{ width: "128px" }}>
                <ButtonGroup>
                    {(cot.status > 1) ?
                        <Button data-tip="Valorar" as={Link}
                            to={"valorar/" + cot.id}
                            variant={this.getRank(rank_tech, rank_service)}>
                            <FontAwesomeIcon icon={faStar} />
                        </Button> : <></>}
                    <Button data-tip="Referir Cliente"
                        variant="primary"
                        onClick={this.referir.bind(this)}>
                        <FontAwesomeIcon icon={faPhone} />
                    </Button>
                </ButtonGroup>
            </th>
        </tr>
    }
    getStatus() {
        switch (this.props.cotizacion.status) {
            case 1:
                return <span className="bg-warning text-black p-1 d-inline-block">Espera</span>
            case 2:
                return <span className="bg-info text-white p-1 d-inline-block">Activa</span>
            case 3:
                return <span className="bg-success text-white p-1 d-inline-block">Procesada</span>
        }
    }
    referir() {
        this.setState({ referir: true })
    }
    close() {
        this.setState({
            verDetalles: false,
            referir: false
        })
    }
    getVariant(i: number): string {
        switch (i) {
            case 1:
                return "danger"
            case 2:
                return "warning"
            case 3:
                return "dark"
            case 4:
                return "info"
            case 5:
                return "success"
            default:
                return "outline-primary"
        }
    }
    getRank(v1?: number, v2?: number): string {
        if (v1 !== undefined && v2 !== undefined) {
            let rank: number = (v1 + v2) / 2
            let sent = Math.trunc(rank)
            let variant = this.getVariant(sent)
            if (rank > sent)
                variant = "outline-" + variant
            return variant
        }
        return "outline-primary"
    }

}