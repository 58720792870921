import { Activas } from "../Conexiones/Activas"
import { fechas } from "./Generales"

export class contribuyente extends Activas {
    tabla = 'tablas'
    coleccion = 'taxpayer_types'
    lista: string = 'Tipos'
}
var    conCon: contribuyente
export function conContribuyente() {
    if (conCon === undefined)
        conCon = new contribuyente()
    return conCon
}
export interface taxpayer_type extends fechas {
    id: number,
    description: string,
    tax: number
    status: number
}