import React from "react";
import categoria, { conCategorias } from '../../../Model/Categoria';
import { NuevaCategorias } from "./Nuevo";
import { FilaCategoria } from "./Fila";
import { PermisosType } from "../../../config/Permisos";
import Tabla from "../../../Utils/Table";
import { Storage } from "../../../Storage";
export class Categorias extends Tabla {
    bucarLabel = ["description", "code"];
    permiso: PermisosType = "categoria";
    con = conCategorias();
    componentWillMount() {
        this.context.set({ title: "configuracion de Categorias" })
        this.con.setCargar(this.setItens.bind(this));
    }
    Row(p: categoria) {
        return <FilaCategoria update={()=>{this.con.setLista(this.setItens.bind(this))}} key={"con" + p.id + p.status} categoria={p} />
    }
    Header() {
        return <tr>
            <th style={{ width: 150 }} onClick={() => { this.setOrden("code") }}>CÓDIGO{this.flechas("code")}</th>
            <th onClick={() => { this.setOrden("description") }}>DESCRIPCIÓN{this.flechas("description")}</th>
            <th style={{ width: 100 }}>ESTATUS</th>
            <th style={{ width: 150 }}>ACCIONES</th>
        </tr>
    }
    busquedaMostrar() {
        return <NuevaCategorias actulalizar={()=>{this.con.setLista(this.setItens.bind(this))}} />;
    }
}
Categorias.contextType = Storage
export default Categorias
