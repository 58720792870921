import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, FormControl, InputGroup } from "react-bootstrap"
import categoria, { conCategorias } from "../../../Model/Categoria"

export class NuevaCategorias extends React.Component<any, categoria> {
    con = conCategorias()

    render() {
        return <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>CODIGO</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl maxLength={6} onChange={this.setChangeCode.bind(this)} style={{ maxWidth: 150 }} />
            <InputGroup.Text>DESCRIPCIÓN</InputGroup.Text>
            <FormControl onChange={this.setChangeDesc.bind(this)} />
            <InputGroup.Append>
                <Button onClick={this.guardar.bind(this)}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </InputGroup.Append>
        </InputGroup>
    }

    setChangeCode(e: React.ChangeEvent<HTMLSelectElement>) {
        let { value } = e.currentTarget
        let r = value.toUpperCase()
        e.currentTarget.value = r
        this.setState({ code: r })
    }
    setChangeDesc(e: React.ChangeEvent<HTMLSelectElement>) {
        let { value } = e.currentTarget
        let r = value.toUpperCase()
        e.currentTarget.value = r
        this.setState({ description: r })
    }
    guardar() {
        let { code, description } = this.state
        this.con.create({ id: 1, code, description, status: 1 })
    }
}