import { faPrint, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, ButtonGroup, Card, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import { ServicioDefault } from "../../Model/Sevicios"
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { conValorar, sendValor, Valor } from '../../Model/valoracion'
import { zfill } from '../../Utils/Formats'
import SetText from '../../Utils/setTex'
import { Permisos } from '../../config/Permisos'
import Mostrar from '../Servicios/Mostrar'
import { Storage } from '../../Storage'
interface State extends Valor {
    redirect: boolean,
}
interface Props {
    match: { params: { id: number } }
}

export class Valorarcion extends React.Component<{ setData: (d: any) => void, data: Valor }> {
    render() {
        let { props: { data: { rank_tech, rank_service, comment_tech, comment_service }, setData } } = this
        return <> <Card>
            <Card.Header>
                <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">VAlorar servicio</h6>
            </Card.Header>
            <Container className="mh-3">
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>estrellas de servicio</InputGroup.Text>
                    </InputGroup.Prepend>
                    <ValorStart valor={rank_service} update={"rank_service"} setdata={setData} />
                </InputGroup>
                <Form.Group controlId="formBasicRange">
                    <Form.Label>Comentario de servicio</Form.Label>
                    <SetText as="textarea" value={comment_service} update={"comment_service"} setData={setData} />
                </Form.Group>
            </Container>
        </Card>
            <Card>
                <Card.Header>
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">VAlorar tecnico</h6>
                </Card.Header>
                <Container className="mh-3">
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>estrellas de tecnico</InputGroup.Text>
                        </InputGroup.Prepend>
                        <ValorStart valor={rank_tech} update={"rank_tech"} setdata={setData} />
                    </InputGroup>
                    <Form.Group controlId="formBasicRange">
                        <Form.Label>comentario de tecnico</Form.Label>
                        <SetText as="textarea" value={comment_tech} update={"comment_tech"} setData={setData} />
                    </Form.Group>
                </Container>
            </Card>
        </>
    }
}
class Valorar extends React.Component<Props, State> {
    con = conValorar()
    constructor(p: Props) {
        super(p)
        this.state = {
            service: ServicioDefault,
            comment_service: "",
            service_id: 0,
            rank_service: 1,
            comment_tech: "",
            rank_tech: 1,
            client_id: 0,
            name: "",
            id: 0,
            redirect: false,
            status: 0
        }
        this.setState = this.setState.bind(this)
    }
    render() {
        let { authFuncion } = Permisos()
        if (!authFuncion("valoracion"))
            return <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h1 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        No esta autorizado para entrar en esta seccion
                </h1>
                </Card.Header>
            </Card>
        if (this.state.redirect)
            return <Redirect to="/valoraciones" />
        let { setState, state } = this
        return <Card style={{ height: "100%" }} className="shadow mb-4">
            <Card.Body style={{ height: "100%" }}>
                {(this.state.id === 0) ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                    <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                    <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Cargando....
                    </h4>
                </Row> :
                    <Row>
                        <Col md={6}>
                            <Mostrar servicio={state.service} />
                        </Col>
                        <Col md={6}>
                            <Valorarcion setData={setState} data={state} />
                            {(state.status === 2) ?
                                <Button onClick={this.procesar.bind(this)} className="float-right" variant="success">
                                    <FontAwesomeIcon icon={faPrint} />
                                </Button> : <></>}
                        </Col>
                    </Row>}
            </Card.Body>
        </Card>
    }
    componentDidMount() {
        const { match: { params: { id } } } = this.props
        let { set } = this.context
        let { setState } = this
        this.con.select(id, (t: Valor) => {
            console.log(t);
            if (t.rank_service === null)
                t.rank_service = 1
            if (t.rank_tech === null)
                t.rank_tech = 1
            setState(t)
            set({ title: "valorar servicio N° " + zfill(t.service_id) })
            if (t.status === 1) {
                toast('no se ha cumplido el tiempo', { type: toast.TYPE.ERROR })
                setState({ redirect: true })
            }
        })
    }
    procesar() {
        let { setState } = this
        this.con.update(sendValor(this.state), () => {
            setState({ redirect: true })
        })
    }
}
class ValorStart extends React.Component<{ valor: number, setdata?: (d: any) => void, update?: string }, { hover: number }>{
    constructor(p: any) {
        super(p)
        this.state = { hover: 0 }
    }
    render() {
        return <ButtonGroup>
            <Button
                variant={this.select(1)}
                onMouseEnter={this.hoverInt.bind(this, 1)}
                onClick={this.valorar.bind(this, 1)}
                onMouseLeave={this.hoverOut.bind(this)}
            >
                <FontAwesomeIcon icon={faStar} />
            </Button>
            <Button
                variant={this.select(2)}
                onMouseEnter={this.hoverInt.bind(this, 2)}
                onClick={this.valorar.bind(this, 2)}
                onMouseLeave={this.hoverOut.bind(this)}
            >
                <FontAwesomeIcon icon={faStar} />
            </Button>
            <Button
                variant={this.select(3)}
                onMouseEnter={this.hoverInt.bind(this, 3)}
                onClick={this.valorar.bind(this, 3)}
                onMouseLeave={this.hoverOut.bind(this)}>
                <FontAwesomeIcon icon={faStar} />
            </Button>
            <Button
                variant={this.select(4)}
                onMouseEnter={this.hoverInt.bind(this, 4)}
                onClick={this.valorar.bind(this, 4)}
                onMouseLeave={this.hoverOut.bind(this)}>
                <FontAwesomeIcon icon={faStar} />
            </Button>
            <Button
                variant={this.select(5)}
                onMouseEnter={this.hoverInt.bind(this, 5)}
                onClick={this.valorar.bind(this, 5)}
                onMouseLeave={this.hoverOut.bind(this)}>
                <FontAwesomeIcon icon={faStar} />
            </Button>
        </ButtonGroup>
    }
    select(i: number) {
        let { hover } = this.state
        let { valor } = this.props
        let variant: string = "white"
        if (i === valor)
            variant = this.getVariant(valor)
        if (i < valor)
            variant = this.getVariant(valor)
        if (hover !== 0)
            if (i <= hover)
                variant = this.getVariant(hover)
        return variant
    }
    getVariant(i: number): string {
        switch (i) {
            case 1:
                return "danger"
            case 2:
                return "warning"
            case 3:
                return "dark"
            case 4:
                return "info"
            case 5:
                return "success"
            default:
                return "white"
        }
    }
    hoverInt(i: number) {
        let { setdata } = this.props
        if (setdata !== undefined)
            this.setState({ hover: i })
    }
    hoverOut() {
        let { setdata } = this.props
        if (setdata !== undefined)
            this.setState({ hover: 0 })
    }
    valorar(i: number) {
        let { update, setdata } = this.props
        if (setdata !== undefined && update !== undefined)
            setdata({ [update]: i })
    }
}
Valorar.contextType = Storage;
export default Valorar;