import React from 'react'
import Paginacion from '../../Utils/Paginacion'
import { Modal, Row, Table, Button, Col, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Cliente, { conCliente } from '../../Model/Clientes'
import ReactTooltip from 'react-tooltip'
class Fila extends React.Component<{ Cliente: Cliente, add: (iten: Cliente) => void, close: () => void }, Cliente>{
    con = conCliente()
    constructor(p: { Cliente: Cliente, add: (iten: Cliente) => void, close: () => void }) {
        super(p)
        this.state = p.Cliente
    }
    render() {
        let Cliente = this.props.Cliente
        return <tr>
            <th>{Cliente.code}</th>
            <th>{Cliente.name}</th>
            <th>
                <ReactTooltip />
                <Button data-tip="Seleccionar Cliente" onClick={() => { this.getCliente(); this.props.close() }} variant="outline-dark">
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </th>
        </tr >
    }

    getCliente() {
        let { add, Cliente } = this.props
        this.con.select(Cliente.id, add)
    }
}
export default class BuscarCliente extends React.Component<{ open: boolean, add: (iten: Cliente) => void, close: () => void }, { act: number, clientes?: Array<Cliente> }>{
    page = Paginacion();
    con = conCliente();
    constructor(pr: { open: boolean, add: (iten: Cliente) => void, close: () => void }) {
        super(pr);
        this.setState = this.setState.bind(this);
        this.state = { act: 0 };
        this.page.row = (p: Cliente) => {
            return <Fila key={"cliente" + p.id + p.status} Cliente={p} add={pr.add} close={pr.close} />
        };
        this.page.bucarLabel = ["code", "name", "contact_person"];
        this.page.act = this.act.bind(this);
        this.con.setActivas(this.setClientes.bind(this));
    }
    setClientes(c: Cliente[]) {
        this.setState({ clientes: c });
    }

    render() {
        return <Modal size="xl" centered animation show={this.props.open} onHide={this.props.close}>
            <Modal.Header closeButton>
                <Col as="h6" md="6" className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    {this.page.Buscador("Buscar Cliente")}
                </Col>
            </Modal.Header>
            <Modal.Body>
                {(this.state.clientes === undefined) ? <Row style={{ fontSize: "300%" }} className="d-flex justify-content-center">
                    <Spinner style={{ width: "15rem", height: "15rem" }} variant="primary" animation="border" />
                    <h4 style={{ position: "absolute" }} className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                        Cargando....
                    </h4>
                </Row> : <Row>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th onClick={() => { this.page.setOrden("code") }}>RUC {this.page.flechas("code")}</th>
                                    <th onClick={() => { this.page.setOrden("name") }}>NOMBRE {this.page.flechas("name")}</th>
                                    <th>ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.page.body()}
                            </tbody>
                        </Table>
                    </Row>}
                <Row className="py-3 d-flex">
                    <h6 className="m-0 font-weight-bold text-primary mr-auto align-self-center">CLIENTES LISTADO {this.page.length}</h6>
                    {this.page.pagination()}
                </Row>
            </Modal.Body>
        </Modal>
    }
    componentWillUpdate(nextProps: any, nextState: any) {
        if (nextState.clientes !== undefined)
            this.page.setItens(nextState.clientes);
    }
    act() {
        this.setState({ act: this.state.act + 1 });
    }
}